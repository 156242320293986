
var djf = djf || {};

$(function () {
    djf.datepicker.init();
});


djf.datepicker = new function () {

    var self = this;

    this.init = function () {
        $('.datetimepicker').not('.unionmessageform').datetimepicker({
            locale: 'da'
        });

        $('.datetimepicker.unionmessageform').datetimepicker({
            locale: 'da',
            sideBySide: true
        });

        $('.datepicker.unionmessageform').datetimepicker({
            locale: 'da',
            format: 'DD/MM/YYYY'
        });

        $('.datepicker').not('.unionmessageform').datetimepicker({
            locale: 'da',
            format: 'YYYY-MM-DD'
        });

        $('.timepicker').datetimepicker({
            locale: 'da',
            format: 'HH:mm'
        });

        $('.datepickerfield').datetimepicker({
            locale: 'da',
            format: 'YYYY-MM-DD'
        });
    };

};

