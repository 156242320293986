var djf = djf || {};

// DocReady
$(document).ready(function () {
    djf.stageSlider.init();
    djf.stageSlider.initTinySlider();
}); // DocReady


djf.stageSlider = new function () {

    this.init = function () {
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            centerMode: true,
            focusOnSelect: true,
            accessibility: false,
            arrows: false,
            pauseOnHover: true
        });
        $('.slider-for').on('init', function (event, slick, currentSlide, nextSlide) {
            $('.slider-for').show();
        });
        $('.slider-nav').on('init', function (event, slick, currentSlide, nextSlide) {
            $('.slider-nav').show();
        });

        setInterval(function () {
            var navFor = $('.slider-nav');
            var currentSlide = navFor.slick("slickCurrentSlide");
            var isHovered = $('.frontpage-slider').is(":hover");
            if (!isHovered) {
                navFor.slick("slickGoTo", currentSlide + 1, true);
            }
        }, 3000);

        $('.slider-for').on('swipe', function (event, slick, direction) {
            var navFor = $('.slider-nav');

            var currentSlide = navFor.slick('slickCurrentSlide');
            if (direction == "right") {
                navFor.slick("slickGoTo", currentSlide - 1, true);
            } else {
                navFor.slick("slickGoTo", currentSlide + 1, true);
            }
        });
        jQuery('.slick-slide').bind('touchstart', function () { });
    }

    //speed: 1000,
    //arrowKeys: true,
    this.initTinySlider = function () {
        this.initFrontPageSliderEvents();
        var owlcarouselFrontPage_for = $('.frontpage-slider-for');
        owlcarouselFrontPage_for.owlCarousel({
            items: 1,
            loop: true,
            checkVisible: false,
            autoplay: true,
            autoplayHoverPause: true,
            autoplaySpeed: 500
        });

        var owlcarouselFrontPage_nav = $('.frontpage-slider-nav');
        owlcarouselFrontPage_nav.owlCarousel({
            items: 1,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            loop: true,
            responsive: {
                // breakpoint from 0 up
                1024: {
                    items: 3
                }
            }
        });
        $('.frontpage-slider-nav .owl-item.active').first().addClass('current');
        //$('.slider-for-tiny').owlCarousel(); "initialized.owl.carousel"

    };
    this.initFrontPageSliderEvents = function () {

        $('.frontpage-slider-for').on('changed.owl.carousel', function (property) {
            var currentIndex = property.page.index;
            var allItems = $('.frontpage-slider-nav .owl-item');
            allItems.removeClass('current');
            allItems = $('.frontpage-slider-nav .owl-item.active');
            var currentItem = allItems[currentIndex];
            $(currentItem).addClass('current');
            $('.frontpage-slider-nav').trigger('to.owl.carousel', [currentIndex, 500]);
            //$('.frontpage-slider-nav .owl-item').addClass("current");

        });
    }
};
// europcar.stageSlider
//640: {
//    items: 2
//},
//700: {
//    gutter: 30
//},
