var djf = djf || {};

$(function () {
    djf.newsletter.init();
});


djf.newsletter = new function () {
    var self = this,
        button = $('.newsletter-container .button.toggler');


    this.init = function () {
        self.toggleShow(button);
        self.sendForm();
    };
    this.toggleShow = function (t) {
        t.click(function () {
            var $this = $(this),
                $toggleWrapper = $this.parent().parent().parent().siblings('.toggle-wrapper'),
                contentUrl = $this.attr('data-url');

            $.get(contentUrl, function (data) {
                $toggleWrapper.html(data);
            });

            if (!$toggleWrapper.hasClass('open')) {
                //console.log('scrollTo');
                $('html, body').animate({
                    scrollTop: $toggleWrapper.offset().top
                }, 500);
            }

            $toggleWrapper.toggleClass('open');
        });

    };

    this.sendForm = function () {

        $('.newsletter-container').on('submit', '#cm-form', function (event) {
            event.preventDefault();

            var $this = $(this),
                $checkboxes = $(this).find('.options input:checked'),
                $response = $('#cm-response');
            listArray = [];
            formData = {};

            // Get values from the inputs and put them into the empty object
            formData.email = $this.find('#fieldEmail').val();
            formData.name = $this.find('#fieldName').val();
            formData.listArray = [];

            // Get the checkbox options
            $checkboxes.each(function () {

                var listVal = $(this).val();
                formData.listArray.push(listVal);

            });

            // Check if there is any values in the array
            if (!formData.listArray.length) {
                $response.html('Vælg venligst mindst ét emne ovenfor');
                $response.fadeIn();
            } else {

                // Init the form with the values
                var subscribePostData = {
                    "subscribe": {
                        "mailinglist_ids": formData.listArray,
                        "subscriber": {
                            "email": formData.email,
                            "full_name": formData.name
                        }
                    }
                };
                $.ajax({
                    url: "/umbraco/Api/NewsletterApi/Subscribe",
                    cache: false,
                    type: "POST",
                    data: JSON.stringify(formData),
                    success: function (data) {
                        data.success ? $response.attr('class', 'success') : $response.attr('class', 'error');
                        $response.html(data.msg);
                        $response.fadeIn();

                        // Reset the msg after 4s
                        setTimeout(function () {
                            $response.fadeOut();
                            $response.html('');
                        }, 8000);
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // console.log("Error: " + textStatus + ", " + errorThrown);
                        $response.fadeIn();
                        $response.html('Der er sket en fejl - kontakt venligst administration').attr('class', 'error');
                    }
                });
            }


        });
    };

    this.reloadWithFitler = function () {

        var queryObj = {
            page: 1
        };

        var selectedCategory = $("#cat-filter").children("option:selected").val();
        if (selectedCategory != 0) {
            queryObj.cat = selectedCategory;
        }
        var selectedMonth = $("#month-filter").children("option:selected").val();
        if (selectedMonth != 0) {
            queryObj.month = selectedMonth;
        }
        var selectedYear = $("#year-filter").children("option:selected").val();
        if (selectedYear != 0) {
            queryObj.year = selectedYear;
        }

        window.reloadWithQueryStringVars(queryObj, true);
    };

};
