
if (document.getElementById("signupModel_Address1") != null) {
    dawaAutocomplete.dawaAutocomplete(document.getElementById("signupModel_Address1"),
        {
            select: function (selected) {

                var address = selected.data.vejnavn + " " + selected.data.husnr;

                if (selected.data.etage != null) {
                    address += " " + selected.data.etage;
                }

                if (selected.data.dør != null) {
                    address += " " + selected.data.dør;
                }

                if (selected.data.kommunekode != null) {
                    document.getElementById("signupModel_Municipality").value = selected.data.kommunekode;
                }

                if (selected.data.supplerendebynavn != null) {
                    document.getElementById("signupModel_Address2").value = selected.data.supplerendebynavn;
                }

                if (selected.data.postnr != null) {
                    document.getElementById("signupModel_ZipCode").value = selected.data.postnr;
                }


                if (selected.data.postnrnavn != null) {
                    document.getElementById("signupModel_City").value = selected.data.postnrnavn;
                }
                setTimeout(changeAddress, 100, address, "signupModel_Address1");


                document.activeElement.blur();

            }
        });
}

if (document.getElementById("myprofile-address") != null) {
    dawaAutocomplete.dawaAutocomplete(document.getElementById("myprofile-address"),
        {
            select: function (selected) {

                var address = selected.data.vejnavn + " " + selected.data.husnr;

                if (selected.data.etage != null) {
                    address += " " + selected.data.etage;
                }

                if (selected.data.dør != null) {
                    address += " " + selected.data.dør;
                }

                if (selected.data.kommunekode != null) {
                    document.getElementById("myprofile-municipality").value = selected.data.kommunekode;
                }

                if (selected.data.postnr != null) {
                    document.getElementById("myprofile-zipcode").value = selected.data.postnr;
                }


                if (selected.data.postnrnavn != null) {
                    document.getElementById("myprofile-city").value = selected.data.postnrnavn;
                }

                document.getElementById("myprofile-place").value = selected.data.supplerendebynavn;

                setTimeout(changeAddress, 10, address, "myprofile-address");

                document.activeElement.blur();

            }
        });
}

function changeAddress(address, id) {
    document.getElementById(id).value = address;
}

$(".userpage form").submit(function () {
    var form = $(this);
    setTimeout(function () {
        form.find("button[type=submit]").prop("disabled", true);
    }, 100);


});