var djf = djf || {};

// DocReady

$(document).ready(function () {
    var amountOfItemsToDisplay = 2;
    //console.log("window.innerWidth", window.innerWidth);
    if (window.innerWidth >= 1200) {
        amountOfItemsToDisplay = 3;
    }
    var owlcarouselStories = $('.review-section-slider');
    owlcarouselStories.owlCarousel({
        items: amountOfItemsToDisplay,
        loop: true,
        checkVisible: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplaySpeed: 500,
        navText: ["", ""]
    });
}); // DocReady