function FocusToThis(obj, videoUrl, containerID) {
    var youtubeContainer = $("#" + containerID);
    //console.log("containerID", containerID);
    //console.log("videoUrl", videoUrl);

    var jqueryObj = $(obj); //Convert html object to jquery object.
    //console.log("inside FocusToThis", jqueryObj);

    var activeVideo = youtubeContainer.find(".active-sub-video"); //Find previously selected video and deselect
    activeVideo.removeClass("active-sub-video");

    jqueryObj.addClass("active-sub-video"); //Select clicked video

    var youtubePlayer = youtubeContainer.find("iframe"); //Find youtube iframe and set url.
    var url = "https://www.youtube.com/embed/" + videoUrl + "?autoplay=0&origin=http://example.com";
    youtubePlayer.attr('src', url);

    //console.log("youtubeContainer", youtubeContainer);

    resizeYoutubePlayer(youtubeContainer); //Resize video player
}
$(document).ready(function () {
    //console.log("youtube list script doku");
    var lazy = $('.lazy');
    if (lazy.length) {
        setTimeout(function () {
            lazy.slick({
                lazyLoad: 'ondemand',
                slidesToShow: 4,
                slidesToScroll: 4
            });
        }, 500);
    }
})