var djf = djf || {};


djf.quickSignUpLink = new function () {

    this.GenerateSignUpQuickLink = function (id) {

        var subscription = $("#subscriptions-" + id).val();
        console.log("subscription", subscription);

        var signUpQuickLink = $("#sign-up-quick-link")[0];
        console.log("signUpQuickLink", signUpQuickLink);
        console.log("signUpQuickLink", signUpQuickLink.value);

        if (validateEmail(signUpQuickLink.value)) {
            $("#invalidEmailText").hide();
            $.post("/umbraco/api/SignUpQuickLink/GenerateQuickSignUpLink", { EmailTo: signUpQuickLink.value, AssociationId: subscription }, function (response) {
                var successEmailText = $("#successEmailText");
                console.log("response", response);
                successEmailText[0].innerText = response;
                if (response.substring(0, 4) == "Fejl") {
                    successEmailText[0].addClass("invalidEmailText");
                }
                successEmailText.show();
                $("#sign-up-quick-link").removeClass("invalid");
            });
        } else {
            var successEmailText = $("#successEmailText");
            $("#sign-up-quick-link").addClass("invalid");
            $("#invalidEmailText").show();
            successEmailText.hide();
        }
    };

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

}