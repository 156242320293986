/*
dataTables for Danmarks Jægerforbund

1. Render Functions for Additional Info
2. Default Settings for DataTables
3. Init of DataTable
4. Calender DataTable
5. HjorteVildt DataTable
6. Udvalg DataTable
7. Kredse DataTable
8. Lokalforening DataTable
8. Adminstrator DataTable

*/

var djf = djf || {};

//New code
$.extend(jQuery.fn.dataTableExt.oSort, {
    "date-uk-pre": function (a) {
        var x;
        try {
            var dateA = a.replace(/ /g, '').split("-");
            var day = parseInt(dateA[0], 10);
            var month = parseInt(dateA[1], 10);
            var year = parseInt(dateA[2], 10);
            var date = new Date(year, month - 1, day);
            var getDatetime = date.getTime();
            x = !isNaN(getDatetime) ? getDatetime : 10000000000000000000; // Kinda of hacky but its works
        }
        catch (err) {
            console.log("error" + err);
            x = new Date().getTime();
        }

        return x;
    },

    "date-uk-asc": function (a, b) {
        return a - b;
    },

    "date-uk-desc": function (a, b) {
        return b - a;
    }
});

function jagtproevenDetails(d) {

    var address = d.address;
    if (address && address.length > 0 && d.city && d.city.length > 0) {
        address = address + ", " + d.city;
    } else if (d.city && d.city.length > 0) {
        address = d.city;
    }
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" class="details-table">' +
        '<tbody class="block">' +
        '<tr class="block">' +
        '<td class="block">' +
        '<div class="details-wrapper clearfix">' +
        '<div class="col-xs-12 scroll-x">' +


        //line 1
        '<table class="local-info">' +
        '<thead>' +
        '<tr>' +
        '<th style="min-width: 140px">' + (d.startDate ? 'Startdato' : '') + '</th>' +
        '<th style="min-width: 140px">' + (d.endDate ? 'Slutdato' : '') + '</th>' +
        '<th style="min-width: 140px">' + (d.contactName ? 'Underviser' : '') + '</th>' +
        '<th style="min-width: 250px">Holdnavn</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>' +
        '<tr>' +
        '<td>' + (d.startDate ? d.startDate : '') + '</td>' +
        '<td>' + (d.endDate ? d.endDate : '') + '</td>' +
        '<td>' + (d.contactName ? d.contactName : '') + '</td>' +
        '<td>' + (d.name ? d.name : '') + '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>' +


        //line 2
        '<table class="local-info">' +
        '<thead>' +
        '<tr>' +
        '<th style="min-width: 140px">' + (d.contactPhone ? 'Telefon' : '') + '</th>' +
        '<th style="min-width: 140px">' + (d.contactEmail ? 'E-mail' : '') + '</th>' +
        '<th style="min-width: 140px">' + (d.address ? 'Adresse' : '') + '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>' +
        '<tr>' +
        '<td>' + (d.contactPhone ? d.contactPhone : '') + '</td>' +
        '<td>' + (d.contactEmail ? d.contactEmail : '') + '</td>' +
        '<td>' + (d.address ? d.address : '') + (d.address && d.city ? "</br>" : '') + (d.city ? d.city : '') + '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>' +

        '</div>' +

        (d.contactWebsite ?
            '<div class="col-xs-12 button-wrapper clearfix">' +
            '<a href="' + d.contactWebsite + '" class="button green-button" target="_blank">Besøg website</a>' +
            '</div>' : '') +
        '</div>' +
        '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>';
}

function jagtproevenDetailsMobile(d) {

    var address = d.address;
    if (address && address.length > 0 && d.city && d.city.length > 0) {
        address = address + ", " + d.city;
    } else if (d.city && d.city.length > 0) {
        address = d.city;

    }
    // `d` is the original data object for the row
    var result = '<div class="col-xs-12">' +
        (d.startDate ? "<b>Startdato</b><br />" : '') +
        (d.startDate ? d.startDate + "<br /><br />" : '') +

        (d.endDate ? "<b>Slutdato</b><br />" : '') +
        (d.endDate ? d.endDate + "<br /><br />" : '') +

        (d.contactName ? "<b>Underviser</b><br />" : '') +
        (d.contactName ? d.contactName + "<br /><br />" : '') +

        (d.name ? "<b>Holdnavn</b><br />" : '') +
        (d.name ? d.name + "<br /><br />" : '') +

        (d.contactPhone ? "<b>Telefon</b><br />" : '') +
        (d.contactPhone ? d.contactPhone + "<br /><br />" : '') +

        (d.contactEmail ? "<b>E-mail</b><br />" : '') +
        (d.contactEmail ? d.contactEmail + "<br /><br />" : '') +

        (address ? "<b>Adresse</b><br />" : '') +
        (d.address ? d.address : '') + (d.address && d.city ? "</br>" : '') + (d.city ? d.city : '') + "<br /><br />"
        ;
    if (d.contactWebsite) {
        result += '<div class="button-wrapper clearfix">' +
            '<a href="' + d.contactWebsite + '" class="button green-button" target="_blank">Besøg website</a>' +
            '</div>';
    }

    return result + '</div>';
}



// 1. Render Functions for Additional Info
function calenderDetails(d) {
    if (!d.details) { return ''; }

    var priceInfo = '';
    if (d.details.priceInfo) {
        for (var i = 0; i < d.details.priceInfo.length; i++) {
            var price = d.details.priceInfo[i];

            priceInfo += '<tr><td class="title">' + price.label + '</td><td>' + (!price.fixedPrice ? 'Fra ' : '') + price.price + ' DKK</td></tr>';
        }
    } else {
        priceInfo = (d.details.price ? '<tr><td class="title">Pris</td><td>' + d.details.price + '</td></tr>' : '');
    }

    if (d.details.imgURL && d.details.imgURL.indexOf('www.event.it') == -1) {
        d.details.imgURL = '//www.event.it' + d.details.imgURL;
    }

    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" class="details-table">' +
        '<tbody class="block">' +
        '<tr class="block">' +
        '<td class="block">' +
        '<div class="details-wrapper clearfix">' +
        '<div class="content-wrapper col-sm-5">' +
        '<div class="row">' +
        (d.details.imgURL ? '<div class="col-xs-6 col-sm-12 detail-image"><img src="' + d.details.imgURL + '"></div>' : '') +
        '<div class="col-xs-6 col-sm-12 detail-desc"><p>' + d.details.desc + '</p></div>    ' +
        '</div>' +
        '</div>' +
        '<div class="contact-wrapper col-sm-7">' +
        '<table class="contact-info">' +
        (d.details.time ? '<tr><td class="title">Tid</td><td>' + d.details.time + '</td></tr>' : '') +
        (d.details.place ? '<tr><td class="title">Sted</td><td>' + d.details.place + '</td></tr>' : '') +
        priceInfo +
        (d.details.signup ? '<tr><td class="title">Tilmeldingsfrist</td><td>' + d.details.signup + '</td></tr>' : '') +
        '</table>' +
        '</div>' +
        '<div class="col-xs-12 button-wrapper clearfix">' +
        '<a href="' + d.details.readmoreURL + '" target="_blank" class="button green-button white">Læs mere</a>' +
        '</div>' +
        '</div>' +
        '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>';
}


function calenderDetailsMobile(d) {
    if (!d.details) { return ''; }

    var priceInfo = '';
    if (d.details.priceInfo) {
        for (var i = 0; i < d.details.priceInfo.length; i++) {
            var price = d.details.priceInfo[i];

            priceInfo += '<tr><td class="title">' + price.label + '</td><td>' + (!price.fixedPrice ? 'Fra ' : '') + price.price + ' DKK</td></tr>';
        }
    } else {
        priceInfo = (d.details.price ? '<tr><td class="title">Pris</td><td>' + d.details.price + '</td></tr>' : '');
    }

    if (d.details.imgURL && d.details.imgURL.indexOf('www.event.it') == -1) {
        d.details.imgURL = '//www.event.it' + d.details.imgURL;
    }

    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" class="details-table">' +
        '<tbody class="block">' +
        '<tr class="block">' +
        '<td class="block">' +
        '<div class="details-wrapper clearfix">' +
        '<div class="content-wrapper col-sm-5">' +
        '<div class="row">' +
        (d.details.imgURL ? '<div class="col-xs-6 col-sm-12 detail-image"><img src="' + d.details.imgURL + '"></div>' : '') +
        '<div class="col-xs-' + (d.details.imgURL ? '6' : '12') + ' col-sm-12"><p>' + d.details.desc + '</p></div>    ' +
        '</div>' +
        '</div>' +
        '<div class="contact-wrapper col-sm-7">' +
        '<table class="contact-info">' +
        (d.details.time ? '<tr><td class="title">Tid</td><td>' + d.details.time + '</td></tr>' : '') +
        (d.details.place ? '<tr><td class="title">Sted</td><td>' + d.details.place + '</td></tr>' : '') +
        priceInfo +
        (d.details.signup ? '<tr><td class="title">Tilmeldingsfrist</td><td>' + d.details.signup + '</td></tr>' : '') +
        '</table>' +
        '</div>' +
        '<div class="col-xs-12 button-wrapper clearfix">' +
        '<a href="' + d.details.readmoreURL + '" target="_blank" class="button green-button white">Læs mere</a>' +
        (d.details.signupURL && d.details.openForRegistration ? '<a href="' + d.details.signupURL + '" target="_blank" class="button green-button solid">' + (d.details.eventType == 'EVENT' ? 'Tilmeld' : 'Jeg er interesseret') + '</a>' : '') +
        '</div>' +
        '</div>' +
        '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>';
}

function localDetails(d) {
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" class="details-table">' +
        '<tbody class="block">' +
        '<tr class="block">' +
        '<td class="block">' +
        '<div class="details-wrapper clearfix">' +
        '<div class="col-xs-12 scroll-x">' +
        '<table class="local-info">' +
        '<thead>' +
        '<tr>' +
        '<th style="min-width: 140px">Jægerråd</th>' +
        '<th>' + (d.details.chairman && d.details.chairman.name ? 'Jagtforeningsformand' : '') + '</th>' +
        '<th>' + (d.details.treasurer && d.details.treasurer.name ? 'Jagtforeningskasserer' : '') + '</th>' +
        '<th>' + (d.details.secretary && d.details.secretary.name ? 'Jagtforeningssekretær ' : '') + '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>' +
        '<tr>' +
        '<td style="min-width: 140px">' + d.details.jfk + '</td>' +
        '<td>' + (d.details.chairman && d.details.chairman.name ? d.details.chairman.name : '') + '</td>' +
        '<td>' + (d.details.treasurer && d.details.treasurer.name ? d.details.treasurer.name : '') + '</td>' +
        '<td>' + (d.details.secretary && d.details.secretary.name ? d.details.secretary.name : '') + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="min-width: 140px"></td>' +
        '<td>' + (d.details.chairman && d.details.chairman.phone1 ? d.details.chairman.phone1 : '') + '</td>' +
        '<td>' + (d.details.treasurer && d.details.treasurer.phone1 ? d.details.treasurer.phone1 : '') + '</td>' +
        '<td>' + (d.details.secretary && d.details.secretary.phone1 ? d.details.secretary.phone1 : '') + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="min-width: 140px" class="th">Jagtforenings nr.</td>' +
        '<td>' + (d.details.chairman && d.details.chairman.phone2 ? d.details.chairman.phone2 : '') + '</td>' +
        '<td>' + (d.details.treasurer && d.details.treasurer.phone2 ? d.details.treasurer.phone2 : '') + '</td>' +
        '<td>' + (d.details.secretary && d.details.secretary.phone2 ? d.details.secretary.phone2 : '') + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="min-width: 140px">' + d.number + '</td>' +
        '<td>' + (d.details.chairman && d.details.chairman.email ? d.details.chairman.email : '') + '</td>' +
        '<td>' + (d.details.treasurer && d.details.treasurer.email ? d.details.treasurer.email : '') + '</td>' +
        '<td>' + (d.details.secretary && d.details.secretary.email ? d.details.secretary.email : '') + '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>' +
        '</div>' +
        (d.details.website ?
            '<div class="col-xs-12 button-wrapper clearfix">' +
            '<a href="' + d.details.website + '" class="button green-button" target="_blank">Besøg website</a>' +
            '</div>' : '') +
        '</div>' +
        '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>';
}


function localDetailsMobile(d) {
    // `d` is the original data object for the row

    function formatPerson(person) {
        var result = '';
        if (person.name) {
            result += person.name + '<br />';
        }
        if (person.phone1) {
            result += person.phone1 + '<br />';
        }
        if (person.phone2) {
            result += person.phone2 + '<br />';
        }
        if (person.email) {
            result += person.email + '<br />';
        }
        return result;
    }

    var result = '<div class="col-xs-12">' +
        '<b>Jægerråd</b><br />' +
        d.details.jfk + '<br />' +
        '<br />' +
        '<b>Jagtforenings nr.</b><br />' +
        d.number + '<br />' +
        '<br />';

    if (d.details.chairman && d.details.chairman.name) {
        result += '<b>Jagtforeningsformand</b><br />' + formatPerson(d.details.chairman) + '<br />';
    }
    if (d.details.treasurer && d.details.treasurer.name) {
        result += '<b>Jagtforeningskasserer</b><br />' + formatPerson(d.details.treasurer) + '<br />';
    }
    if (d.details.secretary && d.details.secretary.name) {
        result += '<b>Jagtforeningssekretær</b><br />' + formatPerson(d.details.secretary) + '<br />';
    }
    if (d.details.website) {
        result += '<div class="button-wrapper clearfix">' +
            '<a href="' + d.details.website + '" class="button green-button" target="_blank">Besøg website</a>' +
            '</div>';
    }

    return result + '</div>';
}

// 2. Default Settings for DataTables
//$.datepicker.setDefaults($.datepicker.regional["da"]);
$.extend($.fn.dataTable.defaults,
    {
        "bLengthChange": false, // Hide lenght menu
        "dom": '<"mobile-filter"<"button-wrapper"<"#open-filter"><"#open-sorter">><"clear-all">><"sorter-Outerwrapper"<"custom-sorter">><"filter-Outerwrapper"<"custom-filter"><"brush-divider"><"clear-all">f><t><"bottom-pagination" irp>',
        "pagingType": "simple", // Only show the prev/next button
        "language": {
            "processing": "Indlæser..",
            "search": "",
            "searchPlaceholder": "Søg...",
            "lengthMenu": "Vis _MENU_ per side",
            "emptyTable": "Der blev desværre ikke fundet nogle opslag",
            "zeroRecords": "Der blev desværre ikke fundet nogle opslag",
            "info": "Side _PAGE_ af _PAGES_",
            "infoEmpty": "",
            "paginate": {
                "next": "",
                "previous": ""
            }
        },
        "initComplete": function (response) {
            var responseJson = response.json;
            if (!responseJson.success) {
                $('.dataTable .dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
            }
        },
        "infoCallback": function (settings, start, end, max, total, pre) {

            var page = Math.ceil(start / 10);
            $.ajax({
                type: "POST",
                url: "/umbraco/Api/HandleRequestApi/ChangeTablePage/" + page,
                contentType: "application/json; charsect=utf-8",
                dataType: "json",
                data: JSON.stringify(null)
            });

            var api = this.api();
            var pageInfo = api.page.info();
            return 'Side ' + (pageInfo.page + 1) + ' af ' + pageInfo.pages;
        },
        "drawCallback": function (param1, param2, param3, param4) {
            $('.paginate_button.previous').attr("rel", "prev");
            $('.paginate_button.next').attr("rel", "next");


            //var actualTableWrapper = $(param1.nTable).parent();
            //actualTableWrapper.addClass('table-responsive');
        }
    });

// 3. Init of DataTable
$(function () {

    $.fn.dataTable.moment = function (format, locale) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift(function (d) {
            return moment(d, format, locale, true).isValid() ?
                'moment-' + format :
                null;
        });

        // Add sorting method - use an integer for the sorting
        types.order['moment-' + format + '-pre'] = function (d) {
            return moment(d, format, locale, true).unix();
        };
    };

    djf.table.init();
    djf.table.initGridTables();

});

djf.table = new function () {
    var self = this,
        tabbar = $('.tab-bar li');


    this.init = function () {
        if ($('.dataTable#all-table').length) {
            self.dataTableCalender("all-table", "alle");
        }
        var eventtable = $('.dataTable.all-event-table-detector');
        //console.log("eventtable", eventtable);
        if (eventtable.length) {
            for (var i = 0; i < eventtable.length; i++) {
                //console.log("eventtable[i]", eventtable[i]);
                self.dataTableEventCalender(eventtable[i].id, "alle");
            }
        }
        if ($('.dataTable#market-messages').length) {
            self.dataTableMarketMessages("market-messages", "alle");
        }

        var jagtproevenTables = $('.dataTable.jagtproevenHuntingTeamTable');
        if (jagtproevenTables.length) {

            for (var i = 0; i < jagtproevenTables.length; i++) {
                self.dataTableJagtproevenHuntingTeam(jagtproevenTables[i].id);

            }
        }

        var jagerraadTables = $('#jaegerraad-table');

        if (jagerraadTables.length) {
            djf.table.dataTableJaegerraad('jaegerraad-table', 'Jaegerraad');
        }

        // Add readonly to datepicker
        $('.hasDatepicker').attr('readonly', 'readonly');
    };

    this.initGridTables = function () {

        $('.contactpersonTable').each(function () {
            var id = $(this).attr('id'),
                endpoint = $(this).attr('data-url'),
                chosenTitles = $(this).attr('data-chosenTitles'),
                shouldShowAreaInsteadOfCounty = $(this).attr('data-shouldShowAreaInsteadOfCounty');
            isRegionContacts = $(this).attr('data-is-region-contacts') === 'true';
            if (isRegionContacts) {
                self.dataTableContactKreds(id, endpoint, chosenTitles, shouldShowAreaInsteadOfCounty);
            } else {
                self.dataTableContactUdvalg(id, endpoint, chosenTitles, shouldShowAreaInsteadOfCounty);
            }
        });

        $('.eventTable').each(function () {
            var id = $(this).attr('id'),
                endpoint = $(this).attr('data-categoryid') != "" ? $(this).attr('data-categoryid') : "";
            regionId = $(this).attr('data-regionid') != "" ? $(this).attr('data-regionid') : "";

            self.dataTableEventCalender(id, endpoint, regionId);
        });

        $('.memberListAll').each(function () {
            var id = $(this).attr('data-id');
            self.dataTableMemberListAll(id);
            $(this).parent().css('overflow-x', 'scroll');
        });

        $('.memberListArrearsOnly').each(function () {
            var id = $(this).attr('data-id');
            self.dataTableMemberListArrearsOnly(id);
            $(this).parent().css('overflow-x', 'scroll');
        });

        $('.memberListPaidOnly').each(function () {
            var id = $(this).attr('data-id');
            self.dataTableMemberListPaidOnly(id);
            $(this).parent().css('overflow-x', 'scroll');
        });

        $('.memberAnniversary').each(function () {
            var id = $(this).attr('data-id');
            self.dataTableAnniverseryList(id);
            $(this).parent().css('overflow-x', 'scroll');
        });

        $('.gameReportTable').each(function () {
            var id = $(this).attr('id');
            var yearId = $(this).attr('data-yearid');
            self.dataTableGameReport(id, yearId);
        });

        $(".year-select select").on("change", function () {
            var yearId = $(this).find(":checked").val();
            var tableId = $(this).parent().parent().parent().find(".gamejournal-table-container .dataTables_scrollBody table").attr("id");
            if (tableId === undefined) {
                tableId = $(this).parent().parent().parent().find(".gamejournal-table-container table").attr("id");
            }
            $("#reportModel_YearId").val(yearId);
            self.dataTableGameReport(tableId, yearId);
        });

        $('.gameJournalTable').each(function () {
            var id = $(this).attr('id');
            self.dataTableGameJournal(id);
        });

        $(".gameJournalTable").on("click", "tbody tr", function () {
            $(".gamejournal-table-container .loading").addClass("active");
            window.location.href = $(this).attr("data-redirect");

        });

        $('.gameJournalRegistrationTable').each(function () {
            var id = $(this).attr('id');
            self.dataTableGameJournalRegistration(id);
        });
    }


    this.dataTableDefaults = function (d) {
        var el = $('#' + d + ''),
            body = $('body, html'),
            table = el.DataTable(),
            tableWrapper = $('#' + d + '_wrapper'),
            mobileFilter = tableWrapper.find('.mobile-filter'),
            customSorter = tableWrapper.find('.custom-sorter'),
            customFilters = tableWrapper.find('.filter-wrapper'),
            sortFilters = el.find('thead th').not('.sorting_disabled'),
            filterContent = tableWrapper.find('.filter-Outerwrapper').get(),
            sortContent = [],
            filterModal = $('#filterModal');

        // Get the sort content
        if (sortContent.length == 0) {
            sortFilters.each(function () {
                var filterName = $(this).html();
                $(this).attr('data-filter', filterName);

                sortContent.push('<li class="sort-filter" data-filter=' + filterName + '>' + filterName + '</li>');
            });
        }

        // Remove sort-button if no columns has filter
        if (sortFilters.length == 0) {
            tableWrapper.find('#open-sorter').hide();
        }

        // Hide clear-all if custom filters is 0 
        if (customFilters.length == 0) {
            tableWrapper.find('.clear-all').addClass('hidden-desktop');
        }

        // Append overlay triggers to mobileFilter
        mobileFilter.find('#open-filter').html("Filtrer");
        mobileFilter.find('#open-sorter').html("Sorter");
        mobileFilter.find('#create').html("Opret");
        tableWrapper.find('.clear-all').html('<span class="clear-all-span">Ryd filter</span>');
        tableWrapper.find('.clear-all').attr('id', d + "-clear");


        // Remove filter buttons if no-filter
        if (customFilters.length == 0) {
            mobileFilter.find('#open-filter, .clear-all').remove();
        }

        // Open sorter
        tableWrapper.find('#open-sorter').on('click', function (event) {
            event.preventDefault();

            filterModal.find('.modal-body').html('<ul class="sort-list">' + sortContent.join('') + '</ul>');
            filterModal.find('.filter-header span').html('Sorter visning');
            filterModal.find('.button-wrapper').hide();
            filterModal.modal('show');
        });

        tableWrapper.find('#create').on('click', function (event) {
            event.preventDefault();
            window.location.replace("/jagtjournal/journaler/administrer-journal/");
        });

        // Filter the table and close the sorter
        filterModal.on('click', '.sort-filter', function () {
            var dataFilter = $(this).attr('data-filter');
            el.find('thead th[data-filter=' + dataFilter + ']').trigger('click');
            filterModal.modal('hide');
        });

        // Open filter
        tableWrapper.find('#open-filter').on('click', function (event) {
            event.preventDefault();
            filterModal.find('.modal-body').html(filterContent);
            filterModal.find('.filter-header span').html('Filtrer visning');
            filterModal.find('.button-wrapper').show();
            filterModal.modal('show');
        });


        // Close the filter
        filterModal.find('#close-filter').on('click', function (event) {
            event.preventDefault();
            tableWrapper.prepend(filterContent);
            filterModal.modal('hide');
        });

        // Close filters on keyup
        $(document).keyup(function (e) {
            if (e.keyCode == 13 || e.keyCode == 27) {
                $('.filter-Outerwrapper, .sorter-Outerwrapper').removeClass('open');
                body.removeClass('overlay-open');
            }
        });
    }

    // 4. Calender DataTable
    this.dataTableCalender = function (t, dataSrc, region) {
        var el = $('#' + t + '');
        var api = '/umbraco/api/EventApi/All';
        var hasType = true;
        var organizerWidth = 30;
        var isByTag = (dataSrc != '' && dataSrc != 'alle');
        var region = region || "";
        var newApi;
        var table;

        if (dataSrc != '' && dataSrc != 'alle') {
            api += "By?tag=" + dataSrc;
            hasType = false;
            organizerWidth = 60;
        }

        if (region != '') {
            api += (isByTag ? "&" : "?") + "region=" + region;
        }

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Region", "data": "regionName", "bVisible": false },
                { "title": "Lokalforening", "data": "local", "bVisible": false },
                { "title": "Aktivitet", "data": "name", "className": "mobile-full col-sm-3 col-md-5 activity" },
                { "title": "Arrangør", "data": "organizerName", "className": "mobile-" + organizerWidth + " border" },
                { "title": "Type", "data": "type", "className": "mobile-30 border", "bVisible": hasType, "orderable": hasType },
                { "title": "Dato", "data": "dateStart", "type": "date-uk", "className": "mobile-40 border" },
                { "title": "Info", "orderable": false, "defaultContent": "<button><svg class=\"arrow-circle-up iconPlus\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-up\"></use></svg><svg class=\"arrow-circle-down iconMinus\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-down\"></use></svg></button>", "data": null, "className": "details-control" }
            ],
                columnDefs = null,
                bSort = true;


            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            if (isMobileOrTablet) {
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            return '<b>' + row.dateStart + '</b><br />' +
                                row.name + '<br />' +
                                row.organizerName;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns, true);
            }


            table = el.DataTable({
                "ajax": api,
                "columns": columns,
                "columnDefs": columnDefs,
                "order": [[5, "asc"]],
                "initComplete": function (response) {
                    el.addClass('has-details-control');
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });


            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="regionFilter-' + t + '" class="filter-wrapper"></div><div id="localFilter-' + t + '" class="filter-wrapper"></div><div class="brush-divider"></div><div id="dateFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 1, filter_type: "select", filter_default_label: "Alle lokalforeninger", filter_container_id: "localFilter-" + t, filter_match_mode: "exact" },
                { column_number: 5, filter_type: "range_date", date_format: "dd-mm-yyyy", filter_default_label: ["Fra", "Til"], filter_container_id: "dateFilter-" + t }
            ]);



            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'tr:has(td.details-control)', function () {
                var tr = $(this);
                var row = table.row(tr);

                if (row.child.isShown()) {
                    $(this).removeClass('open');
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open');
                    // Open this row
                    if (isMobileOrTablet) {
                        row.child(calenderDetailsMobile(row.data())).show();
                    } else {
                        row.child(calenderDetails(row.data())).show();
                    }
                    tr.addClass('shown');
                }
            });

            // Onchange function on region filter
            $('body').on('change', '#' + t + '-custom-region-filter', function (event) {
                var selectVal = $(this).children('option:selected').val();
                newApi = api + (isByTag ? "&" : "?") + "region=" + selectVal;


                if (selectVal != 0) {
                    table.ajax.url(newApi).load();
                } else {
                    table.ajax.url(api).load();
                }

                // Reset the local-filter
                $('#yadcf-filter-wrapper-localFilter-' + t + ' select').val(-1).change();

            });

            self.initRegionFilter(t, dataSrc);

        } else {
            table = table || {};
        }

        // Load all the default stuff
        self.dataTableDefaults(t);


    };

    this.initRegionFilter = function (d, dataSrc) {
        var $selectWrapper = $('#regionFilter-' + d),
            selectID = '#' + d + '-custom-region-filter',
            options = "<option value='0'>Vælg kreds</option>";

        $selectWrapper.append('<select id="' + selectID.replace('#', '') + '" class="yadcf-filter"></select>');

        for (var i = 1; i < 9; i++) {
            options += "<option value=kreds" + i + ">Kreds " + i + "</option>";
        }
        options += "<option value='30'>HB m.v.</option>";

        $(selectID).prepend(options);
    }

    this.clearFilter = function (t, table) {
        var tableWrapper = $('#' + t + '_wrapper'),
            customRegionFilterId = '#' + t + '-custom-region-filter';

        // Clear all filters
        tableWrapper.find('.clear-all').on('click', function (event) {
            event.preventDefault();
            $(customRegionFilterId).val(0).change();

            yadcf.exResetAllFilters(table);
            table
                .search('')
                .columns().search('')
                .draw();

        });
    }


    // 4. Calender DataTable
    this.dataTableEventCalender = function (t, dataSrc, region, hideRegionDropdown) {
        console.log("t", t);
        console.log("dataSrc", dataSrc);
        var el = $('#' + t + '');
        console.log("el", el);
        var excludeUnion = el.data('exclude-union');
        var searchText = el.data('searchtext');
        if (!region) {
            region = el.data('regionid');
        }
        if (!hideRegionDropdown) {
            hideRegionDropdown = el.data('showdropdown');
        }
        console.log("region", region);

        var api = '/umbraco/api/EventApi/All';
        var hasType = true;
        var organizerWidth = 30;

        var isByTag = (dataSrc != '' && dataSrc != 'alle');

        if (isByTag) {
            api += "By?tag=" + dataSrc;
            hasType = false;
            organizerWidth = 60;
        }

        if (region != '') {
            api += (isByTag ? "&" : "?") + "region=" + region;
            $('#yadcf-filter-wrapper-localFilter-' + t + ' select').val(region).change();
        }
        if (excludeUnion) {
            api += (isByTag || region != '' ? "&" : "?") + "excludeUnionMessages=true";
        }
        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Region", "data": "regionName", "bVisible": false },
                { "title": "Lokalforening", "data": "local", "bVisible": false },
                { "title": "Aktivitet", "data": "name", "className": "mobile-full col-sm-3 col-md-5 activity" },
                { "title": "Arrangør", "data": "organizerName", "className": "mobile-" + organizerWidth + " border" },
                { "title": "Type", "data": "type", "className": "mobile-30 border", "bVisible": hasType, "orderable": hasType },
                { "title": "Dato", "data": "dateStart", "type": "date-uk", "className": "mobile-40 border" },
                { "title": "Mere info", "orderable": false, "defaultContent": "<button><svg class=\"arrow-circle-up\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-up\"></use></svg><svg class=\"arrow-circle-down\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-down\"></use></svg></button>", "data": null, "className": "details-control" }
            ],
                columnDefs = null,
                bSort = true;


            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            var showAsMobileView = el.data('show-as-mobile') == "1";

            if (isMobileOrTablet || showAsMobileView) {
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            return '<b>' + row.dateStart + '</b><br />' +
                                row.name + '<br />' +
                                row.organizerName + '<br />' +
                                row.type;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns, true);
            }

            console.log("before");
            var table = el.DataTable({
                "ajax": api,
                "columns": columns,
                "columnDefs": columnDefs,
                "search": {
                    "search": searchText
                },
                "order": [[5, "asc"]],
                "initComplete": function (response) {
                    el.addClass('has-details-control');
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });
            console.log("after");


            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="regionFilter-' + t + '" class="filter-wrapper"></div><div class="brush-divider"></div><div id="dateFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 5, filter_type: "range_date", date_format: "dd-mm-yyyy", filter_default_label: ["Fra", "Til"], filter_container_id: "dateFilter-" + t }
            ]);

            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'tr:has(td.details-control)', function () {
                var tr = $(this);
                var row = table.row(tr);

                if (row.child.isShown()) {
                    $(this).removeClass('open');
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open');
                    // Open this row
                    row.child(calenderDetails(row.data())).show();
                    tr.addClass('shown');
                }
            });

            // Onchange function on region filter
            $('body').on('change', '#' + t + '-custom-region-filter', function (event) {
                var selectVal = $(this).children('option:selected').val();
                if (api.indexOf('region=') != -1) {
                    var test = api.substring(api.indexOf('region=') + 7);
                    var test1 = "";
                    if (test.indexOf('&') == -1) {
                        test1 = test;
                    } else {
                        test1 = test.substring(0, test.indexOf('&'));
                    }
                    newApi = api.replace("region=" + test1, "region=" + selectVal);
                }
                else {
                    newApi = api + (isByTag ? "&" : "?") + "region=" + selectVal;
                }
                if (selectVal != 0) {
                    table.ajax.url(newApi).load();
                } else {
                    table.ajax.url(api).load();
                }

                // Reset the local-filter
                $('#yadcf-filter-wrapper-localFilter-' + t + ' select').val(-1).change();

            });

            // Show region filter if no region is selected
            console.log("hideRegionDropdown", hideRegionDropdown);
            if (hideRegionDropdown == "False") {
                self.initRegionFilter(t, dataSrc);
            }

        }

        // Load all the default stuff
        self.dataTableDefaults(t);
    };

    // 5. HjorteVildt DataTable
    this.dataTableHjorteVildt = function (t, dataSrc) {
        var el = $('#' + t + ''),
            tableWrapper = $('#' + t + '_wrapper');

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var table = el.DataTable({
                "ajax": '/data/' + dataSrc + '.txt',
                "columns": [
                    { "title": "Område", "data": "area", "className": "mobile-full area" },
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "Postnr", "data": "postal", "className": "mobile-25" },
                    { "title": "Kreds", "data": "regionName", "className": "mobile-25" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false },
                    { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false }
                ],
                "order": [[3, "desc"]], // Pre sort on date
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.before('<h2 class="filter-header"><span>Filtrer visning</span></h2>');
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 6. Udvalg DataTable
    this.dataTableUdvalg = function (t, dataSrc) {
        var el = $('#' + t + '');

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Udvalg", "data": "committee", "className": "mobile-full", "bVisible": false },
                { "title": "sortorder", "data": "sortorder", "className": "mobile-50", "bVisible": false },
                { "title": "Titel", "data": "titel", "className": "mobile-full" },
                { "title": "Navn", "data": "name", "className": "mobile-50" },
                { "title": "Postnr", "data": "postal", "className": "mobile-25" },
                { "title": "By", "data": "town", "className": "mobile-25" },
                { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false },
                { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false },
                { "title": "Udvalg", "data": "committee", "className": "mobile-full", "bVisible": false },
            ],
                columnDefs = null;

            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            if (isMobileOrTablet) {
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            return '<b>' + row.titel + '<br />' +
                                row.name + '</b><br />' +
                                row.postal + ' ' + row.town + '<br />' +
                                row.phone + '<br />' +
                                row.email;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns);
            }

            var tableElement = el.dataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": columns,
                "columnDefs": columnDefs,
                "order": [[3, "asc"]],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }

                    const urlParams = new URLSearchParams(window.location.search);
                    const committee = urlParams.get('udvalg');


                    if (committee != null) {

                        setTimeout(function () {

                            $("#yadcf-filter--udvalg-table-8").val(committee).change();
                        }, 2000);
                    }

                }
            });

            var table = tableElement.api();

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="committeeFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 8, filter_type: "select", filter_default_label: "Alle udvalg", filter_container_id: "committeeFilter-" + t }
            ]);

            // Change sorting based filtering 
            // Sort by name if all committees is chosen (default). Sort by sortorder if one committee is chosen
            var currentVal = '-1';
            var committeeFilterSelect = $('select', '#committeeFilter-' + t);
            function onSearch() {
                console.log('onSearch');
                var newVal = committeeFilterSelect.val();
                if (newVal == currentVal) { return; }

                currentVal = newVal;

                if (newVal == '-1') {
                    tableElement.fnSort([[3, 'asc']]);
                } else {
                    tableElement.fnSort([[1, 'asc']]);
                }
            }
            tableElement.on('search.dt', function () { onSearch(); });




        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    function shouldShowResponsiveTablesAsGrid() {
        //https://getbootstrap.com/docs/3.3/css/#grid-options
        var windowWidth = $(window).width();
        var isMobileOrTablet = !(windowWidth >= 992);
        return isMobileOrTablet;
    }

    function makeColumnsResponsive(columns, skipLast) {
        columns[0]["title"] = '';
        for (var i = 0; i < columns.length; i++) {

            if (skipLast && i == columns.length - 1) {
                return;
            }

            var column = columns[i];
            column["bVisible"] = i == 0;
            column["sortable"] = false;
            column["bSortable"] = false;
            column["orderable"] = false;
        }
    }



    // 7. Kredse DataTable
    this.dataTableKredse = function (t, dataSrc) {

        var el = $('#' + t + '');

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Kommune", "data": "municipality", "bVisible": false },
                { "title": "Kreds", "data": "area", "className": "mobile-40 area" },
                { "title": "Titel", "data": "titel", "className": "mobile-60" },
                { "title": "Navn", "data": "name", "className": "mobile-40" },
                { "title": "Postnr", "data": "postal", "className": "mobile-30" },
                { "title": "By", "data": "town", "className": "mobile-30" },
                { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false },
                { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false }
            ],
                columnDefs = null,
                bSort = true;

            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            if (isMobileOrTablet) {
                bSort = false;
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            //console.log('data', data);
                            //console.log('type', type);
                            //console.log('row', row);
                            return '<b>' + row.titel + ' Kreds ' + row.area + '<br />' +
                                row.name + '</b><br />' +
                                row.postal + ' ' + row.town + '<br />' +
                                row.phone + '<br />' +
                                row.email;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns);
            }
            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "bSort": bSort,
                "columns": columns,
                "columnDefs": columnDefs,
                "order": [[3, "asc"]], // Pre sort on date
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }


                    const urlParams = new URLSearchParams(window.location.search);
                    const title = urlParams.get('titel');
                    const kreds = urlParams.get('kreds');

                    if (kreds != null) {

                        setTimeout(function () {

                            $("#yadcf-filter--kredse-table-1").val(kreds).change();
                        }, 2000);


                    }

                    if (title != null) {

                        setTimeout(function () {

                            console.log(title)
                            $("#yadcf-filter--kredse-table-2").val(title).change();
                        }, 2000);


                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="areaFilter-' + t + '" class="filter-wrapper"></div><div id="titelFilter-' + t + '" class="filter-wrapper"></div><div id="townFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 1, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-" + t },
                {
                    column_number: 2, filter_type: "custom_func", filter_default_label: "Alle titler", filter_container_id: "titelFilter-" + t,
                    custom_func: function (filterVal, columnVal, rowValues, stateVal) {
                        if (filterVal === "-1" || filterVal === columnVal ||
                            (
                                (filterVal === "Kreds-Bestyrelse" || filterVal === "Kredsbestyrelse") &&
                                (
                                    (columnVal === "Kreds-Formand" || columnVal === "Kreds Kasserer")
                                    ||
                                    (columnVal === "Kredsformand" || columnVal === "Kredskasserer")
                                )
                            )
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                },
                { column_number: 5, filter_type: "select", filter_default_label: "Alle byer", filter_container_id: "townFilter-" + t }
            ]);



        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };
    // 8. Lokalforening DataTable
    this.dataTableLokal = function (t, dataSrc) {
        var el = $('#' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {




            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            var columns = [
                { "title": "Kreds", "data": "area", "className": "mobile-20 area border" },
                { "title": "Forening", "data": "name", "className": "mobile-40 border" },
                { "title": "Kommune", "data": "municipality", "className": "mobile-40 border" },
                { "title": "Mere info", "orderable": false, "defaultContent": "<button><svg class=\"arrow-circle-up\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-up\"></use></svg><svg class=\"arrow-circle-down\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-down\"></use></svg></button>", "data": null, "className": "details-control" }
            ],
                columnDefs = null,
                bSort = true;

            if (isMobileOrTablet) {
                bSort = false;
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            //console.log('data', data);
                            //console.log('type', type);
                            //console.log('row', row);
                            return row.area + '<br />' +
                                '<b>' + row.name + '</b><br />' +
                                row.municipality;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns, true);
            }

            var responseItemsJson = [];
            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "bSort": bSort,
                "columns": columns,
                "columnDefs": columnDefs,
                "order": [[1, "asc"]],
                "initComplete": function (response) {
                    el.addClass('has-details-control');
                    console.log("response", response);
                    var responseJson = response.json;
                    responseItemsJson = responseJson.data;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }


                    const urlParams = new URLSearchParams(window.location.search);
                    const title = urlParams.get('title');
                    const kreds = urlParams.get('kreds');
                    const county = urlParams.get('kommune');

                    if (kreds != null) {

                        table.columns(0).search(kreds).draw();

                    }

                    if (county != null) {
                        table.columns(2).search(county).draw();
                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="areaFilter-' + t + '" class="filter-wrapper"></div><div id="municipalityFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 0, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-" + t, filter_match_mode: "exact" },
                { column_number: 2, filter_type: "select", filter_default_label: "Alle kommuner", filter_container_id: "municipalityFilter-" + t }
            ]);



            $('#yadcf-filter--jagtforeninger-table-0, #yadcf-filter--jagtforeninger-table-2').change(function (a) {
                var municipalityFilter = $('#yadcf-filter--jagtforeninger-table-2');
                var currentMunicipalityFilterValue = municipalityFilter.val();
                var regionFilter = $('#yadcf-filter--jagtforeninger-table-0');
                var currentRegionFilterValue = regionFilter.val();
                var isRegionFilter = a.currentTarget.id == regionFilter[0].id;
                if (isRegionFilter) {
                    yadcf.exResetFilters(table, [2]);
                }

                municipalityFilter.html('');
                var defaultoption = '<option value="-1">Alle kommuner</option>';
                municipalityFilter.append(defaultoption);

                var addedOptions = [];
                for (var i = 0; i < responseItemsJson.length; i++) {
                    if ((currentRegionFilterValue == -1 || responseItemsJson[i].area == "" + currentRegionFilterValue) && responseItemsJson[i].municipality && addedOptions.indexOf(responseItemsJson[i].municipality) == -1) {
                        var option = '<option value="' + responseItemsJson[i].municipality + '">' + responseItemsJson[i].municipality + '</option>';
                        municipalityFilter.append(option);
                        addedOptions.push(responseItemsJson[i].municipality);
                    }
                }

                if (!isRegionFilter) {
                    municipalityFilter.val(currentMunicipalityFilterValue);
                } else {
                    municipalityFilter.val(-1);
                }
            });
            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'tr:has(td.details-control)', function () {
                var tr = $(this);
                var row = table.row(tr);

                if (row.child.isShown()) {
                    $(this).removeClass('open');
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open');
                    if (row.data().details === null || row.data().details.website === null) {
                        $.ajax({
                            url: '/umbraco/Api/ContactApi/GetContactInfo?committeeId=' + row.data().number,
                            async: false,
                            success: function (data) {
                                if (data.details !== null) {
                                    row.data().details = {};
                                    row.data().details.website = data.details.website;
                                    row.data().details.chairman = data.details.chairman;
                                    row.data().details.treasurer = data.details.treasurer;
                                    row.data().details.secretary = data.details.secretary;
                                    row.data().details.jfk = data.details.jfk;
                                };
                            }
                        });
                    };

                    // Open this row
                    if (isMobileOrTablet) {
                        row.child(localDetailsMobile(row.data())).show();
                    } else {
                        console.log("row.data()", row.data());
                        row.child(localDetails(row.data())).show();
                    }
                    tr.addClass('shown');
                }
            });
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 9. Admin DataTable 
    this.dataTableAdmin = function (t, dataSrc) {
        var el = $('#' + t + ''),
            tableWrapper = $('#' + t + '_wrapper');


        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts?memberGroup=' + dataSrc + '&shouldCropSmall=true',
                "pageLength": 8,
                "columns": [
                    { "title": "Afdeling", "data": "section", "bVisible": false, "orderable": false },
                    { "title": "Billede", "data": "image", "orderable": false },
                    { "title": "Navn", "data": "name", "bVisible": false }
                ],
                "columnDefs": [
                    {
                        "render": function (data, type, row) {
                            var image = "",
                                position = "",
                                name = "",
                                phone = "",
                                email = "",
                                linkedin = "",
                                workFunction = "";
                            // Check if the values are empty
                            if (row.image) {
                                image = '<figure class="img"><img src="' + row.image + '"></figure>';
                            }
                            if (row.position) {
                                position = '<div class="position"><p>' + row.position + '</p></div>';
                            }
                            if (row.name) {
                                name = '<div class="name"><p>' + row.name + '</p></div>';
                            }
                            if (row.phone) {
                                phone = '<div class="phone"><p>Tlf: ' + row.phone + '</p></div>';
                            }
                            if (row.email) {
                                email = '<div class="email"><a href="mailto:' + row.email + '">' + row.email + '</a></div>';
                            }
                            if (row.linkedin) {
                                linkedin = '<div class="linkedin"><a href="' + row.linkedin + '" target="_blank"><i class="icon-linkedin"></i></a></div>';
                            }
                            if (row.function) {
                                workFunction = '<div class="title">Funktion</div><div class="function"><p>' + row.function + '</p></div>';
                            }


                            return '<div class="row row-content">' +
                                '<div class="col col-xs-4 col-sm-3">' +
                                image +
                                '</div>' +
                                '<div class="col col-xs-8 col-sm-9">' +
                                '<div class="row">' +
                                '<div class="col col-xs-12 col-sm-6">' +
                                position +
                                name +
                                phone +
                                email +
                                linkedin +
                                '</div>' +
                                '<div class="col col-xs-12 col-sm-6">' +
                                workFunction +
                                '</div>' +
                                '</div>' +
                                '</div>' +
                                '</div>' +
                                '</div>';

                        },
                        "targets": 1
                    }
                ],
                "fnDrawCallback": function (o) {
                    el.find('tr.even').after('<div class="clear"></div>');
                },
                "order": [[2, "asc"]],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }

                    const urlParams = new URLSearchParams(window.location.search);
                    const department = urlParams.get('afdeling');

                    if (department != null) {

                        table.columns(0).search(department).draw();

                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="sectionFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 0, filter_type: "select", filter_default_label: "Alle afdelinger", filter_container_id: "sectionFilter-" + t }
            ]);




        }
        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 10. Hovedbestyrelse DataTable 
    this.dataTableHovedBestyrelse = function (t, dataSrc) {
        var el = $('#' + t + ''),
            tableWrapper = $('#' + t + '_wrapper');


        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts?memberGroup=' + dataSrc + '&shouldCropSmall=true',
                "pageLength": 8,
                "bSort": false,
                "columns": [
                    { "title": "Billede", "data": "image", "orderable": false },
                    { "title": "kreds", "data": "area", "bVisible": false },
                    { "title": "Navn", "data": "name", "bVisible": false }
                ],
                "columnDefs": [
                    {
                        "render": function (data, type, row) {
                            var image = "",
                                position = "",
                                name = "",
                                phone = "",
                                email = "",
                                linkedin = "",
                                workFunction = "";

                            // Check if the values are empty
                            if (row.image) {
                                image = '<figure class="img"><img src="' + row.image + '"></figure>';
                            }
                            if (row.position) {
                                position = '<div class="position"><p>' + row.position + '</p></div>';
                            }
                            if (row.name) {
                                name = '<div class="name"><p>' + row.name + '</p></div>';
                            }
                            if (row.phone) {
                                phone = '<div class="phone"><p>Tlf: ' + row.phone + '</p></div>';
                            }
                            if (row.email) {
                                email = '<div class="email"><a href="mailto:' + row.email + '">' + row.email + '</a></div>';
                            }
                            if (row.linkedin) {
                                linkedin = '<div class="linkedin"><a href="' + row.linkedin + '" target="_blank"><i class="icon-linkedin"></i></a></div>';
                            }
                            if (row.function) {
                                workFunction = '<div class="title">Funktion</div><div class="function"><p>' + row.function + '</p></div>';
                            }


                            return '<div class="row row-content">' +
                                '<div class="col col-xs-4 col-sm-3">' +
                                image +
                                '</div>' +
                                '<div class="col col-xs-8 col-sm-9">' +
                                '<div class="row">' +
                                '<div class="col col-xs-12 col-sm-6">' +
                                position +
                                name +
                                phone +
                                linkedin +
                                email +
                                '</div>' +
                                '<div class="col col-xs-12 col-sm-6">' +
                                workFunction +
                                '</div>' +
                                '</div>' +
                                '</div>' +
                                '</div>' +
                                '</div>';

                        },
                        "targets": 0
                    }
                ],
                "fnDrawCallback": function (o) {
                    el.find('tr.even').after('<div class="clear"></div>');
                },
                "order": [[2, "asc"]],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="areaFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 1, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-" + t }
            ]);
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 6. Udvalg DataTable
    this.dataTableGrupper = function (t, dataSrc) {
        var el = $('#' + t + '');

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Gruppe", "data": "titel", "className": "mobile-50" },
                { "title": "sortorder", "data": "sortorder", "className": "mobile-50", "bVisible": false },
                { "title": "Navn", "data": "name", "className": "mobile-50" },
                { "title": "By", "data": "town", "className": "mobile-75" },
                { "title": "Postnr", "data": "postal", "className": "mobile-25" },
                { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false },
                { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false },
                { "title": "Gruppe", "data": "committee", "className": "mobile-50", "bVisible": false },
            ],
                columnDefs = null;

            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            if (isMobileOrTablet) {
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            //console.log('row', row);
                            return '<b>' + row.titel + '<br />' +
                                row.name + '</b><br />' +
                                row.postal + ' ' + row.town + '<br />' +
                                row.phone + '<br />' +
                                row.email;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns);
            }

            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": columns,
                "columnDefs": columnDefs,
                "order": [[2, "asc"]],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }


                    const urlParams = new URLSearchParams(window.location.search);
                    const group = urlParams.get('gruppe');

                    if (group != null) {

                        table.columns(0).search(group).draw();

                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="groupFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 7, filter_type: "select", filter_default_label: "Alle grupper", filter_container_id: "groupFilter-" + t }
            ]);



        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    // 11 Markedsplads beskeder
    this.dataTableMarketMessages = function (t, dataSrc) {
        console.log("hi");
        var el = $('#' + t + '');
        console.log("el", el);
        var searchText = el.data('searchtext');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            var organizerWidth = 30;

            var columns = [
                { "title": "Annonce", "data": "ID", "bVisible": false },
                { "title": "Afsender", "data": "ConversationWith", "bVisible": false },
                { "title": "Tidspunkt", "data": "LastMessageReceivedAt", "bVisible": false }
            ],
                columnDefs = null,
                bSort = true;


            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            //var showAsMobileView = el.data('show-as-mobile') == "1";

            if (isMobileOrTablet) {
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            console.log("row", row);
                            return '<b>' + row.dateStart + '</b><br />' +
                                row.name + '<br />' +
                                row.type;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns, true);
            }


            var table = el.DataTable({
                "ajax": "/umbraco/api/MarketApi/GetDataListConversations",
                "columns": columns,
                "columnDefs": columnDefs,
                "search": {
                    "search": searchText
                },
                "order": [[0, "asc"]],
                "initComplete": function (response) {
                    el.addClass('has-details-control');
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });


            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="areaFilter-' + t + '" class="filter-wrapper"></div>');

            yadcf.init(table, [
                { column_number: 1, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-" + t }
            ]);
        }
    };

    this.dataTableJaegerraad = function (t, dataSrc) {
        var el = $('#' + t + '');

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Kreds", "data": "area", "className": "mobile-40 area width-50" },
                { "title": "Kommune", "data": "municipality", "className": "mobile-50" },
                { "title": "Titel", "data": "titel", "className": "mobile-50" },
                { "title": "Navn", "data": "name", "className": "mobile-50" },
                { "title": "Mail", "data": "email", "className": "mobile-60 border", "orderable": false },
                { "title": "Telefon", "data": "phone", "className": "mobile-40 border", "orderable": false },
                { "title": "Kommune", "data": "municipality", "className": "mobile-50", "bVisible": false }
            ],
                columnDefs = null;

            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            if (isMobileOrTablet) {
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            //console.log('data', data);
                            //console.log('type', type);
                            console.log('row', row);
                            return '<b>' + row.municipality + '<br />' +
                                row.titel + '<br />' +
                                row.name + '</b><br />' +
                                row.phone + '<br />' +
                                row.email;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns);
            }

            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContacts' + dataSrc,
                "columns": columns,
                "columnDefs": columnDefs,
                "order": [[0, "asc"]],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }

                    const urlParams = new URLSearchParams(window.location.search);
                    const title = urlParams.get('titel');
                    const kreds = urlParams.get('kreds');
                    const county = urlParams.get('kommune');

                    if (title != null) {

                        table.columns(2).search(title).draw();

                    }

                    if (kreds != null) {
                        table.columns(0).search(kreds).draw();
                    }

                    if (county != null) {

                        table.columns(1).search(county).draw();


                    }
                }

            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="areaFilter-' + t + '" class="filter-wrapper"></div><div id="titelFilter-' + t + '" class="filter-wrapper"></div><div id="municipalityFilter-' + t + '" class="filter-wrapper"></div>');
            console.log("table", table);
            yadcf.init(table, [
                { column_number: 0, filter_type: "select", filter_default_label: "Alle kredse", filter_container_id: "areaFilter-" + t },
                { column_number: 2, filter_type: "select", filter_default_label: "Alle titler", filter_container_id: "titelFilter-" + t },
                { column_number: 1, filter_type: "select", filter_default_label: "Alle kommuner", filter_container_id: "municipalityFilter-" + t }
            ]);




        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    this.dataTableContactUdvalg = function (t, dataSrc, chosenTitles, shouldShowAreaInsteadOfCounty) {
        var el = $('#' + t + '');
        var isJaegerRaad = false;
        console.log("chosentitles", chosenTitles);
        // Check on the dataSrc if the src is Jægerråd
        if (dataSrc === "Kontaktperson for regulering i lokale Jægerråd") {
            isJaegerRaad = true;
        }

        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            var data_title = "";
            var data_data = "";
            if (shouldShowAreaInsteadOfCounty == "True") {
                data_title = "Område";
                data_data = "titel";
                //console.log("shouldShowAreaInsteadOfCounty was true", shouldShowAreaInsteadOfCounty);
            }
            else {
                if (isJaegerRaad) {
                    data_title = "Jægerråd";
                    data_data = "municipality";
                } else {
                    data_title = "Kreds";
                    data_data = "area";
                }
            }
            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContactsUdvalg?roleName=' + encodeURIComponent(dataSrc) + (chosenTitles && chosenTitles.length > 0 ? '&chosenTitles=' + encodeURIComponent(chosenTitles) : ''),
                "columns": [
                    { "title": data_title, "data": data_data, "className": "mobile-50 area" },
                    { "title": "Navn", "data": "name", "className": "mobile-50" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border" },
                    { "title": "Mail", "data": "email", "className": "mobile-60 border" }
                ],
                "order": [1, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    this.dataTableGameReport = function (t, yearId) {
        var el = $('#' + t + '');
        console.log(el);
        // Check on the dataSrc if the src is Jægerråd

        // Init the table if it is not already
        var gottenData = null;
        if (el.find('tr').length == 0) {
            var table = el.DataTable({
                searching: false,
                "ajax": '/umbraco/api/GameJournalApi/GetGameReportAsDataList?huntingYearId=' + yearId,
                "dom": '<"mobile-filter"<"button-wrapper"<"#open-filter"><"#open-sorter"><"#create">><"clear-all">><"sorter-Outerwrapper"<"custom-sorter">><"filter-Outerwrapper"<"custom-filter"><"brush-divider">f><t><"bottom-pagination" irp>',
                "columns": [
                    {
                        title: "<section class='wrap'><input type='checkbox' class='check-all'> <span>Indberet</span></section>",
                        data: "Status",
                        searchable: false,
                        sortable: false,
                        className: "mobile-40 checkbox-container",
                        render: function (data, type, row) {
                            if (data === false) {
                                return '<input type="checkbox">';
                            }
                            else {
                                return "";
                            }
                            //return data;
                        },

                    },
                    { "title": "Status", "data": "StatusText", "className": "mobile-60 border " },
                    { "title": "Dato", "data": "HuntDate", "className": "mobile-50 area" },
                    { "title": "Dato", "data": "HuntDateText", "className": "mobile-50 area" },
                    { "title": "Kommune", "data": "MunicipalityText", "className": "mobile-50" },
                    { "title": "Art", "data": "GameSpeciesText", "className": "mobile-40 border" },
                    { "title": "Nedlagt/fanget", "data": "ShotOrCaughtText", "className": "mobile-60 border" },
                    { "title": "Køn", "data": "Sex", "className": "mobile-60 border" }
                ],
                "order": [2, "DESC"],
                "createdRow": function (row, data, dataIndex) {
                    $(row).attr('data-journal-id', data.ID);
                },
                "columnDefs": [
                    {
                        "targets": [2],
                        "visible": false,
                        "render": function (data, type, row) {
                            var date = new Date(data);
                            return "" + date.getFullYear();
                        }
                    }
                ],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Der blev ikke fundet noget vildt som kan indberettes.");
                    }
                    gottenData = responseJson.data;
                    self.clearFilter(t, table);
                },
                "scrollX": true
            });
        }
        else {
            el.dataTable({
                "destroy": true,
                searching: false,
                "ajax": '/umbraco/api/GameJournalApi/GetGameReportAsDataList?huntingYearId=' + yearId,
                "dom": '<"mobile-filter"<"button-wrapper"<"#open-filter"><"#open-sorter"><"#create">><"clear-all">><"sorter-Outerwrapper"<"custom-sorter">><"filter-Outerwrapper"<"custom-filter"><"brush-divider">f><t><"bottom-pagination" irp>',
                "columns": [
                    {
                        title: "<section class='wrap'><input type='checkbox' class='check-all'> <span>Indberet</span></section>",
                        data: "Status",
                        searchable: false,
                        sortable: false,
                        className: "mobile-40 checkbox-container",
                        render: function (data, type, row) {
                            if (data === false) {
                                return '<input type="checkbox">';
                            }
                            else {
                                return "";
                            }
                            //return data;
                        },
                    },
                    { "title": "Status", "data": "StatusText", "className": "mobile-60 border " },
                    { "title": "Dato", "data": "HuntDate", "className": "mobile-50 area" },
                    { "title": "Dato", "data": "HuntDateText", "className": "mobile-50 area" },
                    { "title": "Kommune", "data": "MunicipalityText", "className": "mobile-50" },
                    { "title": "Art", "data": "GameSpeciesText", "className": "mobile-40 border" },
                    { "title": "Nedlagt/fanget", "data": "ShotOrCaughtText", "className": "mobile-60 border" },
                    { "title": "Køn", "data": "Sex", "className": "mobile-60 border" }
                ],
                "order": [2, "DESC"],
                "createdRow": function (row, data, dataIndex) {
                    $(row).attr('data-journal-id', data.ID);
                },
                "columnDefs": [
                    {
                        "targets": [2],
                        "visible": false,
                        "render": function (data, type, row) {
                            var date = new Date(data);
                            return "" + date.getFullYear();
                        }
                    },
                ],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Der blev ikke fundet noget vildt som kan indberettes.");
                    }
                    gottenData = responseJson.data;
                    self.clearFilter(t, table);
                }
            });
        }

        // Load all the default stuff
        self.dataTableDefaults(t);
    };

    this.dataTableGameJournal = function (t) {
        var el = $('#' + t + '');
        // Check on the dataSrc if the src is Jægerråd

        // Init the table if it is not already
        var gottenData = null;
        if (el.find('tr').length == 0) {
            var table = el.DataTable({
                "ajax": '/umbraco/api/GameJournalApi/GetGameJournalsAsDataList',
                "dom": '<"mobile-filter"<"button-wrapper"<"#open-filter"><"#open-sorter"><"#create">><"clear-all">><"sorter-Outerwrapper"<"custom-sorter">><"filter-Outerwrapper"<"custom-filter"><"brush-divider">f><t><"bottom-pagination" irp>',
                "columns": [
                    { "title": "Dato", "data": "HuntDate", "className": "mobile-50 area" },
                    {
                        "title": "Dato", "data": "HuntDate", "className": "mobile-50 area", "render": function (data) {
                            var date = new Date(data);
                            var month = date.getMonth() + 1;
                            var dayOfTheMonth = date.getDate();
                            console.log("dayOfTheMonth", dayOfTheMonth);
                            console.log("dayOfTheMonth", ("" + dayOfTheMonth).length);
                            console.log("month", month);
                            console.log("month", ("" + month).length);
                            return (("" + dayOfTheMonth).length > 1 ? dayOfTheMonth : "0" + dayOfTheMonth) + "-" + (("" + month).length > 1 ? month : "0" + month) + "-" + date.getFullYear();
                        }
                    },
                    { "title": "Kommune/Land", "data": "MunicipalityText", "className": "mobile-50" },
                    { "title": "Revir", "data": "Territory", "className": "mobile-40 border" },
                    { "title": "Nedlagt", "data": "Killed", "className": "mobile-60 border" },
                    { "title": "Fanget", "data": "Caught", "className": "mobile-60 border" }
                ],
                "columnDefs": [
                    {
                        "targets": [0],
                        "visible": false,
                        "render": function (data, type, row) {
                            var date = new Date(data);
                            return "" + date.getFullYear();
                        }
                    }
                ],
                "createdRow": function (row, data, dataIndex) {
                    $(row).attr('data-redirect', "/jagtjournal/journaler/vis-journal/?id=" + data.ID);
                },
                "order": [0, "desc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Du har endnu ikke oprettet en jagtjournal.");
                    }
                    gottenData = responseJson.data;
                    self.clearFilter(t, table);

                    // Custom filters
                    var filterContainer = $('#' + t + '_filter');
                    var customFilter = filterContainer.siblings('.custom-filter');

                    customFilter.prepend('<div id="groupFilter-' + t + '" class="filter-wrapper"><a href="/jagtjournal/journaler/administrer-journal/">Opret ny jagtjournal</a></div>');
                    var foundOptions = [];
                    for (var i = 0; i < gottenData.length; i++) {
                        var current = new Date(gottenData[i].HuntDate).getFullYear();
                        if (foundOptions.indexOf(current) == -1) {
                            foundOptions.push(current);
                        }
                    }
                    foundOptions.sort();
                    var optionsArr = [];
                    for (var j = 0; j < foundOptions.length; j++) {
                        optionsArr.push({ value: foundOptions[j], label: foundOptions[j] });
                    }
                    yadcf.init(table, [
                        {
                            column_number: 1,
                            filter_container_id: "groupFilter-" + t,
                            filter_default_label: "Alle jagtår",
                            filter_type: "select",
                            date_format: "yyyy",
                            data: optionsArr
                        }
                    ]);
                }
            });
        }

        // Load all the default stuff
        self.dataTableDefaults(t);
    };

    this.dataTableGameJournalRegistration = function (t) {
        var el = $('#' + t + '');
        var gameJournalId = el.attr("data-gameJournalId");
        // Check on the dataSrc if the src is Jægerråd

        // Init the table if it is not already
        var gottenData = null;
        if (el.find('tr').length == 0) {
            var table = el.DataTable({
                paginate: false,
                "ajax": '/umbraco/api/GameJournalApi/GetGameJournalRegistrationAsDataList?gameJournalId=' + gameJournalId,
                "dom": '',
                "columns": [
                    { "title": "Antal", "data": "NumberOfKills", "className": "mobile-50" },
                    { "title": "Art", "data": "GameSpeciesText", "className": "mobile-40 border" },
                    {
                        "title": "Nedlagt/Fanget", "data": "ShotOrCaught", "className": "mobile-60 border", "render": function (data) {
                            if (data) {
                                return "Fanget";
                            } else {
                                return "Nedlagt";
                            }
                        }
                    },
                    {
                        "title": "Regulering", "data": "Regulation", "className": "mobile-60 border", "render": function (data) {
                            if (data) {
                                return "Ja";
                            } else {
                                return "Nej";
                            }
                        }
                    },
                    { "title": "Brækket vægt (kg)", "data": "WeightBreaking", "className": "mobile-50" },
                    { "title": "Levende vægt (kg)", "data": "WeightAlive", "className": "mobile-50" },
                    { "title": "Køn", "data": "Sex", "className": "mobile-50" },
                    { "title": "Alder (år)", "data": "Age", "className": "mobile-50" }
                ],
                //"columnDefs": [
                //    {
                //        "targets": [0],
                //        "visible": false,
                //        "render": function (data, type, row) {
                //            var date = new Date(data);
                //            return "" + date.getFullYear();
                //        }
                //    }
                //],
                //"order": [1, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Du har endnu ikke tilføjet vildt.");
                    }
                    gottenData = responseJson.data;
                    self.clearFilter(t, table);
                }
            });
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    this.dataTableContactKreds = function (t, dataSrc, chosenTitles, shouldShowAreaInsteadOfCounty) {
        console.log("chosenTitles", chosenTitles);
        var el = $('#' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            if (shouldShowAreaInsteadOfCounty == "True") {
                data_title = "Område";
                data_data = "titel";
                //console.log("shouldShowAreaInsteadOfCounty was true", shouldShowAreaInsteadOfCounty);
            }
            else {
                data_title = "Titel";
                data_data = "titel";
            }
            var table = el.DataTable({
                "ajax": '/umbraco/Api/ContactApi/GetContactsKreds?regionNumber=' + encodeURIComponent(dataSrc) + '&chosenTitles=' + encodeURIComponent(chosenTitles),
                "columns": [
                    { "title": data_title, "data": data_data, "className": "mobile-60" },
                    { "title": "Navn", "data": "name", "className": "mobile-40" },
                    { "title": "Telefon", "data": "phone", "className": "mobile-40 border" },
                    { "title": "Mail", "data": "email", "className": "mobile-60 border" }
                ],
                "order": [1, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');
        }

        // Load all the default stuff
        self.dataTableDefaults(t);

    };

    this.dataTableAnniverseryList = function (t) {
        var el = $('#table-' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            var table = el.DataTable({
                "ajax": {
                    url: '/umbraco/Api/ContactApi/',
                    dataSrc: function (d) {

                        renderCategoriCount(d, t);
                        return d.data;
                    }
                },
                "columns": [
                    { "title": "DJnr", "data": "memberNumber" },
                    { "title": "Status", "data": "status" },
                    { "title": "Fornavn", "data": "firstName" },
                    { "title": "Efternavn", "data": "lastName" },
                    { "title": "Adresse 1", "data": "address1" },
                    { "title": "Adresse 2", "data": "address2" },
                    { "title": "Postnr", "data": "zip" },
                    { "title": "By", "data": "city" },
                    { "title": "Indm1", "data": "creationDate", "type": "date-uk" },
                    //{ "title": "Indm2", "data": "firstEnrolmentDate", "type": "date-uk" },
                    { "title": "Indm2", "data": "latestEnrolmentDate", "type": "date-uk" },
                    { "title": "Udm", "data": "withdrawelDate", "type": "date-uk" },
                    { "title": "Opkrævet", "data": "collected" },
                    { "title": "Indbetalt", "data": "paidAmount" },
                    { "title": "Skyldigt", "data": "dueAmount" },
                    { "title": "IndbDato", "data": "paymentDate", "type": "date-uk" },
                    { "title": "Tlf 1", "data": "phone1" },
                    { "title": "Tlf 2", "data": "phone2" },
                    { "title": "Bemærk", "data": "remarks" },
                    { "title": "Email", "data": "email" }
                ],
                "order": [2, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');
        }
        // Load all the default stuff
        self.dataTableDefaults(t);
    };

    this.dataTableMemberListAll = function (t) {
        var el = $('#table-' + t + '');
        var select = $('#subscriptions-' + t + '');
        var downloadCsv = $('#download-csv-' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            var loadingElement = $(".loading");
            loadingElement.addClass("active");
            var table = el.DataTable({
                "ajax": {
                    url: '/umbraco/Api/ContactApi/GetMemberListAll/?committeeId=' + select.val(),
                    dataSrc: function (d) {

                        renderCategoriCount(d, t);
                        renderAgeSeggregationCount(d, t);
                        var data = null;
                        if (d.data) {
                            data = d.data;
                        } else if (d.Data) {
                            data = d.Data;
                        }
                        return data;
                    }
                },
                "rowCallback": function (row, data, index) {
                    if (!data || !row) {
                        return;
                    }

                    if (data.newHunter === true) {
                        row.style.backgroundColor = "#F7CEB6";
                    }

                },
                "columns": [
                    { "title": "DJnr", "data": "memberNumber" },
                    { "title": "Status", "data": "status" },
                    { "title": "Fornavn", "data": "firstName" },
                    { "title": "Efternavn", "data": "lastName" },
                    { "title": "Adresse 1", "data": "address1" },
                    { "title": "Adresse 2", "data": "address2" },
                    { "title": "Postnr", "data": "zip" },
                    { "title": "By", "data": "city" },
                    { "title": "Indm1", "data": "creationDate", "type": "date-uk" },
                    //{ "title": "Indm2", "data": "firstEnrolmentDate", "type": "date-uk" },
                    { "title": "Indm2", "data": "latestEnrolmentDate", "type": "date-uk" },
                    { "title": "Udm", "data": "withdrawelDate", "type": "date-uk" },
                    { "title": "Opkrævet", "data": "collected" },
                    { "title": "Indbetalt", "data": "paidAmount" },
                    { "title": "Skyldigt", "data": "dueAmount" },
                    { "title": "IndbDato", "data": "paymentDate", "type": "date-uk" },
                    { "title": "Tlf 1", "data": "phone1" },
                    { "title": "Tlf 2", "data": "phone2" },
                    { "title": "Bemærk", "data": "remarks" },
                    { "title": "Email", "data": "email" }
                ],
                "order": [2, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                    loadingElement.removeClass("active");
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#table-' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            injectNewHunterNotice(customFilter);
            $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListAllAsCsv/?committeeId=' + select.val());

            $(select).on('change',
                function () {
                    loadingElement.addClass("active");
                    table.ajax.url('/umbraco/Api/ContactApi/GetMemberListAll/?committeeId=' + select.val()).load(function () {
                        loadingElement.removeClass("active");
                    });
                    $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListAllAsCsv/?committeeId=' + select.val());
                });
            var ageSeggretationTo1 = $('#ageSeggretationTo1-' + t);
            var ageSeggretationFrom1 = $('#ageSeggretationFrom1-' + t);
            var ageSeggretationTo2 = $('#ageSeggretationTo2-' + t);
            var ageSeggretationFrom2 = $('#ageSeggretationFrom2-' + t);
            var ageSeggretationTo3 = $('#ageSeggretationTo3-' + t);
            var ageSeggretationFrom3 = $('#ageSeggretationFrom3-' + t);
            var ageSeggretationTo4 = $('#ageSeggretationTo4-' + t);
            var ageSeggretationFrom4 = $('#ageSeggretationFrom4-' + t);
            var ageSeggretationTo5 = $('#ageSeggretationTo5-' + t);
            var ageSeggretationFrom5 = $('#ageSeggretationFrom5-' + t);
            $('#age-seggregation-btn-' + t).click(function () {
                console.log("-seggregation-btn was clicked");
                table.ajax.url('/umbraco/Api/ContactApi/GetMemberListAll/?committeeId=' + select.val() +
                    '&ageSeggretationTo1=' + ageSeggretationTo1.val() + '&ageSeggretationFrom1=' + ageSeggretationFrom1.val() +
                    '&ageSeggretationTo2=' + ageSeggretationTo2.val() + '&ageSeggretationFrom2=' + ageSeggretationFrom2.val() +
                    '&ageSeggretationTo3=' + ageSeggretationTo3.val() + '&ageSeggretationFrom3=' + ageSeggretationFrom3.val() +
                    '&ageSeggretationTo4=' + ageSeggretationTo4.val() + '&ageSeggretationFrom4=' + ageSeggretationFrom4.val() +
                    '&ageSeggretationTo5=' + ageSeggretationTo5.val() + '&ageSeggretationFrom5=' + ageSeggretationFrom5.val()
                ).load();
            });
        }
        // Load all the default stuff
        self.dataTableDefaults(t);
        $('.memberListAnniversary').each(function () {
            self.dataTableMemberListAnniversary(t, 'all');
            $(this).parent().css('overflow-x', 'scroll');
        });
    };
    this.dataTableMemberListArrearsOnly = function (t) {
        var el = $('#table-' + t + '');
        var select = $('#subscriptions-' + t + '');
        var downloadCsv = $('#download-csv-' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            var loadingElement = $(".loading");
            loadingElement.addClass("active");
            var table = el.DataTable({
                "ajax": {
                    url: '/umbraco/Api/ContactApi/GetMemberListArrearsOnly/?committeeId=' + select.val(),
                    dataSrc: function (d) {

                        renderCategoriCount(d, t);
                        renderAgeSeggregationCount(d, t);
                        var data = null;
                        if (d.data) {
                            data = d.data;
                        } else if (d.Data) {
                            data = d.Data;
                        }
                        return data;
                    }
                },
                "language": {
                    "emptyTable": "Der blev heldigvis ikke fundet nogle opslag",
                    "zeroRecords": "Der blev heldigvis ikke fundet nogle opslag",
                },
                "rowCallback": function (row, data, index) {
                    if (!data || !row) {
                        return;
                    }

                    if (data.newHunter === true) {
                        row.style.backgroundColor = "#F7CEB6";
                    }

                },
                "columns": [
                    { "title": "DJnr", "data": "memberNumber" },
                    { "title": "Status", "data": "status" },
                    { "title": "Fornavn", "data": "firstName" },
                    { "title": "Efternavn", "data": "lastName" },
                    { "title": "Adresse 1", "data": "address1" },
                    { "title": "Adresse 2", "data": "address2" },
                    { "title": "Postnr", "data": "zip" },
                    { "title": "By", "data": "city" },
                    { "title": "Køn", "data": "gender" },
                    { "title": "Født d.", "data": "birthDate" },
                    { "title": "Indm1", "data": "creationDate", "type": "date-uk" },
                    //{ "title": "Indm2", "data": "firstEnrolmentDate", "type": "date-uk" },
                    { "title": "Indm2", "data": "latestEnrolmentDate", "type": "date-uk" },
                    { "title": "Udm", "data": "withdrawelDate", "type": "date-uk" },
                    { "title": "Opkrævet", "data": "collected" },
                    { "title": "Indbetalt", "data": "paidAmount" },
                    { "title": "Skyldigt", "data": "dueAmount" },
                    { "title": "Tlf 1", "data": "phone1" },
                    { "title": "Tlf 2", "data": "phone2" },
                    { "title": "Bemærk", "data": "remarks" },
                    { "title": "Email", "data": "email" }
                ],
                "order": [2, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                    loadingElement.removeClass("active");
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#table-' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            injectNewHunterNotice(customFilter);

            $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListArrearsOnlyAsCsv/?committeeId=' + select.val());

            $(select).on('change',
                function () {
                    loadingElement.addClass("active");
                    table.ajax.url('/umbraco/Api/ContactApi/GetMemberListArrearsOnly/?committeeId=' + select.val()).load(function () {
                        loadingElement.removeClass("active");
                    });
                    $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListArrearsOnlyAsCsv/?committeeId=' + select.val());
                });
            var ageSeggretationTo1 = $('#ageSeggretationTo1-' + t);
            var ageSeggretationFrom1 = $('#ageSeggretationFrom1-' + t);
            var ageSeggretationTo2 = $('#ageSeggretationTo2-' + t);
            var ageSeggretationFrom2 = $('#ageSeggretationFrom2-' + t);
            var ageSeggretationTo3 = $('#ageSeggretationTo3-' + t);
            var ageSeggretationFrom3 = $('#ageSeggretationFrom3-' + t);
            var ageSeggretationTo4 = $('#ageSeggretationTo4-' + t);
            var ageSeggretationFrom4 = $('#ageSeggretationFrom4-' + t);
            var ageSeggretationTo5 = $('#ageSeggretationTo5-' + t);
            var ageSeggretationFrom5 = $('#ageSeggretationFrom5-' + t);
            $('#age-seggregation-btn-' + t).click(function () {
                console.log("-seggregation-btn was clicked");
                table.ajax.url('/umbraco/Api/ContactApi/GetMemberListArrearsOnly/?committeeId=' + select.val() +
                    '&ageSeggretationTo1=' + ageSeggretationTo1.val() + '&ageSeggretationFrom1=' + ageSeggretationFrom1.val() +
                    '&ageSeggretationTo2=' + ageSeggretationTo2.val() + '&ageSeggretationFrom2=' + ageSeggretationFrom2.val() +
                    '&ageSeggretationTo3=' + ageSeggretationTo3.val() + '&ageSeggretationFrom3=' + ageSeggretationFrom3.val() +
                    '&ageSeggretationTo4=' + ageSeggretationTo4.val() + '&ageSeggretationFrom4=' + ageSeggretationFrom4.val() +
                    '&ageSeggretationTo5=' + ageSeggretationTo5.val() + '&ageSeggretationFrom5=' + ageSeggretationFrom5.val()
                ).load();
            });
        }
        // Load all the default stuff
        self.dataTableDefaults(t);

        $('.memberListAnniversary').each(function () {
            self.dataTableMemberListAnniversary(t, 'arrearsOnly');
            $(this).parent().css('overflow-x', 'scroll');
        });
    };

    this.dataTableMemberListPaidOnly = function (t) {
        var el = $('#table-' + t + '');
        var select = $('#subscriptions-' + t + '');
        var ageSeggretationTo1 = $('#ageSeggretationTo1-' + t);
        var ageSeggretationFrom1 = $('#ageSeggretationFrom1-' + t);
        var ageSeggretationTo2 = $('#ageSeggretationTo2-' + t);
        var ageSeggretationFrom2 = $('#ageSeggretationFrom2-' + t);
        var ageSeggretationTo3 = $('#ageSeggretationTo3-' + t);
        var ageSeggretationFrom3 = $('#ageSeggretationFrom3-' + t);
        var ageSeggretationTo4 = $('#ageSeggretationTo4-' + t);
        var ageSeggretationFrom4 = $('#ageSeggretationFrom4-' + t);
        var ageSeggretationTo5 = $('#ageSeggretationTo5-' + t);
        var ageSeggretationFrom5 = $('#ageSeggretationFrom5-' + t);
        var downloadCsv = $('#download-csv-' + t + '');
        // Init the table if it is not already
        if (el.find('tr').length == 0) {
            var loadingElement = $(".loading");
            loadingElement.addClass("active");

            var table = el.DataTable({
                "ajax": {
                    url: '/umbraco/Api/ContactApi/GetMemberListPaidOnly/?committeeId=' + select.val(),

                    dataSrc: function (d) {

                        renderCategoriCount(d, t);
                        renderAgeSeggregationCount(d, t);
                        var data = null;
                        if (d.data) {
                            data = d.data;
                        } else if (d.Data) {
                            data = d.Data;
                        }
                        return data;
                    }
                },
                "columns": [
                    { "title": "DJnr", "data": "memberNumber" },
                    { "title": "Status", "data": "status" },
                    { "title": "Fornavn", "data": "firstName" },
                    { "title": "Efternavn", "data": "lastName" },
                    { "title": "Adresse 1", "data": "address1" },
                    { "title": "Adresse 2", "data": "address2" },
                    { "title": "Postnr", "data": "zip" },
                    { "title": "By", "data": "city" },
                    { "title": "Køn", "data": "gender" },
                    { "title": "Født d.", "data": "birthDate" },
                    { "title": "Indm1", "data": "creationDate", "type": "date-uk" },
                    //{ "title": "Indm2", "data": "firstEnrolmentDate", "type": "date-uk" },
                    { "title": "Indm2", "data": "latestEnrolmentDate", "type": "date-uk" },
                    { "title": "Udm", "data": "withdrawelDate", "type": "date-uk" },
                    { "title": "Tlf 1", "data": "phone1" },
                    { "title": "Tlf 2", "data": "phone2" },
                    { "title": "Bemærk", "data": "remarks" },
                    { "title": "Email", "data": "email" }

                ],
                "rowCallback": function (row, data, index) {
                    if (!data || !row) {
                        return;
                    }

                    if (data.newHunter === true) {
                        row.style.backgroundColor = "#F7CEB6";
                    }

                },
                "order": [2, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                    loadingElement.removeClass("active");
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#table-' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            injectNewHunterNotice(customFilter);

            $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListPaidOnlyAsCsv/?committeeId=' + select.val());

            $(select).on('change',
                function () {
                    loadingElement.addClass("active");
                    table.ajax.url('/umbraco/Api/ContactApi/GetMemberListPaidOnly/?committeeId=' + select.val()).load(function () {
                        loadingElement.removeClass("active");
                    });
                    $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListPaidOnlyAsCsv/?committeeId=' + select.val());
                });

            $('#age-seggregation-btn-' + t).click(function () {
                console.log("-seggregation-btn was clicked");
                table.ajax.url('/umbraco/Api/ContactApi/GetMemberListPaidOnly/?committeeId=' + select.val() +
                    '&ageSeggretationTo1=' + ageSeggretationTo1.val() + '&ageSeggretationFrom1=' + ageSeggretationFrom1.val() +
                    '&ageSeggretationTo2=' + ageSeggretationTo2.val() + '&ageSeggretationFrom2=' + ageSeggretationFrom2.val() +
                    '&ageSeggretationTo3=' + ageSeggretationTo3.val() + '&ageSeggretationFrom3=' + ageSeggretationFrom3.val() +
                    '&ageSeggretationTo4=' + ageSeggretationTo4.val() + '&ageSeggretationFrom4=' + ageSeggretationFrom4.val() +
                    '&ageSeggretationTo5=' + ageSeggretationTo5.val() + '&ageSeggretationFrom5=' + ageSeggretationFrom5.val()
                ).load();
            });
            //ageSeggretationFrom1.change(function () {
            //    console.log("ageSeggretationFrom1", ageSeggretationFrom1);
            //});
            //$(ageSeggretationFrom1).change(function () {
            //    console.log("ageSeggretationFrom1", ageSeggretationFrom1);
            //    table.ajax.url('/umbraco/Api/ContactApi/GetMemberListPaidOnly/?committeeId=' + select.val() + '&ageSeggretationTo1=' + ageSeggretationTo1.val() + '&ageSeggretationFrom1=' + ageSeggretationFrom1.val()).load();
            //});
        }
        // Load all the default stuff
        self.dataTableDefaults(t);

        $('.memberListAnniversary').each(function () {
            self.dataTableMemberListAnniversary(t, 'paidOnly');
            $(this).parent().css('overflow-x', 'scroll');
        });
    };

    function injectNewHunterNotice(customFilterDiv) {
        if (!customFilterDiv.length) {
            return;
        }

        var div = document.createElement("DIV");
        div.className = "new-hunter-info-container";

        var divText = document.createElement("DIV");
        divText.className = "new-hunter-info-text";
        divText.innerHTML = 'Medlemmer med denne markeringsfarve er nyjægere.';

        div.appendChild(divText);

        customFilterDiv[0].appendChild(div);
    }


    this.dataTableMemberListAnniversary = function (tableID, type) {
        var el = $('#table-anniversary-' + tableID + '');
        var downloadCsv = $('#download-anniversary-csv-' + tableID + '');
        var select = $('#subscriptions-' + tableID + '');
        if (el.find('tr').length == 0) {
            var table = el.DataTable({
                "ajax": {
                    url: '/umbraco/Api/ContactApi/GetMemberListAnniversary/?committeeId=' + select.val() + '&type=' + type
                },
                "columns": [
                    { "title": "Jubilæumsår", "data": "year" },
                    { "title": "Jubilæumstype", "data": "typeOfAnniversary" },
                    { "title": "Jubilæumsform", "data": "typeOfMembership" },
                    { "title": "Jubilæumsdato", "data": "dateOfAnniversary" },
                    { "title": "DJnr", "data": "axMembershipID" },
                    { "title": "Status", "data": "status" },
                    { "title": "Fornavn", "data": "firstName" },
                    { "title": "Efternavn", "data": "lastName" },
                    { "title": "Adresse 1", "data": "address1" },
                    { "title": "Adresse 2", "data": "address2" },
                    { "title": "Postnr", "data": "zipCode" },
                    { "title": "By", "data": "city" },
                    { "title": "Telefon", "data": "phoneNumber" }
                ],
                "order": [0, "asc"],
                "initComplete": function (response) {
                    var responseJson = response.json;
                    if (!responseJson.success) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                    }
                }
            });

            self.clearFilter(tableID, table);

            // Custom filters
            var filterContainer = $('#' + tableID + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');
            $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListAnniversaryAsCsv/?committeeId=' + select.val() + '&type=' + type);
            $(select).on('change',
                function () {
                    table.ajax.url('/umbraco/Api/ContactApi/GetMemberListAnniversary/?committeeId=' + select.val() + '&type=' + type).load();
                    $(downloadCsv).attr('href', '/umbraco/Api/ContactApi/GetMemberListAnniversaryAsCsv/?committeeId=' + select.val() + '&type=' + type);
                });
        }
        // Load all the default stuff
        self.dataTableDefaults(tableID);
    };


    this.allJagtproevenPins = [];
    this.setupJagtproevenMaps = function (elementContainer, gridElementID) {


        var defaultCenter = {
            lat: 56.307649,
            lng: 9.840101
        };

        var defaultZoomOnCreate = 7;
        var map = new google.maps.Map(document.getElementById(gridElementID + "-map"), {
            center: defaultCenter,
            zoom: defaultZoomOnCreate
        });

        self.deleteAllPins();
        if (elementContainer.Teams && elementContainer.Teams.length > 0) {
            for (var i = 0; i < elementContainer.Teams.length; i++) {
                var team = elementContainer.Teams[i];
                self.createJagtproevenMapPin(team, map, true);
            }
        }

        if (elementContainer.Visitcards && elementContainer.Visitcards.length > 0) {
            for (var i = 0; i < elementContainer.Visitcards.length; i++) {
                var visitcard = elementContainer.Visitcards[i];
                self.createJagtproevenMapPin(visitcard, map, false);
            }
        }

    }

    this.deleteAllPins = function () {
        for (var i = 0; i < self.allJagtproevenPins.length; i++) {
            self.allJagtproevenPins.googleMarker.setMap(null);
        }

        self.allJagtproevenPins = [];
    }

    this.createJagtproevenMapPin = function (element, map, isTeam) {
        if (!element.latitude || element.latitude == 0 || !element.longitude || element.longitude == 0) {
            return;
        }

        var latLng = {
            lat: parseFloat(element.latitude),
            lng: parseFloat(element.longitude)
        };

        var pin = {
            info: element,
            googleMarker: new google.maps.Marker({
                position: latLng,
                icon: (isTeam == true ? "/Images/jagtproevenpin_red.png?width=32" : "/Images/jagtproevenpin_grey.png?width=32"),
                map: map
            }),
            infoWindow: new google.maps.InfoWindow()
        };
        if (isTeam) {
            pin.infoWindow.setContent(self.createJagtproevenTeamInfoWindow(element));

        } else {
            pin.infoWindow.setContent(self.createJagtproevenVisitCardInfoWindow(element));
        }
        pin.infoWindow.setPosition(latLng);
        pin.googleMarker.addListener('click', function () {
            for (var i = 0; i < self.allJagtproevenPins.length; i++) {
                self.allJagtproevenPins[i].infoWindow.close();
            }
            pin.infoWindow.open(map, pin.googleMarker);
        });

        self.allJagtproevenPins.push(pin);
    }

    this.getUrlParameter = function (sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    this.createJagtproevenVisitCardInfoWindow = function (pin) {

        var container = document.createElement("DIV");
        container.setAttribute("class", "jagtproeven-marker-infowindow-container");

        if (pin.city) {
            var p_Name = document.createElement("P");
            p_Name.innerHTML = pin.visitcardName;
            p_Name.setAttribute("class", "margin-bottom10");
            container.appendChild(p_Name);
        }

        if (pin.city) {
            var p_City = document.createElement("P");
            p_City.innerHTML = pin.city;
            p_City.setAttribute("class", "margin-bottom10");
            container.appendChild(p_City);
        }

        if (pin.website) {
            var a_Website = document.createElement("A");
            a_Website.setAttribute("href", pin.website);
            a_Website.setAttribute("class", "display-block margin-bottom10");
            a_Website.innerHTML = "Tilmeld via website";
            container.appendChild(a_Website);
        }

        if (pin.visitcardEmail) {
            var a_Email = document.createElement("A");
            a_Email.setAttribute("href", "mailto:" + pin.visitcardEmail);
            a_Email.setAttribute("class", "display-block margin-bottom10");
            a_Email.innerHTML = "Tilmeld via email";
            container.appendChild(a_Email);
        }

        if (pin.visitcardPhone) {

            var p_Phone = document.createElement("P");
            p_Phone.setAttribute("class", "margin-bottom10");
            p_Phone.innerHTML = "Tlf: " + pin.visitcardPhone;
            container.appendChild(p_Phone);
        }

        if (pin.committeeName) {
            var p_committeeName = document.createElement("P");
            p_committeeName.innerHTML = pin.committeeName;
            container.appendChild(p_committeeName);
        }


        return container;
    }

    this.createJagtproevenTeamInfoWindow = function (pin) {

        var container = document.createElement("DIV");
        container.setAttribute("class", "jagtproeven-marker-infowindow-container");

        if (pin.name) {
            var p_Name = document.createElement("P");
            p_Name.innerHTML = pin.name;
            p_Name.setAttribute("class", "margin-bottom10");
            container.appendChild(p_Name);
        }

        if (pin.startDate) {
            var p_StartDate = document.createElement("P");
            p_StartDate.innerHTML = "Start: " + pin.startDate;
            p_StartDate.setAttribute("class", "margin-bottom10");
            container.appendChild(p_StartDate);
        }

        if (pin.endDate) {
            var p_EndDate = document.createElement("P");
            p_EndDate.innerHTML = "Slut: " + pin.endDate;
            p_EndDate.setAttribute("class", "margin-bottom10");
            container.appendChild(p_EndDate);
        }

        if (pin.city || pin.contactName) {
            var p_Address = document.createElement("P");


            p_Address.innerHTML = pin.contactName + (pin.city && pin.contactName ? ", " : "") + pin.city;
            p_Address.setAttribute("class", "margin-bottom10");
            container.appendChild(p_Address);
        }

        if (pin.contactWebsite) {
            var a_Website = document.createElement("A");
            a_Website.setAttribute("href", pin.contactWebsite);
            a_Website.setAttribute("class", "display-block margin-bottom10");
            a_Website.innerHTML = "Tilmeld via website";
            container.appendChild(a_Website);
        }

        if (pin.contactEmail) {
            var a_Email = document.createElement("A");
            a_Email.setAttribute("href", "mailto:" + pin.contactEmail);
            a_Email.setAttribute("class", "display-block margin-bottom10");
            a_Email.innerHTML = "Tilmeld via email";
            container.appendChild(a_Email);
        }

        if (pin.contactPhone) {
            var p_Phone = document.createElement("P");
            p_Phone.innerHTML = "Tlf: " + pin.contactPhone;
            container.appendChild(p_Phone);
        }

        return container;
    }

    this.dataTableJagtproevenHuntingTeam = function (t) {
        var el = $('#' + t + '');

        var api = '/umbraco/api/JagtproevenHuntingTeamApi/Get';

        // Init the table if it is not already
        if (el.find('tr').length == 0) {

            var columns = [
                { "title": "Start", "data": "startDate", "type": "date-uk" },
                { "title": "Slut", "data": "endDate", "type": "date-uk" },
                { "title": "Underviser", "data": "contactName", "className": "mobile-full col-sm-3 col-md-5" },
                { "title": "By", "data": "city", "className": "border" }
                , {
                    "title": "Mere info", "orderable": false, "defaultContent": "<button><svg class=\"arrow-circle-up\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-up\"></use></svg><svg class=\"arrow-circle-down\"><use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle-down\"></use></svg></button>", "data": null, "className": "details-control"
                }
            ];
            columnDefs = null;
            bSort = true;


            var isMobileOrTablet = shouldShowResponsiveTablesAsGrid();

            var showAsMobileView = el.data('show-as-mobile') == "1";

            if (isMobileOrTablet || showAsMobileView) {
                bSort = false;
                columnDefs = [
                    {
                        "render": function (data, type, row) {
                            return '<b>' + row.startDate + '</b><br />' +
                                row.contactName + '<br />' +
                                row.city;
                        },
                        "targets": 0
                    }
                ];
                makeColumnsResponsive(columns, true);
            }
            var prefilterCityZipCode = self.getUrlParameter("cityZipCode");
            var table = el.DataTable({
                "ajax": {
                    url: api,
                    dataSrc: function (d) {
                        return d.Item.Teams;
                    }
                },
                "bSort": bSort,
                "columns": columns,
                "columnDefs": columnDefs,
                "search": {
                    "search": prefilterCityZipCode
                },
                "order": [[0, "asc"], [3, "asc"], [1, "asc"]],
                "initComplete": function (response) {
                    el.addClass('has-details-control');
                    var responseJson = response.json;
                    if (!responseJson || responseJson.Success == false || responseJson.success == false) {
                        el.find('.dataTables_empty').text("Vi er i gang med at opdatere vores systemer, og det er derfor ikke muligt at vise data i øjeblikket.");
                        return;
                    }

                    self.setupJagtproevenMaps(response.json.Item, t);
                }
            });

            self.clearFilter(t, table);

            // Custom filters
            var filterContainer = $('#' + t + '_filter');
            var customFilter = filterContainer.siblings('.custom-filter');

            customFilter.prepend('<div id="dateFilterStart-' + t + '" class="filter-wrapper jagtproeven-filter"></div><div id="dateFilterEnd-' + t + '" class="filter-wrapper jagtproeven-filter"></div>');

            yadcf.init(table, [
                {
                    column_number: 0, filter_type: "range_date", date_format: "dd-mm-yyyy", filter_default_label: ["Seneste start dato", "undefined"], filter_container_id: "dateFilterStart-" + t
                },
                {
                    column_number: 1, filter_type: "range_date", date_format: "dd-mm-yyyy", filter_default_label: ["undefined", "Seneste slut dato"], filter_container_id: "dateFilterEnd-" + t
                }
            ]);

            // Add event listener for opening and closing details
            el.find('tbody').on('click', 'tr:has(td.details-control)', function () {
                var tr = $(this);
                var row = table.row(tr);

                if (row.child.isShown()) {
                    $(this).removeClass('open');
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    $(this).addClass('open');
                    // Open this row
                    if (isMobileOrTablet) {
                        row.child(jagtproevenDetailsMobile(row.data())).show();
                    } else {
                        row.child(jagtproevenDetails(row.data())).show();
                    }
                    tr.addClass('shown');
                }
            });
        }

        // Load all the default stuff
        self.dataTableDefaults(t);
    };
    // Render the categori sum-up
    function renderCategoriCount(d, id) {
        // TODO: Insert your code
        if (d.instancesCount) {
            var $categoriTable = $('.table-categories-' + id + '');
            var obj = d.instancesCount;
            var total = 0;
            $('.table-categories-' + id + ' td.member-table-content.member-table-content--first').parent().remove();
            Object.keys(d.instancesCount).forEach(function (key) {
                total += obj[key];
                // do something with obj[key]
                var tablerow = '<tr>' +
                    '<td class="member-table-content member-table-content--first">' + [key] + '</td>' +
                    '<td class="member-table-content member-table-content--second">' + obj[key] + '</td>' +
                    '</tr>';
                $categoriTable.append(tablerow);
            });

            // Add the total
            var tablerow = '<tr>' +
                '<td class="member-table-content member-table-content--first member-table-content--total">' + "total" + '</td>' +
                '<td class="member-table-content member-table-content--second member-table-content--total">' + total + '</td>' +
                '</tr>';
            $categoriTable.append(tablerow);
        }
    }
    function renderAgeSeggregationCount(d, id) {
        // TODO: Insert your code
        console.log("d", d);
        if (d.ageSeggregation) {

            var $categoriTable = $('.table-age-seggregation-' + id + '');
            var obj = d.ageSeggregation;
            var total = 0;
            $('.table-age-seggregation-' + id + ' td.member-table-content.member-table-content--first').parent().remove();
            Object.keys(d.ageSeggregation).forEach(function (key) {
                total += obj[key];
                // do something with obj[key]
                var tablerow = '<tr>' +
                    '<td class="member-table-content member-table-content--first">' + [key] + '</td>' +
                    '<td class="member-table-content member-table-content--second">' + obj[key] + '</td>' +
                    '</tr>';
                $categoriTable.append(tablerow);
            });

            // Add the total
            var tablerow = '<tr>' +
                '<td class="member-table-content member-table-content--first member-table-content--total">' + "total" + '</td>' +
                '<td class="member-table-content member-table-content--second member-table-content--total">' + total + '</td>' +
                '</tr>';
            $categoriTable.append(tablerow);
        }
    }
};

//Subscription / Kontigent
$('#SelectLocalCommittee').change(function () {
    var dataTables = $('.subscription-dataTable');
    dataTables.each(function (index) {
        $(this).removeClass('active');
    });

    var notices = $('.change-period-expiration-notice');
    notices.each(function () {
        $(this).removeClass('active');
    });

    var notificationBoxes = $('.date-outside-change-period');
    notificationBoxes.each(function () {
        $(this).removeClass('active');
    });

    var id = $('#SelectLocalCommittee')[0].value;

    var dataTable = $('.committeeId-' + id);
    dataTable.addClass('active');

    $('.notification-box.success').hide();
    $('.notification-box.error').hide();
});

var committee = $('#SelectLocalCommittee')[0];
if (committee) {
    var id = committee.value;
    $('.committeeId-' + id).addClass('active');
}
