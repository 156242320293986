var djf = djf || {};


$(function () {
    djf.unionmessages.showModals();
    djf.unionmessageForm.init();

});

function unionMessagesStartDateChanged(e) {
    var value = e.target.value;

    var currentEndDateVal = $("#unionMessage_EndDate").val();
    if (!currentEndDateVal) {
        $("#unionMessage_EndDate").val(value);
    }
}

djf.unionmessages = new function () {
    var self = this;

    this.showModals = function () {
        var $modal = $('#union-modal');

        if ($modal.length) {
            $modal.modal();
        }

    };
};

djf.unionmessageForm = new function () {
    var self = this;

    this.autoSelectOnCommitteeHuntingAssociationChangeSync = 0;
    //this.autoSelectOnCommitteeHuntingCouncilChangeSync = 0;

    this.autoSelectOnCommitteeHuntingAssociationChange = function () {

        var sync = ++self.autoSelectOnCommitteeHuntingAssociationChangeSync;

        var elm = $(this);
        var selectedOption = $('option:selected', elm);
        var associationId = elm.val();
        var huntingCouncilId = selectedOption.data('huntingcouncilid');
        var regionId = selectedOption.data('regionid');

        if (huntingCouncilId.length == 0) {
            $.ajax('/umbraco/Surface/UnionMessage/GetAssociationInfo/' + associationId).done(function (data) {
                data = $.parseJSON(data);
                huntingCouncilId = data.AssociatedCommitteeHuntingCouncilId;
                selectedOption.data('huntingcouncilid', huntingCouncilId);
                if (sync != self.autoSelectOnCommitteeHuntingAssociationChangeSync) {
                    //User has already selected another value
                    return;
                }
                self.selectHuntingCouncil(huntingCouncilId);
                self.selectRegion(regionId);
            });
        } else {
            self.selectHuntingCouncil(huntingCouncilId);
            self.selectRegion(regionId);
        }
    };

    this.autoSelectOnCommitteeHuntingCouncilChange = function () {

        //var sync = ++self.autoSelectOnCommitteeHuntingCouncilChangeSync;

        var elm = $(this);
        var selectedOption = $('option:selected', elm);
        var regionId = selectedOption.data('regionid');

        self.selectRegion(regionId);
    };

    this.selectHuntingCouncil = function (huntingCouncilId) {
        if (huntingCouncilId == '0000000') {
            $('#unionMessage_CommitteeHuntingCouncilId').val('');
        } else if (huntingCouncilId.length > 0 &&
            $('#unionMessage_CommitteeHuntingCouncilId option[value=' + huntingCouncilId + ']').length > 0) {
            $('#unionMessage_CommitteeHuntingCouncilId').val(huntingCouncilId);
        }
    };

    this.selectRegion = function (regionId) {
        if (regionId == '0000000') {
            $('#unionMessage_CommitteeRegionId').val('');
        } else if (regionId.length > 0 &&
            $('#unionMessage_CommitteeRegionId option[value=' + regionId + ']').length > 0) {
            $('#unionMessage_CommitteeRegionId').val(regionId);
        }
    };

    this.init = function () {
        $('#unionMessage_CommitteeHuntingAssociationId').change(self.autoSelectOnCommitteeHuntingAssociationChange);
        $('#unionMessage_CommitteeHuntingCouncilId').change(self.autoSelectOnCommitteeHuntingCouncilChange);

        var shouldShowInMagazine = $('#show-in-magazine').is(":checked");
        if (!shouldShowInMagazine) {
            $('#magazine-id-list, .js-magazine-text').slideUp();
        }


        $('#show-in-magazine').click(function (ev) {
            var isChecked = ev.target.checked;

            if (isChecked) {
                $('#magazine-id-list, .js-magazine-text').slideDown();
            } else {
                $('#magazine-id-list, .js-magazine-text').slideUp();
            }
        })


        $("#unionMessage_File").change(function () {
            filename = this.files[0].name;
            $("#unionMessage_File_filename").html(filename);
        });
    };
}
