/**
  * @desc Responsive image script, servering the image depending on parent-wrapper width and aspect-ratio parameter
  * html example <figure class="top-image responsiveimg" data-imgsrc="@imageUrl" data-ratio="57" data-alt="image caption"></figure>
  * js example novicell.responsiveImages.init()
  * @author Danni Larsen - DLA, JHA - Jonas Havmøller
  * @return <img>-tag inside selector
  * @requires jquery@1.11.3
  * @note: Remember to run this on resize too and remember to add and image-tag in a no-script-tag for older browsers
*/
        

var novicell = novicell || {};

novicell.responsiveImages = {
    init: function(el) {

        [].forEach.call(document.querySelectorAll('.responsiveimg'), function (el) {
            console.log("el", el);
            var ratio = $(el).attr('data-ratio') || "";
            if (ratio == "") {
                var dataW = el.getAttribute('data-width');
                var dataH = el.getAttribute('data-height');
                var w = dataW == null || dataW == 0 ? $(el).parent().width() : dataW;
                var h = dataH == null ? "" : dataH;
                var alt = el.getAttribute('data-alt') || "";
                var hString = h != "" ? '&height=' + h : "";
                var src = el.getAttribute('data-imgsrc');
                var wRnd = Math.ceil(w / 50) * 50;
            } else {
                var w = $(el).width() != 0 ? $(el).width() : $(el).parent().width(); // If the value is null, take the width of parent container
                var alt = $(el).attr('data-alt') || "";
                var ratioRnd = Math.round(w / 100 * ratio);
                var hString = ratio != "" ? '&height=' + ratioRnd : "";
                var src = $(el).attr('data-imgsrc');
                var wRnd = Math.ceil(w / 50) * 50;

            }



            // Init the image
            if ($(el).hasClass('bg-image')) {

                var hString = hString == "" ? '&height=' + $(el).parent().height() : hString;

                // If its a background image apply the style to the parent div
                $(el).closest('div').attr('style', 'background: url("' + src + '&width=' + wRnd + hString + '&quality=80&mode=crop") center center no-repeat; background-size: cover;');

            } else {
                $(el).html('<img src="' + src + '&width=' + wRnd + hString + '&quality=80&mode=crop" alt="' + alt + '">');
            }
        });
    }
};

$(function () {
    novicell.responsiveImages.init();
});

$(window).smartresize(function (e) {
    novicell.responsiveImages.init();
});
