var djf = djf || {};

djf.gameJournalExport = new function () {

    this.export = function () {
        console.log("exporting...");
        var encodedUri = encodeURI('/umbraco/api/gamejournalapi/ExportCsv');
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "JagtjournalExport.csv");
        document.body.appendChild(link);
        console.log("link", link);
        link.click();
    }
};



