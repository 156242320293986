var djf = djf || {};
djf.gameJournalGoogleMaps = djf.gameJournalGoogleMaps || {};

$(document).ready(function () {
    djf.gameJournalGoogleMaps.initMaps();
});

djf.gameJournalGoogleMaps = new function () {
    this.countryRestriction = null;
    this.initGameJournalMap = new function () {
        var self = this;
        this.activeMap = {};
        this.defaultCenter = {
            lat: 56.307649,
            lng: 9.840101
        };
        this.defaultZoom = 16;
        this.defaultZoomOnCreate = 7;
        this.times = [];

        this.getLocation = function () {

            var lng = parseFloat($("#gameJournal_LocationGPSLongitude").val());
            var lat = parseFloat($("#gameJournal_LocationGPSLatitude").val());

            if (lng && lat) {
                var positionobj = {
                    coords: {
                        latitude: lat,
                        longitude: lng
                    }
                };
                djf.gameJournalGoogleMaps.initGameJournalMap.init(positionobj);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(djf.gameJournalGoogleMaps.initGameJournalMap.init, djf.gameJournalGoogleMaps.initGameJournalMap.init);
                } else {
                    djf.gameJournalGoogleMaps.initGameJournalMap.init();
                }
            }
        }

        this.init = function (position) {
            var latLng = null;
            var geocoder = new google.maps.Geocoder;
            //console.log("position", position);
            var usedDefault = false;
            if (position && position.coords) {
                latLng = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                if ($("#gameJournal_LocationGPSLongitude").val() == "" || $("#gameJournal_LocationGPSLongitude").val() == undefined) {
                    $("#gameJournal_LocationGPSLongitude").val(position.coords.longitude);
                }

                if ($("#gameJournal_LocationGPSLatitude").val() == "" || $("#gameJournal_LocationGPSLatitude").val() == undefined) {
                    $("#gameJournal_LocationGPSLatitude").val(position.coords.latitude);
                }

                geocoder.geocode({ 'location': latLng }, function (results, status) {
                    if ($('#gameJournal_LocationText').val() == "") {
                        if (status === 'OK') {
                            if (results[0]) {
                                $('#gameJournal_LocationText').val(results[0].formatted_address);
                                $('#gameJournal_LocationText_hidden').val(results[0].formatted_address).trigger('change');
                            }
                        } else {
                            $('#gameJournal_LocationText').val('');
                            $('#gameJournal_LocationText_hidden').val('').trigger('change');
                        }
                    }
                });
            } else {
                latLng = self.defaultCenter;
                console.log("used default", latLng);
                usedDefault = true;
            }
            var containers = $('.game-journal-map-container');
            for (var i = 0; i < containers.length; i++) {
                var mapID = containers[i].id;
                var map = null;
                var zoom = self.defaultZoom;
                if (usedDefault) {
                    zoom = self.defaultZoomOnCreate;
                }
                console.log("used zoom", zoom);
                map = new google.maps.Map(document.getElementById(mapID), {
                    center: latLng,
                    zoom: zoom,
                    disableDefaultUI: true
                });
                self.activeMap = {
                    map: map,
                    id: mapID,
                    marker: null
                };
            }

            var current = self.activeMap;
            current.map.addListener('click', function (event) {
                geocoder.geocode({ 'location': event.latLng }, function (results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            $('#gameJournal_LocationText').val(results[0].formatted_address).trigger('change');
                            $('#gameJournal_LocationText_hidden').val(results[0].formatted_address).trigger('change');
                        }
                    } else {
                        $('#gameJournal_LocationText').val('').trigger('change');
                        $('#gameJournal_LocationText_hidden').val('').trigger('change');
                    }
                });
                //self.getAnimalsForArea(event.latLng.lat(), event.latLng.lng());
                setNewMarker(event.latLng.lat(), event.latLng.lng());
            });
            self.setupAutoCompletes();

            if (!usedDefault) {
                setNewMarker(latLng.lat, latLng.lng);
            }
        };

        this.setupAutoCompletes = function () {
            autocompletePlace(document.getElementById("gameJournal_LocationText"));
        }

        function setNewMarker(lat, lng, panToMarker) {
            if (self.activeMap.marker) {
                self.activeMap.marker.setMap(null);
            }
            self.activeMap.marker = new google.maps.Marker({
                position: { lng: lng, lat: lat },
                map: self.activeMap.map
            });
            if (panToMarker) {
                panTo(lat, lng);
            }

            $("#gameJournal_LocationGPSLongitude").val(lng);
            $("#gameJournal_LocationGPSLatitude").val(lat);
        }

        var panPath = [];   // An array of points the current panning action will use
        var panQueue = [];  // An array of subsequent panTo actions to take
        var STEPS = 50;     // The number of steps that each panTo action will undergo

        function panTo(newLat, newLng) {
            if (panPath.length > 0) {
                // We are already panning...queue this up for next move
                panQueue.push([newLat, newLng]);
            } else {
                // Lets compute the points we'll use
                panPath.push("LAZY SYNCRONIZED LOCK");  // make length non-zero - 'release' this before calling setTimeout
                var curLat = self.activeMap.map.getCenter().lat();
                var curLng = self.activeMap.map.getCenter().lng();
                var dLat = (newLat - curLat) / STEPS;
                var dLng = (newLng - curLng) / STEPS;

                for (var i = 0; i < STEPS; i++) {
                    panPath.push([curLat + dLat * i, curLng + dLng * i]);
                }
                panPath.push([newLat, newLng]);
                panPath.shift();      // LAZY SYNCRONIZED LOCK
                setTimeout(doPan, 5);
            }
        }

        function doPan() {
            var next = panPath.shift();
            if (next != null) {
                // Continue our current pan action
                self.activeMap.map.panTo(new google.maps.LatLng(next[0], next[1]));
                setTimeout(doPan, 5);
            } else {
                // We are finished with this pan - check if there are any queue'd up locations to pan to 
                var queued = panQueue.shift();
                if (queued != null) {
                    panTo(queued[0], queued[1]);
                }
            }
        }

        function autocompletePlace(inp) {

            /*the autocomplete function takes two arguments,
            the text field element and an array of possible autocompleted values:*/
            var currentFocus;

            function inputChanged(element) {
                var val = element.value;
                if (val.length == 0) {
                    inp.placeholder = "";
                    closeAllLists();
                    return;
                }//+ val

                //create service
                service = new google.maps.places.AutocompleteService();

                //perform request. limit results to selected country
                var request = {
                    input: val,
                    componentRestrictions: { country: (djf.gameJournalGoogleMaps.countryRestriction ? djf.gameJournalGoogleMaps.countryRestriction : 'dk') },
                    types: ['geocode']
                };
                service.getPlacePredictions(request, displaySuggestions);

            }

            var displaySuggestions = function (predictions, status) {
                var element = document.getElementById("gameJournal_LocationText");
                var arr = [];
                var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
                var url = "";
                arr = predictions;
                /*close any already open lists of autocompleted values*/
                closeAllLists();
                //if (!val) { return false; }
                currentFocus = -1;
                /*create a DIV element that will contain the items (values):*/
                a = document.createElement("DIV");
                a.setAttribute("id", element.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                /*append the DIV element as a child of the autocomplete container:*/
                element.parentNode.appendChild(a);
                /*for each item in the array...*/
                for (i = 0; i < arr.length; i++) {
                    var s = escapeSearchInput(arr[i].description).toUpperCase();
                    if (s.indexOf(val) != -1) {
                        /*create a DIV element for each matching element:*/
                        b = document.createElement("DIV");
                        /*make the matching letters bold:*/
                        b.innerHTML += arr[i].description;

                        if (arr[i].sted && arr[i].sted.kommuner.length > 0) {
                            b.innerHTML += ' (' + arr[i].sted.kommuner[0].description + ' Kommune)';
                        }
                        //                /*insert a input field that will hold the current array item's value:*/
                        b.innerHTML += "<input type='hidden' value='" + arr[i].description + "' data-name='" + arr[i].description + "'>";
                        //$('#dropdownCounties_value').trigger('change');
                        /*execute a function when someone clicks on the item value (DIV element):*/
                        //////////////////////////////////////////////////////////////////////////////
                        b.addEventListener("click", function (e) {
                            /*insert the value for the autocomplete text field:*/
                            var obj = $(e.srcElement);
                            var inputField = obj.find("input");
                            var name = inputField.data("name");
                            var request = {
                                query: name,
                                locationBias: { north: 57.818172, south: 54.265083, east: 15.915587, west: 5.000653 },
                                fields: ['formatted_address', 'geometry']
                            };
                            service = new google.maps.places.PlacesService(self.activeMap.map);
                            service.findPlaceFromQuery(request, focusLocation);
                            $('#gameJournal_LocationText').val(name);
                            $('#gameJournal_LocationText_hidden').val(name).trigger('change');
                            /*close the list of autocompleted values,
                            (or any other open lists of autocompleted values:*/
                            closeAllLists();

                        });
                        a.appendChild(b);

                    }
                }
                var aAsJquery = $(a);
                a.setAttribute("style", "width:" + (aAsJquery.width() + 1) + "px;");
            };
            var focusLocation = function (results, status) {
                var result = results[0];
                setNewMarker(result.geometry.location.lat(), result.geometry.location.lng(), true);
                //self.getAnimalsForArea(result.geometry.location.lat(), result.geometry.location.lng(), true);
                $('#gameJournal_LocationText_hidden').val(result.formatted_address).trigger('change');
                //inp.value = this.getElementsByTagName("input")[0].value;
            }
            /*execute a function when someone writes in the text field:*/
            inp.addEventListener("input", function (e) {
                inputChanged(this);
            });
            /*execute a function when someone writes in the text field:*/
            var dropdownPlacesInput = document.getElementById("gameJournal_LocationText");
            dropdownPlacesInput.addEventListener("click", function () {
                $('#gameJournal_LocationText_hidden').val("").trigger('change');
                $('#gameJournal_LocationText').val("").trigger('change');
            });

            /*execute a function presses a key on the keyboard:*/
            inp.addEventListener("keydown", function (e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) { x = x.getElementsByTagName("div"); }
                if (e.keyCode == 40) {
                    /*If the arrow DOWN key is pressed,
                    increase the currentFocus variable:*/
                    currentFocus++;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 38) { //up
                    /*If the arrow UP key is pressed,
                    decrease the currentFocus variable:*/
                    currentFocus--;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 13) {
                    /*If the ENTER key is pressed, prevent the form from being submitted,*/
                    e.preventDefault();
                    if (currentFocus > -1) {
                        /*and simulate a click on the "active" item:*/
                        if (x) { x[currentFocus].click(); }
                    }
                }
            });
            function addActive(x) {
                /*a function to classify an item as "active":*/
                if (!x) { return false; }
                /*start by removing the "active" class on all items:*/
                removeActive(x);
                if (currentFocus >= x.length) { currentFocus = 0; }
                if (currentFocus < 0) { currentFocus = (x.length - 1); }
                /*add class "autocomplete-active":*/
                x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
                /*a function to remove the "active" class from all autocomplete items:*/
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }
            function closeAllLists(elmnt) {
                /*close all autocomplete lists in the document,
                except the one passed as an argument:*/
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }

            /*execute a function when someone clicks in the document:*/
            inp.addEventListener("click", function (e) {
                setTimeout(function () {
                    inputChanged(inp, e.target);
                });
            });

            document.addEventListener("click", function (e) {
                closeAllLists(e.target);
            });
        }

        this.autocompleteTimes = function (inp, arr) {
            /*the autocomplete function takes two arguments,
            the text field element and an array of possible autocompleted values:*/
            var currentFocus;

            function inputChanged(element) {
                var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
                //if (val.length == 0) {
                //    $('#dropdownCounties_value').val("").trigger('change');
                //}
                /*close any already open lists of autocompleted values*/
                closeAllLists();
                //if (!val) { return false; }
                currentFocus = -1;
                /*create a DIV element that will contain the items (values):*/
                a = document.createElement("DIV");
                a.setAttribute("id", element.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                /*append the DIV element as a child of the autocomplete container:*/
                element.parentNode.appendChild(a);
                /*for each item in the array...*/
                for (i = 0; i < arr.length; i++) {
                    var s = arr[i];
                    if (val.length == 0 || s.toUpperCase().indexOf(val.toUpperCase()) != -1) {
                        /*create a DIV element for each matching element:*/
                        b = document.createElement("DIV");
                        /*make the matching letters bold:*/
                        b.innerHTML += arr[i];
                        /*insert a input field that will hold the current array item's value:*/
                        b.innerHTML += "<input type='hidden' value='" + arr[i] + "' data-id='" + arr[i] + "'>"; //arr[i].County
                        //$('#dropdownCounties_value').trigger('change');
                        /*execute a function when someone clicks on the item value (DIV element):*/
                        //////////////////////////////////////////////////////////////////////////////
                        b.addEventListener("click", function (e) {
                            /*insert the value for the autocomplete text field:*/
                            var obj = $(e.srcElement);
                            var inputField = obj.find("input");
                            var fieldId = inputField.data("id");
                            $('#dropdownTimes_value').val(fieldId).trigger('change');
                            inp.value = this.getElementsByTagName("input")[0].value;

                            /*close the list of autocompleted values,
                            (or any other open lists of autocompleted values:*/
                            closeAllLists();

                        });
                        a.appendChild(b);
                    }
                }
            }

            /*execute a function when someone writes in the text field:*/
            inp.addEventListener("input", function (e) {
                inputChanged(this);
            });

            var dropdownTimesInput = document.getElementById("dropdownTimes");
            dropdownTimesInput.addEventListener("click", function () {
                $('#dropdownTimes_value').val("").trigger('change');
                $('#dropdownTimes').val("").trigger('change');
            });

            /*execute a function presses a key on the keyboard:*/
            inp.addEventListener("keydown", function (e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) { x = x.getElementsByTagName("div"); }
                if (e.keyCode == 40) {
                    /*If the arrow DOWN key is pressed,
                    increase the currentFocus variable:*/
                    currentFocus++;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 38) { //up
                    /*If the arrow UP key is pressed,
                    decrease the currentFocus variable:*/
                    currentFocus--;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 13) {
                    /*If the ENTER key is pressed, prevent the form from being submitted,*/
                    e.preventDefault();
                    if (currentFocus > -1) {
                        /*and simulate a click on the "active" item:*/
                        if (x) { x[currentFocus].click(); }
                    }
                }
            });
            function addActive(x) {
                /*a function to classify an item as "active":*/
                if (!x) { return false; }
                /*start by removing the "active" class on all items:*/
                removeActive(x);
                if (currentFocus >= x.length) { currentFocus = 0; }
                if (currentFocus < 0) { currentFocus = (x.length - 1); }
                /*add class "autocomplete-active":*/
                x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
                /*a function to remove the "active" class from all autocomplete items:*/
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }
            function closeAllLists(elmnt) {
                /*close all autocomplete lists in the document,
                except the one passed as an argument:*/
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }
            /*execute a function when someone clicks in the document:*/
            inp.addEventListener("click", function (e) {
                setTimeout(function () {
                    inputChanged(inp, e.target);
                });
            });

            document.addEventListener("click", function (e) {
                closeAllLists(e.target);
            });
        }
    };

    this.initMaps = function () {
        //console.log("initMaps was called by me!", $('#gameJournalForm'));
        if ($('#gameJournalForm').length && $(".game-journal-map-container").length) {
            setTimeout(function () {
                djf.gameJournalGoogleMaps.initGameJournalMap.getLocation();
            }, 500);
        }
    };

    this.ChangedCountry = function (element) {
        var $element = $(element);
        //console.log("$element", $element);
        //console.log("$element.val()", $element.val());
        $.get("/umbraco/api/GameJournalApi/GetCountryByID?ID=" + $element.val(), function (res) {
            console.log("res", res);
            var iso = res.ISO_3166_1_alpha_2;
            if (iso) {
                djf.gameJournalGoogleMaps.countryRestriction = iso;
            }
        })
    }
};