var djf = djf || {};
djf.googleMaps = djf.googleMaps || {};

$(document).ready(function () {
    djf.googleMaps.initMaps();
});


$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

djf.googleMaps = new function () {

    this.initHuntingTimesMap = new function () {
        var self = this;
        this.activeMap = {};

        this.defaultCenter = {
            lat: 56.19442,
            lng: 10.6821
        };
        this.defaultZoom = 7;
        this.times = [];

        this.getLocation = function () {
            if (!window.getUrlParameter) {
                window.getUrlParameter = function getUrlParameter(sParam) {
                    var sPageURL = window.location.search.substring(1),
                        sURLVariables = sPageURL.split('&'),
                        sParameterName,
                        i;

                    for (i = 0; i < sURLVariables.length; i++) {
                        sParameterName = sURLVariables[i].split('=');

                        if (sParameterName[0] === sParam) {
                            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                        }
                    }
                };
            }
            var lng = getUrlParameter('longitude');
            var lat = getUrlParameter('latitude');
            console.log("getLocation was called", lng && lat);
            if (lng && lat) {
                var positionobj = {
                    coords: {
                        latitude: lat,
                        longitude: lng
                    }
                }
                djf.googleMaps.initHuntingTimesMap.init(positionobj);
            } else {
                console.log("navigator.geolocation", navigator.geolocation);
                if (navigator.geolocation) {
                    var positionobj = {
                        coords: {
                            latitude: 56.281454,
                            longitude: 10.4859503
                        }
                    }
                    navigator.geolocation.getCurrentPosition(djf.googleMaps.initHuntingTimesMap.init, djf.googleMaps.initHuntingTimesMap.init(positionobj));
                } else {
                    var positionobj = {
                        coords: {
                            latitude: 56.281454,
                            longitude: 10.4859503
                        }
                    }
                    djf.googleMaps.initHuntingTimesMap.init(positionobj);
                }
            }
        }

        this.init = function (position) {
            console.log("init was called");
            var latLng = null;
            if (position) {
                latLng = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                var geocoder = new google.maps.Geocoder;
                geocoder.geocode({ 'location': latLng }, function (results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            $('#city-input').val(results[0].formatted_address);
                            $('#city-input_value').val(results[0].formatted_address).trigger('change');
                        }
                    } else {
                        $('#city-input').val('');
                        $('#city-input_value').val('').trigger('change');
                    }
                });
            } else {
                latLng = self.defaultCenter;
            }
            var containers = $('.hunting-times-map-container');
            for (var i = 0; i < containers.length; i++) {
                var mapID = containers[i].id;
                var map = null;
                map = new google.maps.Map(document.getElementById(mapID), {
                    center: latLng,
                    zoom: self.defaultZoom
                });
                self.activeMap = {
                    map: map,
                    id: mapID,
                    marker: null
                };
            }

            var current = self.activeMap;
            current.map.addListener('click', function (event) {
                geocoder.geocode({ 'location': event.latLng }, function (results, status) {
                    if (status === 'OK') {

                        var locationToShow = "";
                        for (var i = 0; i < results.length; i++) {
                            if (results[i].types.indexOf("country") == -1) {
                                locationToShow = results[i].formatted_address;
                                break;
                            }
                        }
                        if (locationToShow.length > 0 && locationToShow.toLowerCase().indexOf("unnamed road") == -1) {
                            $('#city-input').val(locationToShow);
                            $('#city-input_value').val(locationToShow).trigger('change');
                        } else {
                            $('#city-input').val('Ukendt adresse');
                            $('#city-input_value').val('').trigger('change');
                        }
                    } else {
                        $('#city-input').val('Ukendt adresse');
                        $('#city-input_value').val('').trigger('change');
                    }
                });
                //self.getAnimalsForArea(event.latLng.lat(), event.latLng.lng());
                setNewMarker(event.latLng.lat(), event.latLng.lng());
            });
            $('#date-input_value').change(function (a, b) {
                if (self.activeMap && self.activeMap.marker) {
                    //self.getAnimalsForArea(self.activeMap.marker.position.lat(), self.activeMap.marker.position.lng());
                    setNewMarker(self.activeMap.marker.position.lat(), self.activeMap.marker.position.lng());
                } else {
                    //self.getAnimalsForArea(latLng.lat, latLng.lng);
                    setNewMarker(latLng.lat, latLng.lng);
                }
            });
            var local = new Date();
            //local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
            //local = local.toJSON().slice(0, 10);
            var formatted_date = local.getDate() + "-" + ('0' + (local.getMonth() + 1)).slice(-2) + "-" + local.getFullYear();
            console.log(formatted_date);
            $('#date-input_value').val(formatted_date);

            self.setupAutoCompletes();
            setNewMarker(latLng.lat, latLng.lng);
            //self.getAnimalsForArea(latLng.lat, latLng.lng);
            $('#refresh-hunting-times-trigger').click(function () {
                if (self.activeMap && self.activeMap.marker) {
                    setNewMarker(self.activeMap.marker.position.lat(), self.activeMap.marker.position.lng());
                    self.getAnimalsForArea(self.activeMap.marker.position.lat(), self.activeMap.marker.position.lng());
                } else {
                    setNewMarker(latLng.lat, latLng.lng);
                    self.getAnimalsForArea(latLng.lat, latLng.lng);
                }
            });

        };

        this.getAnimalsForArea = function (lat, lng) {
            var dateValue = $('#date-input_value').val();
            var dateValues = dateValue.split('-');
            var year = dateValues[2];
            var month = dateValues[1];
            var day = dateValues[0];
            $.get('/umbraco/api/HuntingTimes/GetListOfSpeciesFromLocation?lat=' + lat + '&lng=' + lng + '&date=' + day + '/' + month + '/' + year + '&token=e518c66c-e835-4318-8c64-828fbb399361&isWeb=true', function (response) {
                var $resultContainer = $("#hunting-times-result-list");
                var top = $(".hunting-times-container").offset().top - 300;

                $resultContainer.html("");
                if (!response || response.length == 0) {
                    var result = document.createElement("DIV");
                    var itemClass = 'result-item';
                    result.setAttribute('class', itemClass);
                    var text = "<p><b>Der er ikke nogle jagttider p&aring; det valgte sted og p&aring; det valgte tidspunkt.</b></p>";
                    result.innerHTML = text;
                    $resultContainer[0].appendChild(result);
                } else {
                    for (var i = 0; i < response.length; i++) {
                        var item = response[i];
                        self.setUpAccordion(item, $resultContainer);
                    }
                    djf.accordion.reEnit();
                }
                if ($(window).width() > 992) {
                    $('html, body').animate({
                        scrollTop: top
                    }, 0);
                }
            });
        }

        this.setUpAccordion = function (item, $resultContainer) {
            var accordionModule = document.createElement("SECTION");
            accordionModule.setAttribute('class', 'accordion-module');
            var startUnfolded = item.StartUnfolded == true;
            var accordionUl = document.createElement("UL");
            accordionUl.setAttribute('class', 'accordion');

            var accordionLi = document.createElement("LI");
            accordionLi.setAttribute('class', 'accordion-item multi-open');

            var accordionH3 = document.createElement("H3");
            accordionH3.setAttribute('class', 'accordion-header ' + (startUnfolded ? "-start-folded-out" : ""));
            accordionH3.innerHTML = item.Name;
            var accordionH3Span = document.createElement("SPAN");
            accordionH3Span.setAttribute('class', 'icon-circle red-theme');
            accordionH3Span.innerHTML = '<svg class="arrow-down"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/icons/icons.svg#arrow-circle"></use></svg>';

            accordionH3.appendChild(accordionH3Span);
            accordionLi.appendChild(accordionH3);

            var accordionRTEDiv = document.createElement("DIV");
            accordionRTEDiv.setAttribute('class', 'accordion-content rte-content');
            accordionRTEDiv.setAttribute('style', 'display: none;');
            var from = new Date(item.FromDateTime);
            var date = new Date(item.ToDateTime);
            var userTimezoneOffset = date.getTimezoneOffset() * -60000;
            var to = new Date(date.getTime() - userTimezoneOffset);

            if (item.Description) {
                item.Description = item.Description.replace(/(?:\r\n|\r|\n)/g, '<br/>');
                accordionRTEDiv.innerHTML += '<div class="font-bold">V&aelig;r opm&aelig;rksom p&aring:</div>';
                accordionRTEDiv.innerHTML += '<p>' + item.Description + '</p>';
            }
            if (item.ExternalLink) {
                accordionRTEDiv.innerHTML += '<p><a href="' + item.ExternalLink + '" target="_blank">Link til artsleksikon</a></p>';
            }

            accordionRTEDiv.innerHTML += '<div class="font-bold">Periode:</div>';
            accordionRTEDiv.innerHTML += '<p>' + (from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear()) + " - " + (to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear()) + '</p>';

            accordionLi.appendChild(accordionRTEDiv);
            accordionUl.appendChild(accordionLi);
            accordionModule.appendChild(accordionUl);
            $resultContainer[0].appendChild(accordionModule);
        }

        this.setupAutoCompletes = function () {
            autocompletePlace(document.getElementById("city-input"));
        }

        function setNewMarker(lat, lng, panToMarker) {
            if (self.activeMap.marker) {
                self.activeMap.marker.setMap(null);
            }
            self.activeMap.marker = new google.maps.Marker({
                position: { lng: lng, lat: lat },
                map: self.activeMap.map
            });
            if (panToMarker) {
                panTo(lat, lng);
            }
        }

        var panPath = [];   // An array of points the current panning action will use
        var panQueue = [];  // An array of subsequent panTo actions to take
        var STEPS = 50;     // The number of steps that each panTo action will undergo

        function panTo(newLat, newLng) {
            if (panPath.length > 0) {
                // We are already panning...queue this up for next move
                panQueue.push([newLat, newLng]);
            } else {
                // Lets compute the points we'll use
                panPath.push("LAZY SYNCRONIZED LOCK");  // make length non-zero - 'release' this before calling setTimeout
                var curLat = self.activeMap.map.getCenter().lat();
                var curLng = self.activeMap.map.getCenter().lng();
                var dLat = (newLat - curLat) / STEPS;
                var dLng = (newLng - curLng) / STEPS;

                for (var i = 0; i < STEPS; i++) {
                    panPath.push([curLat + dLat * i, curLng + dLng * i]);
                }
                panPath.push([newLat, newLng]);
                panPath.shift();      // LAZY SYNCRONIZED LOCK
                setTimeout(doPan, 5);
            }
        }

        function doPan() {
            var next = panPath.shift();
            if (next != null) {
                // Continue our current pan action
                self.activeMap.map.panTo(new google.maps.LatLng(next[0], next[1]));
                setTimeout(doPan, 5);
            } else {
                // We are finished with this pan - check if there are any queue'd up locations to pan to 
                var queued = panQueue.shift();
                if (queued != null) {
                    panTo(queued[0], queued[1]);
                }
            }
        }

        function autocompletePlace(inp) {

            /*the autocomplete function takes two arguments,
            the text field element and an array of possible autocompleted values:*/
            var currentFocus;

            function inputChanged(element) {
                var val = element.value;
                if (val.length == 0) {
                    inp.placeholder = "";
                    closeAllLists();
                    return;
                }//+ val

                //create service
                service = new google.maps.places.AutocompleteService();

                //perform request. limit results to Australia
                var request = {
                    input: val,
                    componentRestrictions: { country: 'dk' },
                    types: ['geocode']
                };
                service.getPlacePredictions(request, displaySuggestions);

            }

            var displaySuggestions = function (predictions, status) {
                var element = document.getElementById("city-input");
                var arr = [];
                var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
                var url = "";
                arr = predictions;
                /*close any already open lists of autocompleted values*/
                closeAllLists();
                //if (!val) { return false; }
                currentFocus = -1;
                /*create a DIV element that will contain the items (values):*/
                a = document.createElement("DIV");
                a.setAttribute("id", element.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                /*append the DIV element as a child of the autocomplete container:*/
                element.parentNode.appendChild(a);
                /*for each item in the array...*/
                for (i = 0; i < arr.length; i++) {
                    var s = escapeSearchInput(arr[i].description).toUpperCase();
                    if (s.indexOf(val) != -1) {
                        /*create a DIV element for each matching element:*/
                        b = document.createElement("DIV");
                        /*make the matching letters bold:*/
                        b.innerHTML += arr[i].description;

                        if (arr[i].sted && arr[i].sted.kommuner.length > 0) {
                            b.innerHTML += ' (' + arr[i].sted.kommuner[0].description + ' Kommune)';
                        }
                        //                /*insert a input field that will hold the current array item's value:*/
                        b.innerHTML += "<input type='hidden' value='" + arr[i].description + "' data-name='" + arr[i].description + "'>";
                        //$('#dropdownCounties_value').trigger('change');
                        /*execute a function when someone clicks on the item value (DIV element):*/
                        //////////////////////////////////////////////////////////////////////////////
                        b.addEventListener("click", function (e) {
                            /*insert the value for the autocomplete text field:*/
                            var obj = $(e.srcElement);
                            var inputField = obj.find("input");
                            var name = inputField.data("name");
                            var request = {
                                query: name,
                                locationBias: { north: 57.818172, south: 54.265083, east: 15.915587, west: 5.000653 },
                                fields: ['formatted_address', 'geometry']
                            };
                            service = new google.maps.places.PlacesService(self.activeMap.map);
                            service.findPlaceFromQuery(request, focusLocation);
                            $('#city-input').val(name);
                            $('#city-input_value').val(name).trigger('change');
                            /*close the list of autocompleted values,
                            (or any other open lists of autocompleted values:*/
                            closeAllLists();

                        });
                        a.appendChild(b);

                    }
                }
                var aAsJquery = $(a);
                a.setAttribute("style", "width:" + (aAsJquery.width() + 1) + "px;");
            };
            var focusLocation = function (results, status) {
                var result = results[0];
                setNewMarker(result.geometry.location.lat(), result.geometry.location.lng(), true);
                //self.getAnimalsForArea(result.geometry.location.lat(), result.geometry.location.lng(), true);
                $('#city-input_value').val(result.formatted_address).trigger('change');
                //inp.value = this.getElementsByTagName("input")[0].value;
            }
            /*execute a function when someone writes in the text field:*/
            inp.addEventListener("input", function (e) {
                inputChanged(this);
            });
            /*execute a function when someone writes in the text field:*/
            var dropdownPlacesInput = document.getElementById("city-input");
            dropdownPlacesInput.addEventListener("click", function () {
                $('#city-input_value').val("").trigger('change');
                $('#city-input').val("").trigger('change');
            });

            /*execute a function presses a key on the keyboard:*/
            inp.addEventListener("keydown", function (e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) { x = x.getElementsByTagName("div"); }
                if (e.keyCode == 40) {
                    /*If the arrow DOWN key is pressed,
                    increase the currentFocus variable:*/
                    currentFocus++;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 38) { //up
                    /*If the arrow UP key is pressed,
                    decrease the currentFocus variable:*/
                    currentFocus--;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 13) {
                    /*If the ENTER key is pressed, prevent the form from being submitted,*/
                    e.preventDefault();
                    if (currentFocus > -1) {
                        /*and simulate a click on the "active" item:*/
                        if (x) { x[currentFocus].click(); }
                    }
                }
            });
            function addActive(x) {
                /*a function to classify an item as "active":*/
                if (!x) { return false; }
                /*start by removing the "active" class on all items:*/
                removeActive(x);
                if (currentFocus >= x.length) { currentFocus = 0; }
                if (currentFocus < 0) { currentFocus = (x.length - 1); }
                /*add class "autocomplete-active":*/
                x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
                /*a function to remove the "active" class from all autocomplete items:*/
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }
            function closeAllLists(elmnt) {
                /*close all autocomplete lists in the document,
                except the one passed as an argument:*/
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }

            /*execute a function when someone clicks in the document:*/
            inp.addEventListener("click", function (e) {
                setTimeout(function () {
                    inputChanged(inp, e.target);
                });
            });

            document.addEventListener("click", function (e) {
                closeAllLists(e.target);
            });
        }

        this.autocompleteTimes = function (inp, arr) {
            /*the autocomplete function takes two arguments,
            the text field element and an array of possible autocompleted values:*/
            var currentFocus;

            function inputChanged(element) {
                var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
                //if (val.length == 0) {
                //    $('#dropdownCounties_value').val("").trigger('change');
                //}
                /*close any already open lists of autocompleted values*/
                closeAllLists();
                //if (!val) { return false; }
                currentFocus = -1;
                /*create a DIV element that will contain the items (values):*/
                a = document.createElement("DIV");
                a.setAttribute("id", element.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                /*append the DIV element as a child of the autocomplete container:*/
                element.parentNode.appendChild(a);
                /*for each item in the array...*/
                for (i = 0; i < arr.length; i++) {
                    var s = arr[i];
                    if (val.length == 0 || s.toUpperCase().indexOf(val.toUpperCase()) != -1) {
                        /*create a DIV element for each matching element:*/
                        b = document.createElement("DIV");
                        /*make the matching letters bold:*/
                        b.innerHTML += arr[i];
                        /*insert a input field that will hold the current array item's value:*/
                        b.innerHTML += "<input type='hidden' value='" + arr[i] + "' data-id='" + arr[i] + "'>"; //arr[i].County
                        //$('#dropdownCounties_value').trigger('change');
                        /*execute a function when someone clicks on the item value (DIV element):*/
                        //////////////////////////////////////////////////////////////////////////////
                        b.addEventListener("click", function (e) {
                            /*insert the value for the autocomplete text field:*/
                            var obj = $(e.srcElement);
                            var inputField = obj.find("input");
                            var fieldId = inputField.data("id");
                            $('#dropdownTimes_value').val(fieldId).trigger('change');
                            inp.value = this.getElementsByTagName("input")[0].value;

                            /*close the list of autocompleted values,
                            (or any other open lists of autocompleted values:*/
                            closeAllLists();

                        });
                        a.appendChild(b);
                    }
                }
            }

            /*execute a function when someone writes in the text field:*/
            inp.addEventListener("input", function (e) {
                inputChanged(this);
            });

            var dropdownTimesInput = document.getElementById("dropdownTimes");
            dropdownTimesInput.addEventListener("click", function () {
                $('#dropdownTimes_value').val("").trigger('change');
                $('#dropdownTimes').val("").trigger('change');
            });

            /*execute a function presses a key on the keyboard:*/
            inp.addEventListener("keydown", function (e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) { x = x.getElementsByTagName("div"); }
                if (e.keyCode == 40) {
                    /*If the arrow DOWN key is pressed,
                    increase the currentFocus variable:*/
                    currentFocus++;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 38) { //up
                    /*If the arrow UP key is pressed,
                    decrease the currentFocus variable:*/
                    currentFocus--;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 13) {
                    /*If the ENTER key is pressed, prevent the form from being submitted,*/
                    e.preventDefault();
                    if (currentFocus > -1) {
                        /*and simulate a click on the "active" item:*/
                        if (x) { x[currentFocus].click(); }
                    }
                }
            });
            function addActive(x) {
                /*a function to classify an item as "active":*/
                if (!x) { return false; }
                /*start by removing the "active" class on all items:*/
                removeActive(x);
                if (currentFocus >= x.length) { currentFocus = 0; }
                if (currentFocus < 0) { currentFocus = (x.length - 1); }
                /*add class "autocomplete-active":*/
                x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
                /*a function to remove the "active" class from all autocomplete items:*/
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }
            function closeAllLists(elmnt) {
                /*close all autocomplete lists in the document,
                except the one passed as an argument:*/
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }
            /*execute a function when someone clicks in the document:*/
            inp.addEventListener("click", function (e) {
                setTimeout(function () {
                    inputChanged(inp, e.target);
                });
            });

            document.addEventListener("click", function (e) {
                closeAllLists(e.target);
            });
        }
    };

    this.initMaps = function () {
        //console.log("initMaps was called", $('.hunting-times-container'));
        if ($('.hunting-times-container').length) {
            djf.googleMaps.initHuntingTimesMap.getLocation();
            $('#page .page-sidebar+.page-content .djf-9 .rte-content').addClass('remove-padding');
        }
    };
};

