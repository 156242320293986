var djf = djf || {};

djf.navigation = new function () {
    var self = this,
        menu = $('#nav-container'),
        offCanvas = $('#offcanvas-nav'),
        menuList = $('#navigation-list'),
        menuUl = $('#navigation-list > li'),
        menuUlWithChild = $('#navigation-list > li.has-child'),
        menuTrigger = $('#offcanvas-trigger'),
        menuRightTrigger = $('#md-searchbar-menu'),
        closeMenuTrigger = $('.close-overlay, #close-nav'),
        subnavTrigger = $('.subnav-trigger'),
        navTriggerText = $('#nav-trigger-text'),
        navTriggerIcon = $('#nav-trigger-burger'),
        activeItem = $('');


    this.init = function () {

        // Bind the menu-button on init.
        self.triggerMenu(menuTrigger);
        self.triggerRightMenu(menuRightTrigger);
        self.triggerCloseMenu(closeMenuTrigger);
        // Bind the submenu triggers on init.
        self.openSubMenu(subnavTrigger);

        // Set min data-height
        self.SetDataHeight(menuUlWithChild);

        // Sticky menu;
        self.stickyMenu(menu);

        // hoverIntentInit  
        self.hoverIntent(menuUlWithChild);

        // In Resize event
        $(window).smartresize(function () {

            self.moveTheTopNav();

            // Apply minheight css
            if ($(window).width() > 1024) {
                // Bind HoverIntent actions
                self.hoverIntent(menuUlWithChild);
                // Remove menu-open class
                $('html').removeClass('menu-open-left');
                $('html').removeClass('menu-open-right');
            }
        }).trigger('resize');


        // Check for active class
        if (activeItem.length > 0) {
            /*activeItem.parents('li').addClass('opened').find('ul').show();*/
            activeItem.parents('li').addClass('opened');
        }
    };

    /*    this.getAjaxMenu = function (t) {
            var contentUrl = menu.attr('data-url');
    
            var jqxhr = $.get(contentUrl, function (data) {
                $('#main-menu').html(data);
    
            });
    
            jqxhr.done(function() {
                self.init();
    
                setTimeout(function() {
                    self.hoverIntent(menuUl);
                }, 100);
            })
    
        };*/

    this.triggerMenu = function (t) {
        t.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var siteHeight = $('#site').attr('data-height');
            // Toggle body class

            $('html').addClass('menu-open-left');
            if (!$('html').hasClass('menu-open-left')) {
                $('#site').css('min-height', siteHeight + "px");
            };
        })
    };

    this.triggerRightMenu = function (t) {
        t.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var siteHeight = $('#site').attr('data-height');
            // Toggle body class

            $('html').addClass('menu-open-right');
            if (!$('html').hasClass('menu-open-right')) {
                $('#site').css('min-height', siteHeight + "px");
            };
        })
    };

    this.triggerCloseMenu = function (t) {
        t.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            console.log("close");
            $('html').removeClass('menu-open-right');
            $('html').removeClass('menu-open-left');
            $('#site').css('min-height', "auto");
        });
    };

    this.SetMaxHeight = function (selector) {

        var activeSubs = selector.find('ul.slideToggle'),
            subUl = selector.find('ul').first(),
            minHeight = 0,
            ulHeights = new Array();


        activeSubs.each(function () {
            var height = $(this).attr('data-height');
            ulHeights.push(height);
        });

        // Get heights value of array
        minHeight = Math.max.apply(Math, ulHeights);


        // Apply minheight css
        if ($(window).width() > 1024 && minHeight > 0) {
            subUl.attr('style', 'min-height:' + minHeight + 'px;');
        } else {
            subUl.attr('style', 'min-height:0');
        }
    };

    this.openSubMenu = function (t) {
        t.click(function (e) {
            e.preventDefault();
            e.stopPropagation();


            var $this = $(this),
                $openItem = $('.opened'),
                $topLevel = $this.parents('#navigation-list > li');
            $parentLi = $this.parents('li'),
                $parentOpenItem = $parentLi.filter('.opened');


            $openItem.not($parentOpenItem).removeClass('opened').find('ul').removeClass('slideToggle');
            $this.parent().next().toggleClass('slideToggle');
            $this.closest('li').toggleClass('opened');

            // Set max-height
            self.SetMaxHeight($topLevel);
        });

        // Add opened class to active
        var $menu = $('#sidebar-menu');
        $menu.find('.active').parents('li').addClass('opened').children('ul').addClass('slideToggle');
    };

    this.SetDataHeight = function (t) {
        t.each(function () {
            var subUl = $(this).find('ul');

            // Fake show the menu to measure height
            $(this).addClass('hover');
            subUl.addClass('slideToggle');

            subUl.each(function () {
                var height = $(this).height() + 40;
                $(this).attr('data-height', height);
            });

            // Set min-height for off-canvas
            $('#site').attr('data-height', menuList.height());

            // Remove the fake show of menu
            $(this).removeClass('hover');
            subUl.removeClass('slideToggle');

        });
    }
    this.moveTheTopNav = function () {
        var $listItems = $('#top-nav li'),
            $movedItems = $('.navigation-list li.has-moved');

        if ($(window).width() < 1200) {
            $listItems.addClass('has-moved');
            if ($(window).width() < 1023) {
                $listItems.appendTo('#offcanvas-nav .navigation-list');
            } 
            else {
                $listItems.appendTo('#offcanvas-nav-right .navigation-list');
            }
        } else {
            $movedItems.appendTo('#top-nav ul');
        }

    };

    this.activeMenu = null;
    this.hoverIntent = function (t) {

        $(window).unbind().click(function () {
            if (self.activeMenu) {
                self.hideMenu(self.activeMenu);
            }
        });

        $(menuList).click(function (event) {
            event.stopPropagation();
        });

        $(t).children("a").unbind().click(function (e) {
            var current = $($(this)[0]).parent();
            var hasClass = current.hasClass("hover");
            if (!hasClass) {
                current.addClass('hover');
                $('body').addClass('menu-visible');

                if (self.activeMenu != null) {
                    self.activeMenu.removeClass('hover');
                }

                self.activeMenu = current;
            } else {
                self.hideMenu(current);
            }

            if ($("#navigation-list > .hover").length == 0) {
                $('body').removeClass('menu-visible');
            }
        });
    };

    this.hideMenu = function (current) {
        self.activeMenu = null;
        current.removeClass('hover');
        $('body').removeClass('menu-visible');

        if ($("#navigation-list > .hover").length == 0) {
            $('body').removeClass('menu-visible');
        }
    }

    this.stickyMenu = function (selector) {
        var pxToTop = $('#top-container').outerHeight();

        $(document).scroll(function () {
            if ($(this).scrollTop() >= pxToTop) {
                $('body').addClass('fixed-menu');
            } else {
                $('body').removeClass('fixed-menu');
            }
        });
    }

};

$(function () {
    djf.navigation.init();
});