$(function () {

    //Helpers
    function convertBool(bool) {
        return bool ? "Ja" : "Nej";
    }

    function resetAfterError(containerSelector) {
        var container = $(containerSelector);
        container.find('.error-container').css('display', 'none');
        container.find('.custom-error-container').css('display', 'none');
        container.find('.hide-on-error').css('display', 'block');
    }

    function handleError(containerSelector, response) {
        var container = $(containerSelector);

        if (response.status === 400) {
            var parentContainer = container.find('.custom-error-container');
            parentContainer.css('display', 'block');
            parentContainer.find('div').html(response.responseJSON.Message);
        } else {
            container.find('.error-container').css('display', 'block');
        }

        container.find('.hide-on-error').css('display', 'none');
    }

    function showIfBoolIsTrue(element, boolean) {
        element.css('display', boolean ? 'block' : 'none');
    }

    //DelegateRegistration
    var delegatedMacro = $('.delegated-registration, .delegated-approvement');
    var delegatedMacroWasApprovement = null;

    if (delegatedMacro != null) {
        if (delegatedMacro.hasClass("delegated-approvement")) {
            delegatedMacroWasApprovement = true;
        } else {
            delegatedMacroWasApprovement = false;
        }
    }
    var searchBox = delegatedMacro.find('.search input');
    var searchBoxDelegated = delegatedMacro.find('.search-delegated input');
    var undelegatedList = delegatedMacro.find('.members-list-undelegated');
    var undelegatedListTemplate = undelegatedList.find('script[type="text/template"]');
    var delegatedList = delegatedMacro.find('.members-list-delegated');
    var delegatedListTemplate = delegatedList.find('script[type="text/template"]');
    var delegateCount = delegatedMacro.find('.delegated-count');
    var maxDelegatedCount = delegatedMacro.find('.max-delegated');
    var clubSelectorDelegates = delegatedMacro.find('.club-selector-delegates');
    var boardSelectorDelegates = delegatedMacro.find('.board-selector-delegates');


    clubSelectorDelegates.change(function () {
        delegateSearch();
    });

    boardSelectorDelegates.change(function () {
        delegateSearch();
        //delegatedMacro.find('.club-selector-delegates option:selected').each(function () {
        //    delegateSearch();
        //    var str = $(this).text() + " ";
        //    console.log("str", str);
        //    console.log("$(this)", $(this));
        //    console.log("$(this)", $(this).val());
        //});
    });

    var allMembers = null;
    var delegates = null;
    var manuallyAddedDelegates = {};
    var manuallyRemovedDelegates = [];
    var delegateCounter = 0;
    var delegateHuntingBoards = [];
    var delegateHuntingClubs = [];

    function updateUI() {
        undelegatedList.children('div').remove();
        delegatedList.children('div').remove();
        var searchTerm = null;
        try {
            searchTerm = searchBox.val();
        } catch (e) {
            console.error(e);
        }
        searchTerm = (searchTerm || '').toLowerCase();

        var membersNotDelegated = [],
            membersDelegated = delegates;


        var isLoading = allMembers === null;
        var isLoadingDelegates = delegates === null; //|| delegates.length === 0;
        delegatedMacro.find(".mem-loading").css('display', isLoading ? 'block' : 'none');

        delegatedMacro.find(".mem-delegated-loading").css('display', isLoadingDelegates ? 'block' : 'none');

        if (delegateHuntingBoards) {
            var currentVal = boardSelectorDelegates.val();
            boardSelectorDelegates.html('');

            var defaultO = new Option("Alle Jægerråd", '');
            $(defaultO).html('Alle Jægerråd');
            boardSelectorDelegates.append(defaultO);
            for (var c = 0; c < delegateHuntingBoards.length; c++) {
                var currentHuntingBoard = delegateHuntingBoards[c];
                var o = new Option(currentHuntingBoard, currentHuntingBoard.toLowerCase());
                $(o).html(currentHuntingBoard);
                boardSelectorDelegates.append(o);
            }

            if (currentVal) {
                boardSelectorDelegates.val(currentVal);
            } else {
                boardSelectorDelegates.val('');
            }
        }

        if (delegateHuntingClubs) {
            var currentVal = clubSelectorDelegates.val();
            clubSelectorDelegates.html('');

            var defaultO = new Option("Alle foreninger", '');
            $(defaultO).html('Alle foreninger');
            clubSelectorDelegates.append(defaultO);
            for (var c = 0; c < delegateHuntingClubs.length; c++) {
                var currentHuntingClub = delegateHuntingClubs[c];
                var o = new Option(currentHuntingClub, currentHuntingClub.toLowerCase());
                $(o).html(currentHuntingClub);
                clubSelectorDelegates.append(o);
            }

            if (currentVal) {
                clubSelectorDelegates.val(currentVal);
            } else {
                clubSelectorDelegates.val('');
            }
        }

        if (delegates) {
            for (var x = 0; x < delegates.length; x++) {
                const delegatedMember = delegates[x];
                addToList(delegatedMember, delegatedListTemplate, delegatedList, function (elem) {
                    var index = delegates.indexOf(delegatedMember);
                    delegates.splice(index, 1);
                    delegateCounter--;
                    if (manuallyAddedDelegates[delegatedMember.memberNo]) {
                        delete manuallyAddedDelegates[delegatedMember.memberNo];
                    } else {
                        manuallyRemovedDelegates.push(delegatedMember.memberNo);
                    }

                    var delegeateMemberNos = delegatedMacro.find('input[name="delegateMemberNos"]');
                    var memberString = delegeateMemberNos.val();
                    if (memberString.indexOf("," + delegatedMember.memberNo) != -1) {
                        memberString = memberString.replace("," + delegatedMember.memberNo, "");
                    } else {
                        memberString = memberString.replace(delegatedMember.memberNo, "");
                    }
                    console.log("memberString", memberString);

                    delegatedMacro.find('input[name="delegateMemberNos"]').val(memberString);
                    updateUI();
                });
            }
        }

        if (allMembers) {
            for (var i = 0; i < allMembers.length; i++) {
                const member = allMembers[i];
                membersNotDelegated.push(member);
                addToList(member, undelegatedListTemplate, undelegatedList, function (elem) {
                    var index = allMembers.indexOf(member);
                    allMembers.splice(index, 1);
                    membersDelegated.push(member);
                    delegateCounter++;
                    if (manuallyRemovedDelegates.indexOf(member.memberNo) != -1) { //If you add something thats been removed, just remove it from the list of removed items
                        manuallyRemovedDelegates.splice(manuallyRemovedDelegates.indexOf(member.memberNo), 1);
                    } else {
                        manuallyAddedDelegates[member.memberNo] = member;
                    }
                    console.log("member", member);
                    if (member.huntingClub && delegateHuntingClubs.indexOf(member.huntingClub.name) == -1) {
                        delegateHuntingClubs.push(member.huntingClub.name);
                    }

                    var delegeateMemberNos = delegatedMacro.find('input[name="delegateMemberNos"]');
                    var memberString = delegeateMemberNos.val();
                    memberString = memberString + "," + member.memberNo;
                    console.log("memberString", memberString);

                    delegatedMacro.find('input[name="delegateMemberNos"]').val(memberString);
                    updateUI();
                });
            }
        }

        if (membersDelegated && membersDelegated.length === 0) {
            var searchTermDelegated = searchBoxDelegated.val();
            if (searchTermDelegated && searchTermDelegated.length > 0) {
                delegatedList.append('<div class="mem"><small class="center-block">Din søgning gav ingen resultater</small></div>');
            } else {
                delegatedList.append('<div class="mem"><small class="center-block">Ingen er valgt</small></div>');
            }
        }
        if (membersNotDelegated.length === 0 && allMembers !== null) {
            if (!searchTerm || searchTerm.toString().length === 0) {
                undelegatedList.append('<div class="mem"><small class="center-block">Brug feltet herover til at søge medlemmer frem</small></div>');
            } else {
                undelegatedList.append('<div class="mem"><small class="center-block">Din søgning gav ingen resultater</small></div>');
            }
        }

        try {
            if (parseInt(delegateCounter.toString()) >= parseInt(maxDelegates)) {
                undelegatedList.find('.list-button').prop('disabled', true);
            }
        } catch (e) {
            console.error(e);
        }

        delegateCount.html(delegateCounter + '/' + maxDelegates);
    }

    function addToList(element, template, list, btnClickEvent) {
        var html = template.html();
        var dom = $(html);

        dom.find('.member-name').html(element.name);
        dom.find('.member-no').html(element.memberNo);
        dom.find('.member-pricegroup').html(element.priceGroup);
        if (delegatedMacroWasApprovement) {
            if (element.huntingClub) {
                dom.find('.member-club').html(element.huntingClub.name);
            }
            dom.find('.member-raad').html(element.huntingBoard);
        }
        const button = dom.find('.list-button');
        var registration = $('.delegated-registration');
        //if (element.isRestant) { restante medlemmer skal også kunne vælges som delegerede jf. mail fra Pia
        //    button.prop('disabled', true);
        //}
        if (element.isDelegated && registration.length) {
            button.prop('disabled', true);
        }
        if (btnClickEvent) {
            button.click(function () {
                btnClickEvent(element, button);
            });
        }

        dom.appendTo(list);
    }

    var maxDelegates = 0;
    var searchForm = $('.delegated-registration form.search, .delegated-approvement form.search');
    searchForm.submit(function (ev) {
        console.log("asddasd");
        ev.preventDefault();
        getMembers();
    });
    searchForm.on('reset', function () {
        resetDelegateRegistration();
    });

    var searchFormDelegated = $('.delegated-approvement form.search-delegated');
    searchFormDelegated.submit(function (ev) {
        console.log("asd");
        ev.preventDefault();
        delegateSearch();
    });

    var searchFormDelegatedRegistration = $('.delegated-registration .delegated-col form');
    searchFormDelegatedRegistration.submit(function (ev) {
        console.log("asd right form");
    });
    searchFormDelegated.on('reset', function () {
        resetDelegateSearch();
        clubSelectorDelegates.val('');
        boardSelectorDelegates.val('');
    });

    function resetDelegateRegistration() {
        searchBox.val('');
        allMembers = null;
        //delegates = null;
        getMembers();
    }

    function resetDelegateSearch() {
        searchBoxDelegated.val('');
        delegateSearch(true);
    }

    function removeAutomaticallyAddedDelegates() {
        for (var i = 0; i < delegates.length; i++) {
            if (!manuallyAddedDelegates[delegates[i].memberNo]) {
                delegates.splice(i, 1);
            }
        }
    }

    function delegateSearch(shouldDoBlankSearch) {
        removeAutomaticallyAddedDelegates();
        var searchTerm = searchBoxDelegated.val();
        var encodedSearchTerm = '';
        encodedSearchTerm = encodeURI(searchTerm);
        var eventSelector = delegatedMacro.find('.event-selector');
        var clubSelector = delegatedMacro.find('.club-selector');

        var eventSelectorValue = eventSelector.val();
        var clubId = clubSelector.val();

        delegates = null;
        updateUI();
        var clubSelectorDelegate = "";
        delegatedMacro.find('.club-selector-delegates option:selected').each(function () {
            clubSelectorDelegate = $(this).val();
        });
        var encodedClub = encodeURI(clubSelectorDelegate);;

        var boardSelectorDelegate = "";
        delegatedMacro.find('.board-selector-delegates option:selected').each(function () {
            boardSelectorDelegate = $(this).val();
        });
        var encodedBoard = encodeURI(boardSelectorDelegate);

        $.get('/umbraco/api/DelegatedApi/GetDelegatedMembersOnly?club=' + clubId + '&event=' + eventSelectorValue + (shouldDoBlankSearch ? '' : '&term=' + encodedSearchTerm + (encodedClub ? '&clubFilter=' + encodedClub : '') + (encodedBoard ? '&boardFilter=' + encodedBoard : ''))).then(function (resp) {
            var tmpList = [];
            for (var i = 0; i < resp.delegates.length; i++) {
                if (resp.delegates[i] && manuallyRemovedDelegates.indexOf(resp.delegates[i].memberNo) == -1) {
                    tmpList.push(resp.delegates[i]);
                }
            }

            for (var key in manuallyAddedDelegates) {
                var currentManuallyAddedDelegate = manuallyAddedDelegates[key];
                if (
                    manuallyRemovedDelegates.indexOf(currentManuallyAddedDelegate.memberNo) == -1 &&
                    (
                        (
                            (!searchTerm || searchTerm.length == 0) ||
                            (currentManuallyAddedDelegate.name.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) ||
                            (currentManuallyAddedDelegate.memberNo.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1)
                        ) ||
                        (
                            (!clubSelectorDelegate) ||
                            (currentManuallyAddedDelegate.huntingBoard.toLowerCase() == clubSelectorDelegate.toLowerCase()) ||
                            (currentManuallyAddedDelegate.huntingClub && (currentManuallyAddedDelegate.huntingClub.name.toLowerCase() == clubSelectorDelegate.toLowerCase()))
                        )
                    )
                ) {
                    tmpList.push(currentManuallyAddedDelegate);
                }
            }
            delegates = tmpList;
            updateUI();
        });
    }

    function getMembers() {
        var eventSelector = delegatedMacro.find('.event-selector');
        var clubSelector = delegatedMacro.find('.club-selector');
        var eventSelectorValue = eventSelector.val();
        var clubId = clubSelector.val();

        delegatedMacro.find('input[name="eventId"]').val(eventSelectorValue);
        delegatedMacro.find('input[name="huntingClubId"]').val(clubId);

        allMembers = null;
        maxDelegates = 0;
        resetAfterError('.delegated-registration, .delegated-approvement');
        updateUI();

        var searchTerm = searchBox.val();
        var encodedSearchTerm = '';
        if (!searchTerm || searchTerm.toString().length === 0) {
            allMembers = null;
            updateUI();
            //return;
        } else {
            encodedSearchTerm = encodeURI(searchTerm);
            undelegatedList.children('div').remove();
        }

        $.get('/umbraco/api/DelegatedApi/GetMembers?club=' + clubId + '&event=' + eventSelectorValue + '&term=' + encodedSearchTerm).then(function (resp) {

            allMembers = resp.members || [];
            maxDelegates = resp.maxDelegates;

            if (delegates === null) {
                delegates = resp.delegates;
                delegateCounter = delegates.length;
                var memberString = delegates.map(function (elem) { return elem.memberNo; }).join(',');
                delegatedMacro.find('input[name="delegateMemberNos"]').val(memberString);
            } else {
                allMembers = allMembers.map(function (value) {
                    var candidates = delegates.filter(function (del) {
                        return value.memberNo === del.memberNo;
                    });
                    value.isDelegated = candidates.length !== 0;
                    return value;
                });
            }
            resetAfterError('.delegated-registration, .delegated-approvement');

            if (resp.isClosed) {
                $("form.search input, form.search button").prop('disabled', true);
                $(".mem-list-action").css('display', 'none');
            } else {
                $("form.search input, form.search button").prop('disabled', false);
                $(".mem-list-action").css('display', 'block');
            }

            delegateHuntingBoards = resp.huntingBoards;
            delegateHuntingClubs = resp.huntingClubs;
            updateUI();

        }, function (resp) {
            handleError('.delegated-registration, .delegated-approvement', resp);
        });
    }
    if (delegatedMacro.length !== 0) {
        setTimeout(function () {
            getMembers();
        });

        delegatedMacro.find('.event-selector').change(function () {
            delegates = null;
            resetDelegateRegistration();
            getMembers();
        });

        delegatedMacro.find('.club-selector').change(function () {
            delegates = null;
            resetDelegateRegistration();
            getMembers();
        });
    }


    //EventRegistration

    window.showDelegatedMember = function (modalId, memberName, memberNo, isRestant, isDelegated, delegatedNo) {

        var modal = $("#" + modalId);
        var modalParent = modal.parent()[0];
        if (modalParent.tagName.toLowerCase() !== 'body') {
            modal.appendTo($('body'));
        }

        var nameContainer = modal.find('.member-name'),
            memberNoContainer = modal.find('.member-no'),
            memberNoInput = modal.find('.input-member-no'),
            restantContainer = modal.find('.member-restant'),
            delegatedContainer = modal.find('.member-delegated'),
            delegatedNoContainer = modal.find('.member-delegatedno');

        nameContainer.html(memberName);
        memberNoContainer.html('Medlem nr.: ' + memberNo);
        memberNoInput.val(memberNo);
        restantContainer.html(convertBool(isRestant));
        delegatedContainer.html(convertBool(isDelegated));

        showIfBoolIsTrue(delegatedNoContainer, isDelegated);
        delegatedNoContainer.html('Delegeret #' + delegatedNo);
        if (!delegatedNo || delegatedNo.toString().length === 0) {
            showIfBoolIsTrue(delegatedNoContainer, false);
        }

        showIfBoolIsTrue(modal.find('.member-invalid'), isRestant);
        showIfBoolIsTrue(modal.find('.member-valid'), !isRestant);

        modal.modal();
    };



    var eventRegEventPicker = $('.event-registration form [name="event"]');
    eventRegEventPicker.change(function (ev) {
        $(this).parents('form').submit();
    });

    function renderMemberPost(currentItem, modalMemberPostsTableBody) {
        modalMemberPostsTableBody.innerHTML = modalMemberPostsTableBody.innerHTML +
            "<tr>" +

            "<td>" +
            currentItem.category +
            "</td>" +

            "<td>" +
            currentItem.city + " " + currentItem.zipCode +
            "</td>" +

            "<td>" +
            currentItem.committeeName +
            "</td>" +

            "<td>" +
            currentItem.huntingBoard +
            "</td>" +

            "<td>" +
            (currentItem.erDelegeret == true ? "Ja" : "Nej") +
            "</td>" +

            "<td>" +
            (currentItem.hasPaid == true ? "Ja" : "Nej") +
            "</td>" +
            "</tr>"
            ;
    }

    window.showDelegatedMemberPosts = function (modalId, memberName, memberNo) {
        var modalMemberPostsTableBody = document.getElementById("modalMemberPostsTableBody");
        modalMemberPostsTableBody.innerHTML = '<tr><td>Indlæser...</td></tr >';
        $.get("/umbraco/api/DelegatedApi/GetPostsForMember?axMemberID=" + memberNo).then(function (res) {
            modalMemberPostsTableBody.innerHTML = '';
            for (var i = 0; i < res.item.length; i++) {
                var currentItem = res.item[i];
                renderMemberPost(currentItem, modalMemberPostsTableBody);
            }
        });

        $('.member-not-paid-warning').hide();
        $.get("/umbraco/api/IsMemberTesterApi/IsMember?forceAxCheck=true&axMemberID=" + memberNo).then(function (res) {
            if (res && res.success && res.item === false) {
                $('.member-not-paid-warning').show();
            }
        });


        var modal = $("#" + modalId);
        var modalParent = modal.parent()[0];
        if (modalParent.tagName.toLowerCase() !== 'body') {
            modal.appendTo($('body'));
        }

        var nameContainer = modal.find('.member-name'),
            memberNoContainer = modal.find('.member-no'),
            memberNoInput = modal.find('.input-member-no');

        nameContainer.html(memberName);
        memberNoContainer.html('Medlem nr.: ' + memberNo);
        memberNoInput.val(memberNo);

        modal.modal();
    };

});
