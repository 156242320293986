$(document).ready(function () {
    setTimeout(function () {
        var elements = $('.set-margin-half-of-height');
        elements.each(function (index, el) {
            var element = $(el);
            var parentHeight = element.parent().outerHeight();
            var offset = (element.innerHeight() / 2);

            console.log("parentHeight", parentHeight);
            console.log("element.innerHeight()", element.outerHeight());
            console.log("half of element height", offset);

            element.css("margin-top", (-offset) + (parentHeight / 2));
        });
    }, 500);

});
