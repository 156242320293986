var djf = djf || {};

$(function () {
    djf.videoSlider.init();
});

djf.videoSlider = new function () {
    var self = this,
        list = $('.video-thumbs'),
        controlLeft = $('.slider-controller.prev'),
        controlRight = $('.slider-controller.next'),
        thumbnails = $('#video-module .thumbnail, #video-module #video-overlay'),
        videoContainer = $('#video-container');


    this.init = function () {
        if( list.length )
        {
            self.navSlider(list);
            self.slidePlayer(thumbnails);
        }

    };

    this.slidePlayer = function(t) {
        t.on('click', function(event){
            //var videoWidth = videoContainer.find('iframe, img').outerWidth(),
            var videoWidth = videoContainer.innerWidth(),
                videoHeight = videoWidth / (16/9),
                videoSrc = $(this).attr('data-src');
            //etc...
            if ($(this).attr('data-type') == "youtubevideo") {
                var videoKode = $('<iframe>').attr({'width': videoWidth, 'height': videoHeight, 'src': "https://www.youtube.com/embed/"+videoSrc+"?rel=0&autohide=1&showinfo=0&autoplay=1"});
            } else {
                var videoKode = $('<iframe>').attr({'width': videoWidth, 'height': videoHeight, 'src': "//player.vimeo.com/video/"+videoSrc, 'frameborder':"0", 'autoplay': "1"});
            }

            videoContainer.html(videoKode);
            //<iframe width="560" height="315" src="https://www.youtube.com/embed/5KNEZJ6KkLI" frameborder="0" allowfullscreen></iframe>
        });
    }

    this.navSlider = function(selector) {

        $(selector).owlCarousel({
            loop:false,
            center: false,
            margin:10,
            nav:true,
            navText:["<i class='icon-arrow-left'>","<i class='icon-arrow-right'>"],
            responsive:{
                0:{
                    items:3
                },
                600:{
                    items:5
                },
                1000:{
                    items:9
                }
            }
        })
    };


};