var djf = djf || {};

$(function () {
    djf.memberlookup.init();
});

djf.memberlookup = new function () {

	this.init = function () {
		if ($('#member-id-lookup').length){
			$('#btn-member-lookup').on('click', function(){
				var memberId = $('#member-id').val();
				if (memberId != '') {
					$.ajax({
					    url: '/umbraco/Api/ContactApi/GetNewMemberNumber?memberId='+memberId,
					    success: function (response) {
					    	if (response.success) {
						        $('#member-lookup-result').html(response.data);
					    	}
					    	else{
				    		 	$('#member-lookup-result').html(response.errorMessage);
					    	}
					    }
					});
				};
			})
		};
	}
};



