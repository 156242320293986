var djf = djf || {};

djf.gameJournalDelete = new function () {
var self = this;

 self.PopUpDeleteAdModal = function (adId) {
        var $button = $(this),
            modal = $('#journal-delete'),
            modalBtn = $('#delete-journal-button'),
            modalCancelBtn = $('#delete-journal-cancel-button');
        modalContent = $button.attr('href');
        modalBtn.attr("onclick", "djf.gameJournalDelete.DeleteJournal(" + adId + ")");
        modalCancelBtn.attr("onclick", "$('#journal-delete').modal(\"hide\");");
        // Close the menu
        $('html').removeClass('menu-open-left');
        $('html').removeClass('menu-open-right');
        //$.get(modalContent, function (data) {
        //     modal.find('.modal-content .modal-body').html(data);
        //});

        // Fire modal
        modal.modal();

        $('.focus :input:first').focus();
    };

  self.DeleteJournal = function (journalId) {
console.log("deleting journal with ID: " + journalId);
            $.get("/umbraco/api/GameJournalApi/DeleteGameJournal?id=" + journalId, function (response) {
                window.location.replace("/jagtjournal/journaler/");
                });
    };
};