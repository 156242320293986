$(function () {
    var schwitzerlandModuleContainer = $('.schwitzerland-module-container');
    var allMaps = {
    };
    var self = this;
    var mapsDefaultLocation = {
        lat: 56.053519,
        lng: 9.670409
    };
    //console.log("schwitzerlandModuleContainer", schwitzerlandModuleContainer);
    if (schwitzerlandModuleContainer.length) {
        init(true);
    }

    $(".table-pagination .paginate_button").on("click", function () {
        var table = $(this).parent().parent().find("table");
        var ele = $(this);
        var curPage = parseInt(table.attr("data-page"));
        var pageCount = parseInt($(".pageCount").text())

        if ($(this).hasClass("previous")) {
            curPage = (curPage - 1);
        }
        else {
            curPage = (curPage + 1);
        }

        if (curPage != 1) {
            $(".table-pagination .paginate_button.previous").removeClass("disabled");
            if (curPage != pageCount) {
                $(".table-pagination .paginate_button.next").removeClass("disabled");
            }
            else {
                $(".table-pagination .paginate_button.next").addClass("disabled");
            }
        }
        else {
            $(".table-pagination .paginate_button.previous").addClass("disabled");
        }
        table.attr("data-page", curPage);


        init(false);
    });

    function init(isInitialInit, cityFilter, zipFilter, nameQuery) {
        $(".schwitzerland-map-container").each(function (i, elem) {
            elem = $(elem);
            var rawId = elem.attr('raw-id');
            var id = elem.attr('id');
            var listId = "#schwitzerland-list_" + rawId;
            var page = $(listId).attr("data-page");
            if (isInitialInit == true) {
                initMap(id, rawId, elem);
            }
            $.get("/umbraco/api/SwitzerlandDogRegisterHunterApi/GetPaginated?cityQuery=" + (cityFilter != -1 && cityFilter ? cityFilter : "") + "&zipQuery=" + (zipFilter ? zipFilter : "") + "&nameQuery=" + (nameQuery ? nameQuery : ""), function (res) {
                var Items = res.Items;
                if (!Items) {
                    Items = res.items;
                }
                if (allMaps[rawId].markers) {
                    for (var i = 0; i < allMaps[rawId].markers.length; i++) {
                        allMaps[rawId].markers[i].googleMarker.setMap(null);
                    }
                }
                allMaps[rawId].markers = [];
                for (var j = 0; j < Items.length; j++) {
                    var marker = Items[j];
                    self.placeMarker(marker, rawId);
                }
                if (isInitialInit == true) {
                    $.get("/umbraco/api/SwitzerlandDogRegisterHunterApi/GetFilterPreValues", function (res) {
                        initHeader(rawId, res.Cities);
                    });
                }

                $.get("/umbraco/api/SwitzerlandDogRegisterHunterApi/GetPaginated?pageNumber=" + page + "&pageSize=10&cityQuery=" + (cityFilter != -1 && cityFilter ? cityFilter : "") + "&zipQuery=" + (zipFilter ? zipFilter : "") + "&nameQuery=" + (nameQuery ? nameQuery : ""), function (res) {
                    initList(id, rawId, elem, res, isInitialInit);
                });
            });
        });
        $('#page .page-sidebar+.page-content .djf-9 .rte-content').addClass('remove-padding');
    }
    function initMap(id, rawId, elem) {
        if (!rawId) {
            console.error("NO ID FOUND", rawId);
            return;
        }
        //var parentWidth = elem.parent().width();
        //var widthBeyondContainer = ($('body').width() - 30 - parentWidth); //-30 because of container padding
        //console.log("widthBeyondContainer", widthBeyondContainer);
        //elem.attr("style", "right: -" + (widthBeyondContainer / 2) + "px;left: -" + (widthBeyondContainer / 2) + "px;");

        allMaps[rawId] = {
            map: new google.maps.Map(document.getElementById(id), {
                center: mapsDefaultLocation,
                zoom: 7,
                disableDefaultUI: true
            })
        };

    }

    function initHeader(rawId, cities) {
        var listToggle = $('#schwitzerland-list-toggle_' + rawId);
        var mapToggle = $('#schwitzerland-map-toggle_' + rawId);
        var activeIfList = $('.active-if-list');
        var activeIfMap = $('.active-if-map');
        var searchBtn = $('#schwitzerland-map-search_' + rawId);
        var citySelectFilter = document.getElementById('schwitzerland-map-city-filter_' + rawId);
        var clearFilters = $('#schwitzerland-map-clear-filters_' + rawId);
        var $citySelectFilter = $(citySelectFilter);
        var $zipTextFilter = $('#schwitzerland-map-zip-filter_' + rawId);
        var $nameTextFilter = $('#schwitzerland-map-name-filter_' + rawId);
        var activeClass = 'active';
        activeIfMap.addClass(activeClass);

        listToggle.click(function () {
            activeIfMap.removeClass(activeClass);
            activeIfList.addClass(activeClass);
        });

        mapToggle.click(function () {
            activeIfList.removeClass(activeClass);
            activeIfMap.addClass(activeClass);
        });

        for (var i = 0; i < cities.length; i++) {
            var city = cities[i];
            var option_city = document.createElement("OPTION");
            option_city.innerHTML = city;
            option_city.setAttribute("value", city);
            citySelectFilter.appendChild(option_city);
        }
        searchBtn.click(function () {
            $(".tab-schwitzerland-list table").attr("data-page", 1);
            init(false, $citySelectFilter[0].value, $zipTextFilter.val(), $nameTextFilter.val());
        })

        clearFilters.click(function () {
            $citySelectFilter.val(-1), $zipTextFilter.val(""), $nameTextFilter.val("");
            searchBtn.click();
        });
    }
    function initList(id, rawId, elem, pins, isInitialInit) {
        var schwitzerlandListContainer = document.getElementById('schwitzerland-list_' + rawId);
        schwitzerlandListContainer.innerHTML = "";
        var header = constructHeaderForList();
        schwitzerlandListContainer.appendChild(header);
        for (var i = 0; i < pins.Items.length; i++) {
            var pin = pins.Items[i];
            var trList = createschwitzerlandForList(pin);
            for (var j = 0; j < trList.length; j++) {
                schwitzerlandListContainer.appendChild(trList[j]);
            }
        }

        $(".table-pagination .currentPage").html(pins.PageNumber);
        $(".table-pagination .pageCount").html(pins.PageCount);
    }
    function constructHeaderForList() {
        var tr = document.createElement("TR");

        var thName = document.createElement("TH");
        thName.innerText = "Navn";
        tr.appendChild(thName);

        var thCityZip = document.createElement("TH");
        thCityZip.innerText = "Postnr & by";
        tr.appendChild(thCityZip);

        var thPhone1 = document.createElement("TH");
        thPhone1.classList.add("phone");
        thPhone1.innerText = "Telefon 1";
        tr.appendChild(thPhone1);

        var thPhone2 = document.createElement("TH");
        thPhone2.classList.add("phone");
        thPhone2.innerText = "Telefon 2";
        tr.appendChild(thPhone2);

        return tr;
    }
    function createschwitzerlandForList(pin) {
        var resultList = [];
        for (var i = 0; i < pin.Contacts.length; i++) {

            var tr = document.createElement("TR");
            var currentContact = pin.Contacts[i];

            var tdName = document.createElement("TD");
            tdName.innerText = currentContact.Name;
            tr.appendChild(tdName);

            var tdCityZip = document.createElement("TD");
            tdCityZip.innerText = pin.ZipCode + " " + pin.City;
            tr.appendChild(tdCityZip);

            for (var j = 0; j < currentContact.PhoneNumbers.length; j++) {
                var tdPhone1 = document.createElement("TD");
                tdPhone1.innerText = currentContact.PhoneNumbers[j];
                tr.appendChild(tdPhone1);
            }
            resultList.push(tr);
        }

        return resultList;
    }
    function createschwitzerlandForPin(pin) {
        var container = document.createElement("DIV");
        container.setAttribute("class", "schwitzerland-marker-infowindow-container");

        for (var i = 0; i < pin.Contacts.length; i++) {

            if (i > 0) {
                var br = document.createElement("BR");
                container.appendChild(br);
            }

            var currentContact = pin.Contacts[i];
            var p_Name = document.createElement("P");
            p_Name.innerHTML = currentContact.Name;
            container.appendChild(p_Name);

            for (var j = 0; j < currentContact.PhoneNumbers.length; j++) {
                var p_Phone1 = document.createElement("P");
                p_Phone1.innerHTML = "Telefon " + parseInt(j + 1) + ": " + currentContact.PhoneNumbers[j];
                container.appendChild(p_Phone1);
            }
        }

        return container;
    }
    self.placeMarker = function (marker, rawId) {
        var latLng = {
            lat: marker.Latitude,
            lng: marker.Longitude
        };
        var pin = {
            info: marker,
            googleMarker: new google.maps.Marker({
                position: latLng,
                map: allMaps[rawId].map,
                icon: "/Images/schwitzerland_map_pin.png?width=32"
            }),
            infoWindow: new google.maps.InfoWindow()
        };


        pin.infoWindow.setContent(createschwitzerlandForPin(marker));
        pin.infoWindow.setPosition(latLng);
        pin.googleMarker.addListener('click', function () {
            for (var i = 0; i < allMaps[rawId].markers.length; i++) {
                allMaps[rawId].markers[i].infoWindow.close();
            }
            pin.infoWindow.open(allMaps[rawId].map, pin.googleMarker);
        });
        allMaps[rawId].markers.push(pin);
    };
});