
var djf = djf || {};

// DocReady
$(document).ready(function () {
    var amountOfItemsToDisplay = 3;
    //console.log("window.innerWidth", window.innerWidth);
    if (window.innerWidth >= 1200) {
        amountOfItemsToDisplay = 4;
    }
    var owlcarouselStories = $('.hunting-story-container.to-carousel');
    owlcarouselStories.owlCarousel({
        items: amountOfItemsToDisplay,
        loop: true,
        checkVisible: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplaySpeed: 500,
        navText: ["", ""]
    });

    //if (location.search && location.search.toString().length != 0) {
    //    console.log(location.search);
    //    var iframe = $(".hunting-story-container.to-carousel .hunting-story[data-login-redirecturl='" + location.search + "']").first().find("iframe").first();
    //    console.log("iframe found", iframe);
    //    iframe.ready(function () {
    //        setTimeout(function () {
    //            var iframeDom = iframe.contents();
    //            console.log("Found iframe dom!", iframeDom);
    //            console.log("clicked");
    //            iframeDom.find(".overlay").click();
    //        }, 1500);
    //    });


    //    //$(".hunting-story-container.to-carousel .hunting-story[data-login-redirecturl='" + location.search + "']").first().find("iframe .overlay").click();
    //}
}); // DocReady