
var djf = djf || {};

$(function () {
    djf.marketListAds.init();
    //console.log("djf", djf);
});



djf.marketListAds = new function () {
    var self = this;


    self.init = function () {

        var $listAds = $("#list-market");
        if ($listAds.length > 0) {

            $.get("/umbraco/api/MarketApi/GetCategories", function (response) {
                //console.log("response", response);
                self.AddCategoriesRecursively(response);

                var category = getUrlParameter('categoryID');
                if (category) {
                    $("#selectCategory").val(category);
                }

                $.get("/umbraco/api/Districtsapi/Get?isApp=false", function (data) {
                    const counties = self.uniqueBy(data, function (x) { return x.region; })
                        .map(function (x) { return data.find(function (y) { return y.region == x }) })
                        .sort(function (a, b) {
                            var textA = a.regionName.toUpperCase();
                            var textB = b.regionName.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                    counties.splice(0, 1);
                    //var greenland = counties.splice(0, 1);
                    //counties.push(greenland[0]);

                    console.log("counties", counties);
                    for (var i = 0; i < counties.length; i++) {
                        console.log("county", counties[i].region);
                        var countyAsNumber = parseInt(counties[i].region);
                        var region = self.GetCountyRegion(countyAsNumber - 1);
                        if (region.length > 0) {
                            counties[i].regionName = counties[i].regionName + " (" + region + ")";
                        }
                        var item = counties[i];
                        self.AddRegion(item);
                    }

                    var urlRegion = getUrlParameter('regionID');
                    if (urlRegion) {
                        $("#selectRegion").val(urlRegion);
                    }

                    var freeSearch = getUrlParameter('searchTerm');
                    if (freeSearch) {
                        $("#inputFreeSearch").val(freeSearch);
                    }

                    var toZip = getUrlParameter('toZip');
                    if (toZip) {
                        $("#inputFromZip").val(toZip);
                    }
                    var fromZip = getUrlParameter('fromZip');
                    if (fromZip) {
                        $("#inputToZip").val(fromZip);
                    }
                    self.fetchAds(false);
                });
            });
        }
        var $listMyAds = $("#list-my-market");
        if ($listMyAds.length > 0) {
            self.fetchAds(true);
        }
    }

    self.fetchAds = function (isMyAds, pageNumber, pageSize) {
        var $listAds = null;
        if (!pageNumber) {
            pageNumber = getUrlParameter('pageNumber');
            if (pageNumber == undefined) {
                pageNumber = "1";
            }
        }
        if (!pageSize) {
            pageSize = getUrlParameter('pageSize');
            if (pageSize == undefined) {
                pageSize = "10";
            }
        }


        if (!isMyAds) {

            $listAds = $("#list-market");
            var freeSearch = $("#inputFreeSearch")[0].value;
            var category = $("#selectCategory")[0].value;
            var region = $("#selectRegion")[0].value;
            var fromZip = $("#inputFromZip")[0].value;
            var toZip = $("#inputToZip")[0].value;
            $.get("/umbraco/api/MarketApi/GetMarketAds?pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&searchTerm=" + freeSearch + "&categoryId=" + category + "&regionId=" + region + "&fromZip=" + fromZip + "&toZip=" + toZip, function (response) {
                $('#amountOfPages').text(response.PageCount);
                self.UpdateAds(response, $listAds, pageSize);
            });
        } else {
            $listAds = $("#list-my-market");
            $.get("/umbraco/api/MarketApi/GetMarketAdsByUser?web=1", function (response) {
                var noActiveAds = true;
                if (response.length > 0) {
                    for (var i = 0; i < response.length; i++) {
                        if (!response[i].Expired) {
                            noActiveAds = false;
                        }
                    }
                    $('#amountOfPages').text(response.PageCount);
                    self.UpdateAds(response, $listAds, pageSize);
                }
                if (noActiveAds) {
                    $('.no-active-market').show();
                }
            });
        }
    };

    self.GetCountyRegion = function (i) {
        console.log("get county region i", i);
        switch (i) {
            case 0:
                { return "Nordjylland"; }
            case 1:
                { return "Midt- og Vestjylland"; }
            case 2:
                { return "Østjylland"; }
            case 3:
                { return "Sydjylland"; }
            case 4:
                { return "Fyn"; }
            case 5:
                { return "Sydsjælland og øerne"; }
            case 6:
                { return "Nordsjælland og København"; }
            case 7:
                { return "Bornholm"; }
            case 8:
                { return ""; }
            case 9:
                { return ""; }
            default:
                { return "REGION NOT FOUND"; }
        }
    }
    // Snippet thanks to https://stackoverflow.com/users/244353/mrchief
    self.uniqueBy = function (arr, fn) {
        var unique = {};
        var distinct = [];
        arr.forEach(function (x) {
            var key = fn(x);
            if (!unique[key]) {
                distinct.push(key);
                unique[key] = true;
            }
        });
        return distinct;
    }

    self.AddRegion = function (item) {
        $("#selectRegion").append($('<option>', {
            value: item.region,
            text: item.regionName
        }));
    };

    self.AddCategoriesRecursively = function (iEnumOfCategories, isChild, father) {
        for (var i = 0; i < iEnumOfCategories.length; i++) {
            var item = iEnumOfCategories[i];
            if (isChild) {
                item.Name = father.Name + " - " + item.Name;
            }
            if (item) {
                self.AddCategory(item, isChild, father);
                if (item.Children != null) {
                    self.AddCategoriesRecursively(item.Children, true, item);
                }
            }
        }
    }

    self.AddCategory = function (item, isChild, father) {
        $("#selectCategory").append($('<option>', {
            value: item.ID,
            text: (item.ParentID == 0 ? item.Name : "" + item.Name)
        }));
    }

    self.ClearFilter = function () {
        $('#inputFreeSearch').val("");
        $('#selectCategory').val("-1");
        $('#selectRegion').val("-1");
        $('#inputFromZip').val("");
        $('#inputToZip').val("");
        self.fetchAds(false);
    }

    self.Navigate = function (pageNumber, pageSize) {
        self.fetchAds(false, pageNumber, pageSize);
    };

    function ensureNumIs0Prepended(num) {
        var stringToReturn = "" + num;
        if (stringToReturn.length == 1) {
            stringToReturn = "0" + stringToReturn;
            return stringToReturn;
        } else {
            stringToReturn = "" + stringToReturn;
            return stringToReturn;
        }
    }

    self.UpdateAds = function (response, $listAds, pageSize) {
        var listAds = $listAds[0];
        listAds.innerHTML = "";
        var items = [];;
        if (listAds.id == "list-my-market") {
            items = response;
        } else if (listAds.id == "list-market") {
            items = response.Items;
        }
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            var adContainer = document.createElement("DIV");
            adContainer.setAttribute("class", "market-item-link");
            adContainer.setAttribute("href", "/marked/annoncer/annonce/?id=" + item.ID);

            var ad = document.createElement("DIV");
            ad.setAttribute("class", "market-item");
            adContainer.appendChild(ad);

            var adImage = document.createElement("DIV");
            adImage.setAttribute("class", "market-item-image-container");
            var image = document.createElement("IMG");
            var src = "";
            for (var x = 0; x < 4; x++) {
                if (item.Images[x] !== null && item.Images[x] !== undefined) {
                    src = item.Images[x] + "?width=216&height=162&bgcolor=fff";
                    x = 10000;
                }
            }
            if (src === "" || src === undefined) {
                src = "/images/intet-billede.jpg?width=216&height=162&bgcolor=fff";
            }
            image.setAttribute("src", src);
            adImage.appendChild(image);
            ad.appendChild(adImage);

            var adText = document.createElement("DIV");
            adText.setAttribute("class", "market-item-text-container");
            var text = "<p><b>" + item.Title + "</b><p/>";
            if (item.CreatedOn) {

                var createdOn = new Date(item.CreatedOn);
                text += "<p>Oprettet: " + ensureNumIs0Prepended(createdOn.getDate()) + "-" + ensureNumIs0Prepended(createdOn.getMonth() + 1) + "-" + ensureNumIs0Prepended(createdOn.getFullYear()) + "</p>";
            }
            text += "<br/>" + item.Text;

            adText.innerHTML = text;
            ad.appendChild(adText);

            var adShowBtn = document.createElement("DIV");
            adShowBtn.setAttribute("class", "market-item-show-container");
            var aTag = document.createElement("A");
            aTag.setAttribute("class", "show-button");
            aTag.setAttribute("href", "/marked/annoncer/annonce/?id=" + item.ID);
            var spanTag = document.createElement("SPAN");
            spanTag.setAttribute("class", "button-text");
            spanTag.innerHTML = "Vis";
            aTag.appendChild(spanTag);
            adShowBtn.appendChild(aTag);
            ad.appendChild(adShowBtn);
            if (item.IsOwner) {
                var aTagEdit = document.createElement("A");
                aTagEdit.innerHTML = "Ret annonce";
                aTagEdit.setAttribute("href", "/marked/mine-annoncer/opret-annonce/?mode=edit&adId=" + item.ID);
                aTagEdit.setAttribute("class", "link edit-link");
                adShowBtn.appendChild(aTagEdit);

                var aTagDelete = document.createElement("A");
                aTagDelete.innerHTML = "Slet annonce";
                aTagDelete.setAttribute("class", "link delete-link");
                aTagDelete.setAttribute("onclick", "djf.marketListAds.PopUpDeleteAdModal(" + item.ID + ")");
                adShowBtn.appendChild(aTagDelete);
                var currentTime = new Date();
                var expiresOnAsDate = new Date(Date.parse(item.ExpiresOn));
                if (expiresOnAsDate < currentTime) {
                    var aTagRenew = document.createElement("A");
                    aTagRenew.innerHTML = "Forny annonce";
                    aTagRenew.setAttribute("class", "link renew-link");
                    aTagRenew.setAttribute("onclick", "djf.marketListAds.Renew(" + item.ID + ", '" + item.Title + "')");
                    adShowBtn.appendChild(aTagRenew);
                }
            }
            listAds.appendChild(ad);
        }
        $('#all-table_info').html("Side " + response.PageNumber + " af <span id=\"amountOfPages\">" + response.PageCount + "</span>");
        $('.paginate_button.next').removeAttr('onclick');
        $('.paginate_button.previous').removeAttr('onclick');
        var pageNum = parseInt(response.PageNumber);
        var prevPageNum = pageNum - 1;
        var nextPageNum = pageNum + 1;
        $('.paginate_button.next').attr('onClick', 'djf.marketListAds.Navigate(' + nextPageNum + ', ' + response.PageSize + ');');

        $('.paginate_button.previous').attr('onClick', 'djf.marketListAds.Navigate(' + prevPageNum + ', ' + response.PageSize + ');');

        $('#ButtonName').attr('onClick', 'FunctionName(this);');
        if (response.PageNumber === 1) {
            $('#prev_page').hide();
        } else {
            $('#prev_page').show();
        }
        if (response.PageNumber === response.PageCount) {
            $('#next_page').hide();
        } else {
            $('#next_page').show();
        }
        $('.bottom-pagination').show();
    };

    self.PopUpDeleteAdModal = function (adId) {
        var $button = $(this),
            modal = $('#market-delete-ad'),
            modalBtn = $('#delete-ad-modal-button'),
            modalCancelBtn = $('#delete-ad-modal-cancel-button');
        modalContent = $button.attr('href');
        modalBtn.attr("onclick", "djf.marketListAds.DeleteAd(" + adId + ")");
        modalCancelBtn.attr("onclick", "$('#market-delete-ad').modal(\"hide\");");
        // Close the menu
        $('html').removeClass('menu-open-left');
        $('html').removeClass('menu-open-right');
        //$.get(modalContent, function (data) {
        //     modal.find('.modal-content .modal-body').html(data);
        //});

        // Fire modal
        modal.modal();

        $('.focus :input:first').focus();
    };

    self.Renew = function (adId, title) {
        $.get("/umbraco/api/MarketApi/RenewAd?id=" + adId, function (response) {
            //self.AddCategoriesRecursively(response);
            if (response) {
                window.location = "/marked/mine-annoncer?renewed=" + adId + "&renewedTitle=" + title;
            }
        });
    };

    self.DeleteAd = function (adId) {
        $.get("/umbraco/api/MarketApi/DeleteAd?id=" + adId, function (response) {
            window.location.replace("/marked/mine-annoncer/");
        });
    };

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };
};
