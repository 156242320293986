function toggleArea(id) {
    //var element = document.getElementById(id);
    //var x = document.getElementsByClassName("areas-menu-level-2");
    //for (var i = 0; i < x.length; i++) {
    //    if (x[i].id != id && x[i].className.split(" ").indexOf("hidden") == -1) {
    //        x[i].classList.add("hidden");
    //    }
    //    else if (x[i].id == id && x[i].className.split(" ").indexOf("hidden") > -1) {
    //        x[i].classList.remove("hidden");
    //    }
    //}

    var area = id;

    $(".area-item").addClass('hidden');
    var areaUl = $("#" + area);
    areaUl.removeClass('hidden');
}

$("[data-area]").click(function () {
    var area = $(this).data('area');
    
    $(".area-item").addClass('hidden');
    var areaUl = $("#" + area);
    areaUl.removeClass('hidden');
});