$(document).ready(function () {
    $("table.gameReportTable").on("click", "thead th input.check-all", function () {
        if ($(this).is(":checked")) {
            var idsInString = "";

            $("table.gameReportTable tbody tr input:checkbox").each(function () {
                $(this).prop('checked', true);
                var parent = $(this).parent().parent();
                var id = parent.attr("data-journal-id");
                if (idsInString == "") {
                    idsInString += id;
                }
                else {
                    idsInString += "," + id;
                }
            });
            $("#reportModel_IdsInString").val(idsInString);

        }
        else {
            $("table.gameReportTable tbody tr input:checkbox").each(function () {
                $(this).prop('checked', false);
            });
            $("#reportModel_IdsInString").val("");
        }
    });

    $("table.gameReportTable").on("click", "tbody tr input", function () {
        if ($(this).is(":checked")) {
            var idsInString = $("#reportModel_IdsInString").val();
            var parent = $(this).parent().parent();
            var id = parent.attr("data-journal-id");
            if (idsInString == "") {
                idsInString += id;
            }
            else {
                idsInString += "," + id;
            }

            $("#reportModel_IdsInString").val(idsInString);
        }
        else {
            var idsInString = "";

            $("table.gameReportTable tbody tr input:checkbox").each(function () {
                if ($(this).is(":checked")) {
                    var parent = $(this).parent().parent();
                    var id = parent.attr("data-journal-id");
                    if (idsInString == "") {
                        idsInString += id;
                    }
                    else {
                        idsInString += "," + id;
                    }

                }
            });

            $("#reportModel_IdsInString").val(idsInString);
        }
    });
    //$("#normalReport").on("click", function (event) {
    //    var ele = $(this);
    //    event.preventDefault();

    //    var IdList = [];

    //    $("table.gameReportTable tbody tr input:checkbox").each(function () {
    //        if ($(this).is(":checked")) {
    //            var parent = $(this).parent().parent();
    //            var id = parent.attr("data-journal-id");
    //            IdList.push(id);
    //        }
    //    });

    //    $("#reportModel_IdsInString").val(IdList.join());

    //    var form = $(".game-journal-reporting form");
    //    form.submit();
    //});
});