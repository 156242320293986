
var djf = djf || {};

$(function () {
    djf.gallery.init();
});


djf.gallery = new function () {
    var self = this,
        items = $('.gallery-list > li.media a');


    this.init = function () {
        self.swipebox(items);
    };
    this.swipebox = function (t) {
    	t.swipebox({
/*    		initialIndexOnArray: 0, // which image index to init when a array is passed
    		hideCloseButtonOnMobile : false, // true will hide the close button on mobile devices
    		hideBarsDelay : 3000, // delay before hiding bars on desktop
    		videoMaxWidth : 1140, // videos max width
    		beforeOpen: function(){} , // called before opening
    		afterOpen: null, // called after opening
    		afterClose: function(){}, // called after closing
    		loopAtEnd: false, // true will return to the first image after the last image is reached
    		autoplayVideos: false // true will autoplay Youtube and Vimeo videos
    		queryStringData: {} // plain object with custom query string arguments to pass/override for video URLs,
    		toggleClassOnLoad: '', // CSS class that can be toggled when the slide will be loaded (like 'hidden' of Bootstrap)*/
            removeBarsOnMobile: false,
            useSVG: false,
            hideBarsDelay : 60000, // delay before hiding bars on desktop
    		useCSS : true // false will force the use of jQuery for animations
    	});

    };

};

$(".create-journal-container .file-upload").swipebox();

// Stolen from http://stackoverflow.com/questions/1309483/redirect-user-to-current-page-with-some-querystring-using-javascript
function reloadWithQueryStringVars(queryStringVars, ignoreExistingParams) {
    var existingQueryVars = location.search ? location.search.substring(1).split("&") : [],
        currentUrl = location.search ? location.href.replace(location.search, "") : location.href,
        newQueryVars = {},
        newUrl = currentUrl + "?";

    if (existingQueryVars.length > 0 && ignoreExistingParams != true) {
        for (var i = 0; i < existingQueryVars.length; i++) {
            var pair = existingQueryVars[i].split("=");
            newQueryVars[pair[0]] = pair[1];
        }
    }
    if (queryStringVars) {
        for (var queryStringVar in queryStringVars) {
            newQueryVars[queryStringVar] = queryStringVars[queryStringVar];
        }
    }
    if (newQueryVars) {
        for (var newQueryVar in newQueryVars) {
            newUrl += newQueryVar + "=" + newQueryVars[newQueryVar] + "&";
        }
        newUrl = newUrl.substring(0, newUrl.length - 1);
        window.location.href = newUrl;
    } else {
        window.location.href = location.href;
    }
}