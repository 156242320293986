
$(document).ready(function () {

    if ($("#unionMessage_MagazineText") && $("#unionMessage_MagazineText").val() && $("#unionMessage_MagazineText").val().length > 0) {
        var length = $("#unionMessage_MagazineText").val().length + $("#unionMessage_MagazineText").val().split("\n").length - 1;
        $("#magazine-text-letter-count").html(length + "/250");

        if (length > 250) {
            $("#magazine-text-letter-count").css('color', 'red');
        } else {
            $("#magazine-text-letter-count").css('color', 'rgb(60,60,60)');
        }
    }

    $("#unionMessage_MagazineText").on('change keyup paste', function () {

        var length = $(this).val().length + $(this).val().split("\n").length - 1;
        $("#magazine-text-letter-count").html(length + "/250");

        if (length > 250) {
            $("#magazine-text-letter-count").css('color', 'red');
        } else {
            $("#magazine-text-letter-count").css('color', 'rgb(60,60,60)');
        }
    });
});