var djf = djf || {};



djf.login = new function () {
    var self = this,
        modalTrigger = $('a.modal-trigger, .hunting-story.modal-trigger, .reviews-item.modal-trigger');


    this.init = function () {
        self.popup(modalTrigger);
    };
    this.popup = function (t) {
        t.click(function(e){
            e.preventDefault();
            var $button = $(this),
            modal = $('#modal'),
            modalContent = $button.attr('href');

            // Close the menu
            $('html').removeClass('menu-open-left');
            $('html').removeClass('menu-open-right');
            //$.get(modalContent, function (data) {
            //     modal.find('.modal-content .modal-body').html(data);
            //});

            // Fire modal
            modal.modal();

            $('.focus :input:first').focus();

            var redirecturl = $button.data("login-redirecturl");
            console.log("$button", $button);
            console.log("redirecturl", redirecturl);
            if (redirecturl && redirecturl.toString().length != 0) {
                modal.find("#RequestedPage").val(redirecturl);
            }
        });

    };

};
$(function () {
    djf.login.init();
});
