var djf = djf || {};

djf.tabbar = new function () {
    var self = this,
        barItems = $('.tab-bar a'),
        scrollBar = $('.tab-bar');


    this.init = function () {
        // Add active menu functionality to tabbar items
        if (barItems) {
            self.tabbarShowTab(barItems);
        }

        if (scrollBar) {
            self.tabSlider(scrollBar);
        }

    };

    // Move the tab-bar
    this.tabbarScroll = function (t) {
        /*        var $this = t,
                    wrapper = $this.parents('.tab-bar--wrapper');
                    parentCenter = wrapper.outerWidth(true) / 2, // Get center of the wrapper
                    tab = $this.children('li'),  // Find the active items
                    tabCount = tab.length,
                    tabWidth = tab.outerWidth(true), // Get the outer width including padding and margin
                    activeTab = $this.find('li.active'),  // Find the active items
                    tabsBefore = activeTab.prevAll().length, // Find number of items before the active
                    margin = tabWidth * tabsBefore - parentCenter + (tabWidth/2); // Center the active
        
        
                    if ((tabWidth*tabCount) > wrapper.width()) {
        
                        if (margin > 0) {
                            t.animate({'margin-left': '-'+margin+'px'});
                            //t.css('margin-left', '-'+margin+'px');
                        } else {
                            t.animate({'margin-left': '0px'});
                            //t.css('margin-left', '0px');
                        };
        
                    }*/

    };

    this.tabSlider = function (selector) {
        $(selector).addClass("ready");

        jQuery.each(selector, function (i, elem) {
            elem = $(elem);

            if (!elem.hasClass('dropdown')) {
                elem.addClass('owl-carousel');
                elem.owlCarousel({
                    stagePadding: 0,
                    loop: false,
                    center: false,
                    margin: 0,
                    nav: true,
                    navText: ["", ""],
                    margin: 0,
                    autoWidth: false,
                    URLhashListener: true,
                    startPosition: 'URLHash',
                    dotsEach: true,
                    onInitialize: onInit,
                    responsive: {
                        0: {
                            stagePadding: 20,
                            items: 2
                        },
                        600: {
                            items: 3
                        },
                        768: {
                            items: 3
                        },
                        1024: {
                            items: 6,
                            mouseDrag: false
                        },
                        1300: {
                            items: 8,
                            mouseDrag: false
                        }
                    }
                })
            }
        });


        function onInit() {
            initDropdown();

            var selectorItem = $('a[data-hash]');
            var hashID = window.location.hash.replace("#", "");
            
            if (hashID != "" && selectorItem.length) {
                $('a[data-hash=' + hashID + ']').click();
            }
        }

        var isDropdownInited = false;
        function initDropdown() {

            if (isDropdownInited) {
                return;
            }
            isDropdownInited = true;
            
            $('.dropdown.tab-bar .dropdown-button').click(function () {
                //console.log('clicked dropdown button', $(this));
                $(this).siblings('.dropdown-content')
                    .toggleClass('visible');
                $(this).toggleClass('active');
            });

            $('.dropdown .dropdown-content a').click(function () {
                var parent = $(this).parent();
                var text = $(this).text();

                parent.removeClass('visible');

                var button = parent.siblings('.dropdown-button');
                button.children('span').html(text)
                button.removeClass('active');;
            });

        }


    };

    // Toggle active class
    this.tabbarShowTab = function (selector) {
        selector.on('click', function () {      
            self.initActiveTab($(this), selector);

        });

    };

    this.initActiveTab = function (selector, selectorClass) {
        selector.parents('.tab-bar').find(selectorClass.not(selector).removeClass('active'));
        selector.addClass('active');

        var tabId = selector.attr('data-tab');
        var tabContent = selector.parents('.tab-bar--outer').find('.tab-content');
        // Check if the tab-content match with the clicked list-items
        $(tabContent).each(function () {
            if ($(this).attr('data-tab') == tabId) {
                $(this).show().addClass('active');
            } else {
                $(this).hide().removeClass('active');
            }
        });


    };

};


$(function () {
    djf.tabbar.init();
});

//this.validateCheckboxTerms = function () {
//    console.log("hi");
//    return false;
//}

//function validateCheckboxTerms() {
//    var isChecked = document.getElementById("signupModel_termsChecked").checked;
//    if (!isChecked) {
//        $('#signupModel_termsChecked-error').show();
//    }
//    return isChecked;
//}

$("#signupForm_Submitter").click(function (event) {
   
    var isChecked = document.getElementById("signupModel_termsChecked").checked;
    if (!isChecked) {
        event.preventDefault();
        $('#signupModel_termsChecked-error').show();
    }
    return isChecked;
});