var djf = djf || {};

djf.marketAd = new function () {

    smallImgDimentionsString = "?width=125&height=94&bgcolor=fff";
    largeImgDimentionsString = "?width=640&height=345&bgcolor=fff";

    this.init = function () {
        var searchParams = new URLSearchParams(window.location.search);
        //console.log("searchParams", searchParams);
        var id = searchParams.get('id');
        //console.log("id", id);
        $.ajax('/umbraco/Api/MarketApi/GetMarketAdStats?id=' + id).done(function (data) {
            var statistics = data;
            var clicks = 0;
            //console.log("all statistics", statistics);
            for (var i = 0; i < statistics.length; i++) {
                var statistic = statistics[i];
                //console.log("statistic", statistic);
                //labels.push("Uge " + statistic.WeekNo);
                clicks = clicks + statistic.ClickCount;
                //viewCountDataset.data.push(statistic.ViewCount);
            }
            //console.log("clicks", clicks);

            $('#market-item-clicks')[0].innerHTML = "Antal visninger: " + clicks;
        });

        $('.market-item-link').click(function () {
            var obj = $(this);
            if (window.innerWidth < 1024) {
                window.location = obj[0].attributes["href"].value;
            }
        });

        $('.sub-image img').click(function (ev) {
            //console.log("ev", ev);
            var main = $("#show-ad-image-main");
            var target = ev.target;
            //console.log("target", target);

            var src = target.src;

            //console.log("src", src);
            if (src.indexOf(smallImgDimentionsString) != -1) {
                src = src.substring(0, src.indexOf(smallImgDimentionsString));
            }
            //console.log("src1", src);
            src = src + largeImgDimentionsString;
            main.attr("src", src);
        });

    };
};
