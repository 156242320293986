var djf = djf || {};
$(function () {
    if (!window.getUrlParameter) {
        window.getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };
    }
    var origin = getUrlParameter('origin');
    var lng = getUrlParameter('longitude');
    var lat = getUrlParameter('latitude');
    if (origin == 'app' && lng && lat) {
        var positionobj = {
            coords: {
                latitude: lat,
                longitude: lng
            }
        }
        djf.weather.init(positionobj);
    } else {
        if ($('#weather-module').length || $('#weather-module-forecast').length) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(djf.weather.init, djf.weather.noLocationConsent);
            } else {
                djf.weather.init();
            }
        }
    }
});

djf.weather = new function () {
    var self = this,
        element = $('#weather-module'),
        forecastElement = $('#weather-module-forecast');

    this.userCityName = "";

    this.init = function (position) {
        self.position = position;
        if (self.position.coords) {
            var initLat = self.position.coords.latitude;
            var initLong = self.position.coords.longitude;
            var weatherUrl = forecastElement.data('weather-url');
            if (weatherUrl === undefined) {
                weatherUrl = element.data('forecast-url');
            }
            weatherUrl = weatherUrl.replace('{lat}', initLat);
            weatherUrl = weatherUrl.replace('{lon}', initLong);
            var city = "";
            $.ajax({
                url: weatherUrl,
                success: function (response) {
                    self.latitude = response.city.coord.lat;
                    self.longitude = response.city.coord.lon;
                    self.initNormal();
                    self.initForecast();
                }
            });
        }
        else {
            self.initNormal();
            self.initForecast();
        }
    }

    this.noLocationConsent = function (error) {
        //forecast
        if (forecastElement.length) {
            var $cityInput = forecastElement.find("#city-input");

            $cityInput.placeholder = "Vi kunne desværre ikke finde en vejrudsigt for din pågældende lokation";
            autocompleteMunicipalities(document.getElementById("city-input"));
            setTimeout(function () {
                forecastElement.addClass('loaded');
            }, 1000);
        }

        //forside
        if (element.length) {
            var $sunUp = element.find('.sun-up');
            var $sunDown = element.find('.sun-down');
            $sunUp.text("NaN");
            $sunDown.text("NaN");

            var sunupSunDown = $('#sun-up-sun-down-container');
            sunupSunDown.find('a').hide();
            sunupSunDown.append("<span>Lokation kunne ikke findes.</span>");

            var sunUpSunDownMobule = $('.mobile-weather-container');
            sunUpSunDownMobule.find('h4').text('Lokation kunne ikke findes.').attr("style", "margin-bottom: 11px; height: 34px;");
            sunUpSunDownMobule.find('.time').hide();
            sunUpSunDownMobule.find('.city').hide();

        }
    }


    this.initNormal = function () {
        debugger;
        if (!element.length) {
            return;
        }


        var $temp = element.find('.temp');
        var $sunUp = element.find('.sun-up');
        var $sunDown = element.find('.sun-down');
        var $city = element.find('.js-city');
        var $icon = element.find('.weather-icon-container');


        var locationUrl = element.data('location-url');
        var weatherUrl = element.data('weather-url');
        if (self.position.coords) {
            self.latitude = self.position.coords.latitude;
            self.longitude = self.position.coords.longitude;
        }


        weatherUrl = weatherUrl.replace('{lat}', self.latitude);
        weatherUrl = weatherUrl.replace('{lon}', self.longitude);
        $.ajax({
            url: weatherUrl,
            jsonp: "callback",
            dataType: "jsonp",
            data: {},
            success: function (response) { // Weather success
                $temp.html(Math.round(response.main.temp) + '&deg;');

                var icon = response.weather[0].icon.toString();
                var iconName = "weather-" + icon.slice(0, Math.min(icon.length, 2));
                $icon.append($('<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/icons/icons.svg#' + iconName + '"></use></svg>'))
            }
        });

        var date = new Date();
        var dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        var lat = self.latitude;
        var lng = self.longitude;
        var sunriseSunsetUrl = '/umbraco/api/app/GetWeatherInfoForecast?lat=' + lat + '&lon=' + lng + '&date=' + dateString;
        $.ajax({
            url: sunriseSunsetUrl,
            success: function (response) {
                $sunUp.text(response.sunriseString);
                $sunDown.text(response.sunsetString);
            }
        })
    };


    this.initForecast = function (city, long, lat) {


        if (!forecastElement.length) {
            return;
        }

        var locationUrl = forecastElement.data('location-url');
        var weatherUrl = forecastElement.data('weather-url');

        var $city = forecastElement.find(".city");
        var $cityInput = forecastElement.find("#city-input");
        var $forecast = forecastElement.find(".forecast");
        var query;


        if (city) {
            query = city;
            self.latitude = lat;
            self.longitude = long;
        } else if (!city && self.position.coords && typeof self.latitude === 'undefined' && typeof self.longitude === 'undefined') {
            query = "";
            self.latitude = self.position.coords.latitude;
            self.longitude = self.position.coords.longitude;
        }
        else {
            query = "";
        }

        query = replaceDkCharacters(query);

        weatherUrl = weatherUrl.replace('{lat}', self.latitude);
        weatherUrl = weatherUrl.replace('{lon}', self.longitude);
        setTimeout(function () {
            forecastElement.addClass('loaded');
        }, 1000);
        $.ajax({
            url: weatherUrl,
            jsonp: "callback",
            dataType: "jsonp",
            data: {},
            success: function (response) { // Weather success
                $forecast.empty();
                self.userCityName = response.city.name;
                //$city.text(response.city.name);
                $cityInput.attr("placeholder", ActualLocation(self));
                for (var i = 0; i < response.list.length; i++) {
                    addForecast($forecast, response.list[i], i);
                }
                forecastElement.addClass('loaded');
            }
        });
        autocompleteMunicipalities(document.getElementById("city-input"));
    }

    function ActualLocation(self) {
        if (self.latitude && self.longitude) {
            return 'Aktuel lokation';
        }
        return self.userCityName;
    }


    function autocompleteMunicipalities(inp) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;

        function inputChanged(element) {
            var arr = [];
            var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
            var url = "";
            if (val.length == 0) {
                inp.placeholder = "";
                closeAllLists();
                return;
            }
            $.get("https://dawa.aws.dk/stednavne2/autocomplete?&undertype=by&q=" + val, function (response) {
                arr = response;
                //if (val.length == 0) {
                //    $('#dropdownCounties_value').val("").trigger('change');
                //}
                /*close any already open lists of autocompleted values*/
                closeAllLists();
                //if (!val) { return false; }
                currentFocus = -1;
                /*create a DIV element that will contain the items (values):*/
                a = document.createElement("DIV");
                a.setAttribute("id", element.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                /*append the DIV element as a child of the autocomplete container:*/
                element.parentNode.appendChild(a);
                /*for each item in the array...*/
                for (i = 0; i < arr.length; i++) {
                    var s = escapeSearchInput(arr[i].navn).toUpperCase();
                    if (s.indexOf(val) != -1) {
                        /*create a DIV element for each matching element:*/
                        b = document.createElement("DIV");
                        /*make the matching letters bold:*/
                        b.innerHTML += arr[i].navn;

                        if (arr[i].sted && arr[i].sted.kommuner.length > 0) {
                            b.innerHTML += ' (' + arr[i].sted.kommuner[0].navn + ' Kommune)';
                        }
                        //                /*insert a input field that will hold the current array item's value:*/
                        b.innerHTML += "<input type='hidden' value='" + arr[i].navn + "' data-name='" + arr[i].navn + "' data-lat='" + arr[i].sted.visueltcenter[1] + "' data-long='" + arr[i].sted.visueltcenter[0] + "'>"; //arr[i].County
                        //$('#dropdownCounties_value').trigger('change');
                        /*execute a function when someone clicks on the item value (DIV element):*/
                        //////////////////////////////////////////////////////////////////////////////
                        b.addEventListener("click", function (e) {
                            /*insert the value for the autocomplete text field:*/
                            var obj = $(e.srcElement);
                            var inputField = obj.find("input");
                            var name = inputField.data("name");
                            var lat = inputField.data("lat");
                            var long = inputField.data("long");
                            $('#city-input_value').val(name).trigger('change');
                            inp.value = this.getElementsByTagName("input")[0].value;
                            djf.weather.initForecast(name, long, lat);
                            /*close the list of autocompleted values,
                            (or any other open lists of autocompleted values:*/
                            closeAllLists();
                        });
                        a.appendChild(b);

                    }
                }
                var aAsJquery = $(a);
                a.setAttribute("style", "width:" + (aAsJquery.width() + 1) + "px;");
            });
        }

        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            inputChanged(this);
        });
        /*execute a function when someone writes in the text field:*/
        var dropdownMunicipalitiesInput = document.getElementById("city-input");
        dropdownMunicipalitiesInput.addEventListener("click", function () {
            $('#city-input_value').val("").trigger('change');
            $('#city-input').val("").trigger('change');
        });

        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) { x = x.getElementsByTagName("div"); }
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) { x[currentFocus].click(); }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) { return false; }
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) { currentFocus = 0; }
            if (currentFocus < 0) { currentFocus = (x.length - 1); }
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }

        /*execute a function when someone clicks in the document:*/
        inp.addEventListener("click", function (e) {
            setTimeout(function () {
                inputChanged(inp, e.target);
            });
        });

        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    function replaceDkCharacters(str) {
        return str
            .replace('\346', '%C3%A6').replace('\306', '%C3%86')
            .replace('\370', '%C3%B8').replace('\330', '%C3%98')
            .replace('\345', '%C3%A5').replace('\305', '%C3%85');
    }

    function fetchSunriseSunset(date, container) {
        var dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        var lat = self.latitude;
        var lng = self.longitude;
        var hunterSunriseSunsetUrl = '/umbraco/api/app/GetWeatherInfoForecast?lat=' + lat + '&lon=' + lng + '&date=' + dateString;


        $.ajax({
            url: hunterSunriseSunsetUrl,
            success: function (response) {
                var result = response.results;
                var sunriseSunsetSpan = $('<div class="sunrise-sunset">');
                var sunriseSunsetText = 'Sol op/ned: ' + response.sunriseString + ' - ' + response.sunsetString;
                sunriseSunsetSpan.text(sunriseSunsetText);
                container.append(sunriseSunsetSpan);
            }
        });
    }

    function addForecast($forecast, item, index) {

        var date = new Date(item.dt * 1000);

        if (date.getHours() < 3 || index == 0) {
            var dayContainer = $('<div class="day">');
            dayContainer.html(getDayOfWeek(date));
            dayContainer.appendTo($forecast);

            fetchSunriseSunset(date, dayContainer);
        }

        var container = $('<div class="forecast-item">');

        //date
        var dateContainer = $('<div class="date">');
        var dateString = formatTime(date);
        dateContainer.text(dateString);
        dateContainer.appendTo(container);

        var tempAndWeather = $('<div class="temp-and-weather">');

        //weather
        var $icon = $('<span class="weather-icon-container">');
        var icon = item.weather[0].icon.toString();
        var iconName = "weather-" + icon.slice(0, Math.min(icon.length, 2));
        $icon.append($('<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/icons/icons.svg#' + iconName + '"></use></svg>'))
        $icon.appendTo(tempAndWeather);

        //temp
        var $temp = $('<span class="temp">');
        $temp.html(Math.round(item.main.temp) + '&deg;');
        $temp.appendTo(tempAndWeather);
        container.append($('<div class="weather">').append(tempAndWeather));

        //Wind
        if (item.wind && item.wind.deg) {
            var windContainer = $('<div class="wind">');

            //Wind direction
            var windDeg = Math.floor(item.wind.deg);
            var windIcon = $('<img src="/dist/images/vejr-vindretning.png" style="transform:rotate(' + windDeg + 'deg)" />');
            windContainer.append($('<div class="wind-icon-container">').append(windIcon));

            //Wind speed
            if (item.wind.speed) {
                var windText = item.wind.speed.toFixed(1) + ' m/s';
                var windSpan = $('<span />').text(windText);
                windContainer.append(windSpan);
            }

            windContainer.appendTo(container);
        }

        container.appendTo($forecast);
    }

    function getDayOfWeek(date) {
        switch (date.getDay()) {
            case 1: { return 'Mandag'; }
            case 2: { return 'Tirsdag'; }
            case 3: { return 'Onsdag'; }
            case 4: { return 'Torsdag'; }
            case 5: { return 'Fredag'; }
            case 6: { return 'L&oslash;rdag'; }
            case 0:
            case 7: { return 'S&oslash;ndag'; }
            default: { return ''; }
        }
    }

    function formatTime(date) {
        return pad(date.getHours()) + "." + pad(date.getMinutes());
    }

    function pad(t) {
        return ('0' + t).slice(-2);
    }

};


