var djf = djf || {};
$(document).ready(function () {
    //console.log("runnning");
    djf.gameJournalUpdateCreate.startWatcher();
});

djf.gameJournalUpdateCreate = new function () {
    var self = this;

    self.startWatcher = function () {
        //console.log("$('#gameJournal_CountryID')", $('#gameJournal_CountryID'));
        $('#gameJournal_CountryID').change(function (newVal, oldVal) {
            djf.gameJournalUpdateCreate.SetMunicipalitySelectVisibility();
        });
        djf.gameJournalUpdateCreate.SetMunicipalitySelectVisibility();
    };

    self.SetMunicipalitySelectVisibility = function () {
        var newCountry = $('#gameJournal_CountryID');
        if (!newCountry.length) {
            var spanCountry = $('#gamejournal-display-country');
            if (spanCountry.length) {
                var spanCountryID = spanCountry.data("country-id");
                if (spanCountryID === 1) {
                    $('.show-if-denmark').show();
                }
            }
            return;
        }
        var newCountryVal = newCountry.val();
        if (newCountryVal == 1) {
            $('.show-if-denmark').show();
        } else {
            $('.show-if-denmark').hide();
        }
    };

};