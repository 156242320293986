var djf = djf || {};
$(function () {
    djf.chartjs.init();
});

djf.chartjs = new function () {
    var adStatisticsChart = {};

    this.init = function () {
        //$.ajax('/umbraco/Api/MarketApi/GetMarketAdStats?id=' + 1).done(function (data) {
        //    console.log("GetMarketAdStats - Data", data);

        //    //initiateChartJs
        //    var colors = {
        //        lightgreen: 'rgb(139, 146, 80)',
        //        darkgreen: 'rgb(46, 72, 45)'
        //    };
        //    var statistics = data;

        //    var labels = [];

        //    var clickCountDataset = {
        //        label: 'Antal klik',
        //        backgroundColor: colors.darkgreen,
        //        stack: 'Stack 0',
        //        data: []
        //    };

        //    var viewCountDataset = {
        //        label: 'Antal visninger',
        //        backgroundColor: colors.lightgreen,
        //        stack: 'Stack 0',
        //        data: []
        //    };

        //    for (var i = 0; i < statistics.length; i++) {
        //        var statistic = statistics[i];
        //        labels.push("Uge " + statistic.WeekNo);
        //        clickCountDataset.data.push(statistic.ClickCount);
        //        viewCountDataset.data.push(statistic.ViewCount);
        //    }
        //    var datasets = [clickCountDataset, viewCountDataset];
        //    console.log("datasets", datasets);

        //    var chartData = {
        //        labels: labels,
        //        datasets: datasets
        //    };

        //    var ctx = document.getElementById("adStatisticsChart");
        //    if (ctx == null) {
        //        return;
        //    }
        //    adStatisticsChart = new Chart(ctx, {
        //        type: 'bar',
        //        data: chartData,
        //        options: {
        //            legend: {
        //                display: false
        //            },
        //            title: {
        //                display: false,
        //                text: ''
        //            },
        //            tooltips: {
        //                mode: 'index',
        //                intersect: false
        //            },
        //            responsive: true,
        //            scales: {
        //                xAxes: [{
        //                    stacked: true,
        //                    barPercentage: 0.4,
        //                    ticks: {
        //                        fontStyle: "bolder",
        //                        fontColor: colors.darkgreen,
        //                        fontFamily: "Source Sans Pro"
        //                    },
        //                    gridLines: {
        //                        color: "rgba(0, 0, 0, 0)",
        //                        drawBorder: false,
        //                        display: false
        //                    }
        //                }
        //                ],
        //                yAxes: [{
        //                    stacked: true,
        //                    ticks: {
        //                        display: false
        //                    },
        //                    gridLines: {
        //                        color: "rgba(0, 0, 0, 0)",
        //                        drawBorder: false,
        //                        display: false
        //                    }
        //                }]
        //            }
        //        }
        //    });
        //});
    };
};