var djf = djf || {};

$(function () {
    djf.accordion.init();
});

djf.accordion = new function () {

    this.init = function () {
        //console.log("djf.accordion.init was called");
        $('.accordion-content:not(.active .accordion-content)').hide();

        $('.accordion-header', '.accordion').on('click', function (e) {
            console.log("insideclick", e);
            e.preventDefault();
            djf.accordion.handleClick(this);
        });
        $('.accordion-header.-start-folded-out').each(function (index, element) {
            djf.accordion.handleClick(element);
        })
    }

    this.reEnit = function () {
        $('.accordion-header', '.accordion').unbind("click");
        djf.accordion.init();
    }

    this.handleClick = function (element) {
        //console.log("element", element);
        var self = $(element).parents("li"),
            selfContent = self.find('.accordion-content'),
            accordionContents = $('.accordion-item').find('.accordion-content');

        if (self.hasClass("active")) {
            self.removeClass("active");
            selfContent.slideUp(300);
        }
        else {
            if (!self.hasClass('multi-open')) {
                $('.accordion-item').removeClass("active");
                accordionContents.slideUp(300);
            }
            self.addClass("active");
            selfContent.slideDown(300);
        }
    }
};



