$(document).ready(function () {
    var onSubmitSignup = function onSubmitSignup(token) {
        if ($('#signupForm').prop("disabled") == true) {
            return;
        }

        $('#signupForm').prop("disabled", true);
        document.getElementById("signupModel_reCAPTCHAV3Token").value = token;
        document.getElementById("signupForm").submit();
    };

    var onSubmitMessageSignup = function onSubmitMessageSignup(token) {
        if ($('#messageSignupForm').prop("disabled") == true) {
            return;
        }

        $('#messageSignupForm').prop("disabled", true);
        document.getElementById("unionMessageSignup_reCAPTCHAV3Token").value = token;
        document.getElementById("messageSignupForm").submit();
    };

    window.onSubmitSignup = onSubmitSignup;
    window.onSubmitMessageSignup = onSubmitMessageSignup;
});