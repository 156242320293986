var djf = djf || {};

String.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
}

djf.signup = new function () {
    var self = this,
        form = $('#signupForm'),
        districtSearchInput = $('#district_search_input'),
        municipalitySelectInput = $('#district_municipality_select'),
        municipalitySelectInputValue = $('#dropdownMunicipalities_value'),
        countySelectInput = $('#dropdownCounties'),
        countySelectInputValue = $('#dropdownCounties_value'),
        districtList = $('#district-list > ul'),
        searchTermsLabel = $("#search-terms"),
        formSubmitter = $("#signupForm_Submitter"),
        stepPagination = $("#signupForm nav > .pagination");
    this.associationIdQuery = null;

    this.init = function () {
        if (form.length == 0) {
            $('#thank-you-modal').modal('show');
            return;
        }
        //var getUrlParameter = function getUrlParameter(sParam) {
        //    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        //        sURLVariables = sPageURL.split('&'),
        //        sParameterName,
        //        i;

        //    for (i = 0; i < sURLVariables.length; i++) {
        //        sParameterName = sURLVariables[i].split('=');

        //        if (sParameterName[0] === sParam) {
        //            return sParameterName[1] === undefined ? true : sParameterName[1];
        //        }
        //    }
        //};

        //var blog = getUrlParameter('associationId');

        //var birthDate = document.getElementById('birth-date');
        //if (birthDate != null) {
        //    new Pikaday({
        //        field: document.getElementById('birth-date'),
        //        format: 'YYYY-MM-DD',
        //        yearRange: [1915, 2015],
        //        i18n: {
        //            previousMonth: 'Forrige m&aring;ned',
        //            nextMonth: 'N�ste m&aring;ned',
        //            months: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
        //            weekdays: ['S&oslash;ndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'L&oslash;rdag'],
        //            weekdaysShort: ['S&oslash;n', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'L&oslash;r']
        //        }
        //    });
        //}




        this.fetchDistricts();
        this.changeStep(1);

        $(form).on('submit', function (e) {
            $('#step2_date').valid();
            var validationErrors = $("[data-step] .input-validation-error");
            if (validationErrors.length == 0) {
                formSubmitter.prop("disabled", true);
                formSubmitter.addClass("disabled");
                formSubmitter.append('<i class="fa fa-spinner fa-spin"></i>');
            }
        });

        $(form).keypress(function (e) {
            if (e.which == 13) {
                var currentStep = self.steps[self.step - 2];
                if (currentStep != null && typeof (currentStep.enter) == "function") {
                    currentStep.enter();
                    e.preventDefault();
                }
            }
        });

        $(form).find(".prev").unbind().on("click", function () {
            self.previous();
        });
    }

    function getParameterByName(name, url) {
        if (!url) { url = window.location.href; }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) { return null; }
        if (!results[2]) { return ""; }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    this.steps = [
        { // Step #1
            name: "1",
            showButton: false,
            init: function () {
                //self.step1Bind = $("fieldset[data-step='1'] [data-type]").unbind().on('click', function (e) {
                //    e.preventDefault();

                //    var type = $(this).attr("data-type");
                //    if (type == "direct") {
                //        self.directStep = 5;

                //        self.districtId = "0009090";
                //        $("[name='signupModel.AssociationId']").val("0009090");
                //    } else {
                //        self.directStep = 2;
                //    }
                //    self.changeStep(self.directStep);
                //});

                var associationId = getParameterByName("association");
                if (associationId != null) {
                    associationId = associationId.pad(7);
                    self.associationIdQuery = associationId;
                    self.districtId = associationId;
                    $("[name='signupModel.AssociationId']").val(associationId);
                    $("fieldset[data-step='3'] .prev").unbind().remove();
                    self.changeStep(3);
                }
                else {
                    self.directStep = 2;
                    self.changeStep(self.directStep);
                }
            },
            hide: function () {
                //self.step1Bind.unbind();
            }
        },
        { // Step #2
            name: "2",
            showButton: true,
            init: function () {
                if (self.associationIdQuery != null) {
                    self.changeStep(3);
                }
                $('.link-to-direct-membership').click(function () {


                    self.districtId = "0009090";
                    $("[name='signupModel.AssociationId']").val("0009090");
                    self.changeStep(5);
                    $(window).scrollTop(0);
                });
            },

            enter: function () {
                var validator = $(form).validate();
                if (validator.element("#step2_date")) {
                    self.selectedAge = $("#step2_date").val();
                    self.SelectElement("signupModel_BirthdateYear", self.selectedAge);
                    $(form).find("#signupModel_BirthdateYear").val(self.selectedAge);
                    self.changeStep(4);
                }
            }
        },
        { // Step #3
            name: "3",
            showButton: false,
            init: function () {

                $("fieldset[data-step='3'] #step2_date").data("val", true);
                $("fieldset[data-step='3'] #step2_date").focus();
                $("fieldset[data-step='3'] .next").unbind().on('click', function (e) {
                    e.preventDefault();

                    var currentStep = self.steps[self.step - 2];
                    currentStep.enter();
                });
            },
            hide: function () {
                $("fieldset[data-step='3'] #step2_date").data("val", false); // Removes validation
            },
            action: function (element) {
                var value = element.attr('data-id');
                self.associationIdQuery = null;
                self.districtId = value;
                $("[name='signupModel.AssociationId']").val(value);
                self.changeStep(3);
            }
        },

        { // Step #4
            name: "4",
            showButton: false,
            init: function () {
                var date = new Date();
                var age = date.getFullYear() - (self.selectedAge || 1997);

                var birthyear = date.getFullYear() - age;
                var memberShipTypeValdiations = {
                    // 0 - 15
                    "JUN": function () { return age <= 15 },
                    // 16 - 25
                    "UNG": function () { return age >= 16 && age <= 25 },
                    // 15 - 25+
                    "KURS": function () { return age >= 15 },
                    // 16+
                    "PR�": function () { return true },
                    // 26+
                    "ORD": function () { return age >= 26 && birthyear >= 1949 },
                    // 26+
                    "HUS": function () { return age >= 16 },
                    // 26+
                    "XTR": function () { return true },
                    // 70+
                    "SEN": function () { return birthyear <= 1948 }
                }

                //self.SelectElement("signupModel_Country", "DNK");
                //$(form).find("#signupModel_Country").val("DNK");
                $("fieldset[data-step='4'] [data-id]").each(function () {
                    var value = $(this).attr("data-id");
                    var validator = memberShipTypeValdiations[value];
                    if (validator == null || validator()) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });

                $("fieldset[data-step='4'] [data-id]").on('click', function (e) {
                    e.preventDefault();

                    var value = $(this).attr("data-id");
                    self.selectedMemberShip = value;
                    $("[name='signupModel.MembershipTypeId']").val(value);

                    self.changeStep(5);
                });
            }
        },
        { // Step #5
            name: "5",
            showButton: false,
            init: function () {
                $("#step2_date").removeAttr("required");
                $("#signupModel_City").prop("readonly", true);
                $("#signupModel_ZipCode").keyup(function (e) {
                    if (e.target.value.length == 4) {
                        $.get("https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:" + e.target.value + "|country:dk|types:locality&key=AIzaSyC39b_7CTFxHNJ6_p7ARb2HS689BfOVIL4", function (res) {
                            if (res && res.results && res.results.length > 0 && res.results[0].address_components) {
                                for (var i = 0; i < res.results[0].address_components.length; i++) {
                                    var addressComponent = res.results[0].address_components[i];
                                    if (addressComponent.types.indexOf("locality") != -1 || addressComponent.types.indexOf("sublocality") != -1) {
                                        var cityProp = $("#signupModel_City");
                                        cityProp.attr("value", addressComponent.short_name);
                                        cityProp[0].value = addressComponent.short_name;
                                        cityProp.val(addressComponent.short_name).trigger("change");
                                        var newVal = cityProp.val();
                                        break;
                                    }
                                }
                            }
                        });
                    }
                })
            },
            hide: function () {
                $("#step2_date").attr("required");
            }
        }
    ];

    this.SelectElement = function (id, valueToSelect) {
        var element = document.getElementById(id);
        element.value = valueToSelect;
    }

    this.previous = function () {
        if (this.step == 5 && this.directStep == 5) {
            this.changeStep(1);
        } else if (this.step == 5 && this.directStep == 2) {
            this.changeStep(4);
        } else {
            this.changeStep(this.step - 1);
        }
    }

    this.changeStep = function (index) {
        var step = this.steps[index - 1];
        if (step == null) {
            return;
        }

        var currentStep = this.steps[this.step - 1];
        if (this.step && currentStep) {
            if (typeof (currentStep.nextEnabled) == 'function' && !currentStep.nextEnabled()) {
                return;
            }

            if (typeof (currentStep.hide) == 'function') {
                currentStep.hide();
            }
        }

        this.step = index;
        step.init();

        //if (index != this.step && typeof (this.steps[this.step].init) == 'function') {
        //    this.steps[this.step].init();
        //}

        $(form).find("fieldset[data-step]").hide();
        $(form).find("fieldset[data-step='" + this.step + "']").show();

        $(stepPagination).find("li").removeClass("active");
        $(stepPagination).find("li[data-step='" + this.step + "']").addClass("active");


    }

    this.districts = [];
    this.fetchDistricts = function () {
        $.get("/umbraco/api/Districtsapi/Get?isApp=false", function (data) {

            self.districts = data;
            for (var i = 0; i < self.districts.length; i++) {
                if (self.districts[i].description.length && self.districts[i].description.substring(0, 8) == "Direkte ") {
                    self.districts.splice(i, 1);
                    break;
                }
            }
            self.setupSelectInputEvents();
            self.populateSelectInputs();
            self.setupDistrictList(self.districts);
        });
    }

    this.setupSelectInputEvents = function () {
        var updateExpression = function () {
            var sortedList = self.sortDistrictList();
            self.setupDistrictList(sortedList);
        };

        var searchDelayTimer;
        $(districtSearchInput).get(0).addEventListener("keydown", function () {
            clearTimeout(searchDelayTimer);
            searchDelayTimer = setTimeout(function () {
                updateExpression();
            }, 150);
        });
        //$(municipalitySelectInput).get(0).addEventListener("change", updateExpression);
        $(municipalitySelectInputValue).change(function () { updateExpression(); });
        //$(countySelectInputValue).get(0).addEventListener("change", updateExpression);
        $(countySelectInputValue).change(function () { updateExpression(); });
    }

    this.sortDistrictList = function () {
        var searchQuery = $(districtSearchInput).val().toLowerCase();
        var municipalityValue = $(municipalitySelectInputValue).val();
        var countyValue = $(countySelectInputValue).val();

        // Starting to sort the less intensive first
        var districts = this.districts;
        if (countyValue != -1 && countyValue != "") {
            districts = districts
                .filter(function (x) { return x.region == countyValue });
        }

        if (municipalityValue != -1 && municipalityValue != "") {
            districts = districts
                .filter(function (x) {
                    return x.municipality == municipalityValue;
                });
        }

        if (searchQuery.length != 0) {
            districts = districts
                .filter(function (x) { return x.description.toLowerCase().indexOf(searchQuery) != -1 });

            $(searchTermsLabel).html("Din s&oslash;gning p&aring; \"<strong>" + $(districtSearchInput).val() + "</strong>\" gav f&oslash;lgende resultat.");
        } else {
            $(searchTermsLabel).html("");
        }

        return districts;
    }

    this.populateSelectInputs = function () {
        var i;
        var item;

        const municipalities = this.uniqueBy(this.districts, function (x) { return x.municipality; })
            .map(function (x) {
                return self.districts.find(function (y) {
                    return y.municipality == x;
                });

            })
            .sort(function (a, b) {
                var textA = a.municipalityName.toUpperCase();
                var textB = b.municipalityName.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        municipalities.splice(0, 1);
        for (i in municipalities) {
            item = municipalities[i];
            if (item) {
                $(municipalitySelectInput).append($('<option>', {
                    value: item.municipality,
                    text: item.municipalityName
                }));
            }
        }
        autocompleteMunicipalities(document.getElementById("dropdownMunicipalities"), municipalities);

        const counties = this.uniqueBy(this.districts, function (x) { return x.region; })
            .map(function (x) { return self.districts.find(function (y) { return y.region == x }) })
            .sort(function (a, b) {
                var textA = a.regionName.toUpperCase();
                var textB = b.regionName.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        //counties.splice(0, 1);
        var greenland = counties.splice(0, 1);


        for (var i = 0; i < counties.length; i++) {
            var region = self.GetCountyRegion(parseInt(counties[i].region - 1));
            if (region.length > 0) {
                counties[i].regionName = counties[i].regionName + " (" + region + ")";
            }
        }
        counties.push(greenland[0]);

        autocompleteCounties(document.getElementById("dropdownCounties"), counties);
    }

    this.itemsPerPage = 50;
    this.constructedDistrictList = null;
    this.setupDistrictList = function (districts) {
        this.constructedDistrictList = districts;

        var totalPages = Math.max(Math.ceil(districts.length / this.itemsPerPage), 1);
        $('#district-pagination').twbsPagination('destroy');

        $('#district-pagination').twbsPagination({
            totalPages: totalPages,
            visiblePages: 7,
            hideOnlyOnePage: true,
            prev: '<span aria-hidden="true">&laquo;</span>',
            next: '<span aria-hidden="true">&raquo;</span>',
            onPageClick: function (event, page) {
                self.populateDistrictsList((page - 1) * self.itemsPerPage, self.itemsPerPage);
            }
        });

        this.populateDistrictsList(0, this.itemsPerPage);
    }

    this.populateDistrictsList = function (skip, take) {
        var constructedHtml = "";

        for (var i = skip; i < skip + take; i++) {
            var item = self.constructedDistrictList[i];
            if (item == null) {
                continue;
            }
            constructedHtml += this.constructDistrictItem(item);
        }

        constructedHtml = constructedHtml.length != 0 ? constructedHtml : this.constructDistrictItem({
            id: -1,
            description: "Ingen foreninger fundet!"
        }, true);

        $(districtList).html(constructedHtml);

        $(form).find("[data-step='2'] li[data-id]").unbind().on("click", function (e) {
            e.preventDefault();
            self.steps[2].action($(this));
        });
    }

    this.constructDistrictItem = function (data) {
        return "" +
            "<li data-id=\"" + data.id + "\">" +
            "   <b>" + data.description + "</b>" +
            "   <button class=\"subnav-trigger\">" + (data.id == -1 ? "" :
                "       <span class=\"svg-holder\">" +
                "           <svg>" +
                "               <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"/dist/icons/icons.svg#arrow-circle\">" +
                "               </use>" +
                "           </svg>" +
                "       </span>" +
                "   </button>") +
            "</li>";
    }
    this.GetCountyRegion = function (i) {
        switch (i) {
            case 0:
                { return "Nordjylland"; }
            case 1:
                { return "Midt- og Vestjylland"; }
            case 2:
                { return "&Oslash;stjylland"; }
            case 3:
                { return "Sydjylland"; }
            case 4:
                { return "Fyn"; }
            case 5:
                { return "Sydsj&aelig;lland og &oslash;erne"; }
            case 6:
                { return "Nordsj&aelig;lland og K&oslash;benhavn"; }
            case 7:
                { return "Bornholm"; }
            case 8:
                { return ""; }
            default:
                { return "REGION NOT FOUND"; }
        }
    }

    // Snippet thanks to https://stackoverflow.com/users/244353/mrchief
    this.uniqueBy = function (arr, fn) {
        var unique = {};
        var distinct = [];
        arr.forEach(function (x) {
            var key = fn(x);
            if (!unique[key]) {
                distinct.push(key);
                unique[key] = true;
            }
        });
        return distinct;
    }

};

$(function () {
    djf.signup.init();
});

function autocompleteMunicipalities(inp, arr) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;


    function inputChanged(element) {


        var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
        //if (val.length == 0) {
        //    $('#dropdownCounties_value').val("").trigger('change');
        //}
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        //if (!val) { return false; }
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", element.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        element.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            var s = escapeSearchInput(arr[i].municipalityName).toUpperCase();
            var desc = escapeSearchInput(arr[i].escription).toUpperCase();
            if (val.length == 0 || s.indexOf(val) != -1 || desc.indexOf(val) != -1) {
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML += arr[i].municipalityName;
                //                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i].municipalityName + "' data-id='" + arr[i].municipality + "'>"; //arr[i].County
                //$('#dropdownCounties_value').trigger('change');
                /*execute a function when someone clicks on the item value (DIV element):*/
                //////////////////////////////////////////////////////////////////////////////
                b.addEventListener("click", function (e) {
                    /*insert the value for the autocomplete text field:*/
                    var obj = $(e.srcElement);
                    var inputField = obj.find("input");
                    var fieldId = inputField.data("id");
                    $('#dropdownMunicipalities_value').val(fieldId).trigger('change');
                    inp.value = this.getElementsByTagName("input")[0].value;

                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    closeAllLists();

                });
                a.appendChild(b);

            }
        }
        var aAsJquery = $(a);
        a.setAttribute("style", "width:" + (aAsJquery.width() + 1) + "px;");
    }

    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function (e) {
        inputChanged(this);
    });
    /*execute a function when someone writes in the text field:*/
    var dropdownMunicipalitiesInput = document.getElementById("dropdownMunicipalities");
    dropdownMunicipalitiesInput.addEventListener("click", function () {
        $('#dropdownMunicipalities_value').val("").trigger('change');
        $('#dropdownMunicipalities').val("").trigger('change');
    });

    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) { x = x.getElementsByTagName("div"); }
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) { x[currentFocus].click(); }
            }
        }
    });
    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) { return false; }
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) { currentFocus = 0; }
        if (currentFocus < 0) { currentFocus = (x.length - 1); }
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    /*execute a function when someone clicks in the document:*/
    inp.addEventListener("click", function (e) {
        setTimeout(function () {
            inputChanged(inp, e.target);
        });
    });

    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}
function autocompleteCounties(inp, arr) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;

    function inputChanged(element) {
        var a, b, i, val = escapeSearchInput(element.value || "").toUpperCase();
        //if (val.length == 0) {
        //    $('#dropdownCounties_value').val("").trigger('change');
        //}
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        //if (!val) { return false; }
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", element.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        element.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            var s = arr[i].regionName + escapeSearchInput(GetCountyRegion(i));
            if (val.length == 0 || s.toUpperCase().indexOf(val.toUpperCase()) != -1) {
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML += arr[i].regionName;
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i].regionName + "' data-id='" + arr[i].region + "'>"; //arr[i].County
                //$('#dropdownCounties_value').trigger('change');
                /*execute a function when someone clicks on the item value (DIV element):*/
                //////////////////////////////////////////////////////////////////////////////
                b.addEventListener("click", function (e) {
                    /*insert the value for the autocomplete text field:*/
                    var obj = $(e.srcElement);
                    var inputField = obj.find("input");
                    var fieldId = inputField.data("id");
                    $('#dropdownCounties_value').val(fieldId).trigger('change');
                    inp.value = this.getElementsByTagName("input")[0].value;

                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    closeAllLists();

                });
                a.appendChild(b);
            }
        }
    }

    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function (e) {
        inputChanged(this);
    });

    var dropdownCountiesInput = document.getElementById("dropdownCounties");
    dropdownCountiesInput.addEventListener("click", function () {
        $('#dropdownCounties_value').val("").trigger('change');
        $('#dropdownCounties').val("").trigger('change');
    });

    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) { x = x.getElementsByTagName("div"); }
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) { x[currentFocus].click(); }
            }
        }
    });
    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) { return false; }
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) { currentFocus = 0; }
        if (currentFocus < 0) { currentFocus = (x.length - 1); }
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    function GetCountyRegion(i) {
        switch (i) {
            case 0:
                { return "Nordjylland"; }
            case 1:
                { return "Midt- og Vestjylland"; }
            case 2:
                { return "&Oslash;stjylland"; }
            case 3:
                { return "Sydjylland"; }
            case 4:
                { return "Fyn"; }
            case 5:
                { return "Nordsj&aelig;lland og K&oslash;benhavn"; }
            case 6:
                { return "Sydsj&aelig;lland og &oslash;erne"; }
            case 7:
                { return "Bornholm"; }
            case 8:
                { return ""; }
            default:
                { return "REGION NOT FOUND"; }
        }
    }
    /*execute a function when someone clicks in the document:*/
    inp.addEventListener("click", function (e) {
        setTimeout(function () {
            inputChanged(inp, e.target);
        });
    });

    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}

/*An array containing all the country names in the world:*/

/*initiate the autocomplete function on the "myInput" element, and pass along the countries array as possible autocomplete values:*/

$.validator.methods.range = function (value, element, param) {
    if (element.type === 'checkbox') {
        return element.checked;
    } else {
        return defaultRangeValidator.call(this, value, element, param);
    }
}


