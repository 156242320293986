$(document).ready(function () {
    if ($(".create-journal-container").length && $(".create-journal-container .overlay").length) {
        $("#gameJournalForm .create-ad-submit").on("click", function (e) {
            e.preventDefault();
            $(".overlay").addClass("active");
            $("#gameJournalForm").submit();
        })
    }

    if ($(".gamejournal-registration-table-container").length) {
        var table = $(".gamejournal-registration-table-container table.gameJournalRegistrationOnOtherPageTable");
        var tableId = table.attr("id");
        var templateId = 1;
        $(".add-line").on("click", function () {
            var rowMarkup = $(".table-markup table tbody").html();
            rowMarkup = rowMarkup.replace("temp_id", templateId);
            rowMarkup = rowMarkup.replace("template_id", "template_" + templateId);
            rowMarkup = rowMarkup.replace("template_kills_id", "template_kills_" + templateId);
            rowMarkup = rowMarkup.replace("template_species_id", "template_species_" + templateId);
            rowMarkup = rowMarkup.replace("template_foreignspecies_id", "template_foreignspecies_" + templateId);
            rowMarkup = rowMarkup.replace("template_shotOrCaught_id", "template_shotOrCaught_" + templateId);
            rowMarkup = rowMarkup.replace("template_shotOrCaught_id", "template_shotOrCaught_" + templateId);
            rowMarkup = rowMarkup.replace("template_weight_breaking_id", "template_weight_breaking_" + templateId);
            rowMarkup = rowMarkup.replace("template_weight_alive_id", "template_weight_alive_" + templateId);
            rowMarkup = rowMarkup.replace("template_regulation_id", "template_regulation_" + templateId);
            rowMarkup = rowMarkup.replace("template_regulation_id", "template_regulation_" + templateId);
            rowMarkup = rowMarkup.replace("template_sex_id", "template_sex_" + templateId);
            rowMarkup = rowMarkup.replace("template_age_id", "template_age_" + templateId);
            rowMarkup = rowMarkup.replace("1_template_id", "1_" + templateId);
            rowMarkup = rowMarkup.replace("2_template_id", "2_" + templateId);
            rowMarkup = rowMarkup.replace("3_template_id", "3_" + templateId);
            rowMarkup = rowMarkup.replace("4_template_id", "4_" + templateId);
            rowMarkup = rowMarkup.replace("1_template_id", "1_" + templateId);
            rowMarkup = rowMarkup.replace("2_template_id", "2_" + templateId);
            rowMarkup = rowMarkup.replace("3_template_id", "3_" + templateId);
            rowMarkup = rowMarkup.replace("4_template_id", "4_" + templateId);

            table.find("tbody").append(rowMarkup);
            var selectSpecies = $('#template_species_' + templateId);
            console.log("selectSpecies", selectSpecies);
            selectSpecies.prepend('<option value=""></option>');
            selectSpecies.val("");
            templateId++;
        });

        $(table).on("click", ".remove-line", function () {
            $(this).parents("tr").remove();
        });

        $(table).on("input", ".number-of-kills input", function () {
            var tr = $(this).parents("tr");
            if ($(this).val() == 1) {
                tr.find(".can-be-disabled").each(function () {
                    $(this).find("input").prop("disabled", false);
                    $(this).find("select").prop("disabled", false);
                });
                tr.find(".can-be-hidden").each(function () {
                    var options = $(this).find("select option[value='']");
                    options.remove();
                });
            }
            else {
                tr.find(".can-be-disabled").each(function () {
                    $(this).find("input").prop("disabled", true);
                    $(this).find("select").prop("disabled", true);
                    console.log($(this).find("input"));
                    $(this).find("input").val('');
                });
                tr.find(".can-be-hidden").each(function () {
                    var select = $(this).find("select");
                    select.append('<option value=""></option>');
                    select.val("");
                });
            }
        });

        $(".save").on("click", function () {
            var gameJournalId = $("#form_GameJournalId").val();
            var stringList = $("#form_GameJournalRegistrationsStringList");
            var jsonString = "";
            var table = $(".gamejournal-registration-table-container table.gameJournalRegistrationOnOtherPageTable");
            var total = table.find("tr:not(.header)").length;
            table.find("tr").each(function (index) {
                if (!$(this).hasClass("header")) {
                    var tr = $(this);


                    var tempId = "";
                    var kills = "";
                    var speciesId = "";
                    var shotOrCaughtName = "";
                    var shotOrCaught = "";
                    var regulationName = "";
                    var regulation = "";
                    var weightBreaking = "";
                    var weightAlive = "";
                    var sex = "";
                    var age = "";
                    var foreignSpeciesName = "";
                    var myObject = new Object();

                    if (tr.attr("data-in-db") == "true") {
                        tempId = tr.attr("reg-id");
                        kills = tr.find("#kills_" + tempId).val();
                        speciesId = tr.find("#speciesId_" + tempId).val();
                        foreignSpeciesName = tr.find("#foreignspeciesId_" + tempId).val();
                        shotOrCaughtName = "shotOrCaught_" + tempId;
                        shotOrCaught = tr.find("input[name='" + shotOrCaughtName + "']:checked").val();
                        regulationName = "regulation_" + tempId;
                        regulation = tr.find("input[name='" + regulationName + "']:checked").val();
                        weightBreaking = tr.find("#weight_breaking_" + tempId).val();
                        weightAlive = tr.find("#weight_alive_" + tempId).val();
                        sex = tr.find("#sex_" + tempId).val();
                        age = tr.find("#age_" + tempId).val();
                        if (kills != 1) {
                            //age = 0;
                            sex = "";
                            //weight = 0;
                        }
                        myObject.ID = tempId;

                    }
                    else {
                        tempId = tr.attr("template-id");
                        kills = tr.find("#template_kills_" + tempId).val();
                        speciesId = tr.find("#template_species_" + tempId).val();
                        foreignSpeciesName = tr.find("#template_foreignspecies_" + tempId).val();
                        shotOrCaughtName = "template_shotOrCaught_" + tempId;
                        shotOrCaught = tr.find("input[name='" + shotOrCaughtName + "']:checked").val();
                        regulationName = "template_regulation_" + tempId;
                        regulation = tr.find("input[name='" + regulationName + "']:checked").val();
                        weightBreaking = tr.find("#template_weight_breaking_" + tempId).val();
                        weightAlive = tr.find("#template_weight_alive_" + tempId).val();
                        sex = tr.find("#template_sex_" + tempId).val();
                        age = tr.find("#template_age_" + tempId).val();
                        if (kills != 1) {
                            //age = 0;
                            sex = "";
                            //weight = 0;
                        }
                        myObject.ID = 0;
                    }

                    myObject.GameJournalID = gameJournalId;
                    myObject.GameSpeciesID = speciesId;
                    myObject.ForeignSpeciesName = foreignSpeciesName;
                    myObject.NumberOfKills = kills;
                    myObject.ShotOrCaught = shotOrCaught;
                    myObject.Regulation = regulation;
                    myObject.WeightBreaking = weightBreaking;
                    myObject.WeightAlive = weightAlive;
                    myObject.Sex = sex;
                    myObject.Age = age;
                    myObject.DeletedAt = null;
                    myObject.DeletedBy = "";
                    if (index == total) {
                        jsonString += JSON.stringify(myObject);
                    }
                    else {
                        jsonString += JSON.stringify(myObject) + ", ";
                    }
                }
            });

            stringList.val("[" + jsonString + "]");

            console.log("jsonString", "{ \"Data\": [" + jsonString + "]}");

            var objToValidate = JSON.parse("{ \"Data\": [" + jsonString + "]}");
            for (var i = 0; i < objToValidate.Data.length; i++) {
                objToValidate.Data[i].GameJournalID = parseInt(objToValidate.Data[i].GameJournalID);
                objToValidate.Data[i].NumberOfKills = parseInt(objToValidate.Data[i].NumberOfKills);
                objToValidate.Data[i].WeightBreaking = parseInt(objToValidate.Data[i].WeightBreaking);
                objToValidate.Data[i].WeightAlive = parseInt(objToValidate.Data[i].WeightAlive);
                objToValidate.Data[i].Age = parseInt(objToValidate.Data[i].Age);
                objToValidate.Data[i].ShotOrCaught = objToValidate.Data[i].ShotOrCaught == "false" ? false : true;
                objToValidate.Data[i].Regulation = objToValidate.Data[i].Regulation == "false" ? false : true;
            }
            console.log("objToValidate", objToValidate);

            $.post("/umbraco/api/GameJournalApi/ValidateRegistration", objToValidate, function (response) {
                console.log("response", response);
                $("#gameJournalForm").submit();

            }).fail(function (response) {
                $('.create-journal-container .notification-box').html(response.responseJSON.ExceptionMessage);
            });

        });


        var existingRows = $('.gamejournal-registration-table-container table.gameJournalRegistrationOnOtherPageTable tbody tr');
        if (existingRows.length == 0) {

            $(".add-line").click();
        }
    }
});

function SetAmountIfRequired(elem, isTemplate) {
    var $elem = $(elem);
    var value = $elem.val();
    $.get('/umbraco/api/GameJournalApi/SpeciesGetHasRequiredSet?speciesID=' + value, function (res) {
        var hasRequiredProperties = res;
        var id = $elem[0].id.substring($elem[0].id.lastIndexOf('_') + 1);
        var $elemKills = null;
        var $elemWeightBreaking = null;
        var $elemWeightAlive = null;
        var $elemSex = null;
        var $elemAge = null;
        if (isTemplate) {
            $elemKills = $('#template_kills_' + id);
            $elemWeightBreaking = $('#template_weight_breaking_' + id);
            $elemWeightAlive = $('#template_weight_alive_' + id);
            $elemSex = $('#template_sex_' + id);
            $elemAge = $('#template_age_' + id);
        } else {
            $elemKills = $('#kills_' + id);
            $elemWeightBreaking = $('#weight_breaking_' + id);
            $elemWeightAlive = $('#weight_alive_' + id);
            $elemSex = $('#sex_' + id);
            $elemAge = $('#age_' + id);
        }
        if (hasRequiredProperties) {
            $elemKills.val("1").trigger('change');
            $elemKills.prop('disabled', true);
            $elemWeightBreaking.prop('disabled', false);
            $elemWeightAlive.prop('disabled', false);
            $elemSex.prop('disabled', false);
            $elemAge.prop('disabled', false);
        } else {
            $elemKills.prop('disabled', false);
        }
    });
}