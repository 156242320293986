
var djf = djf || {};

//$(function () {
//    djf.marketMenu.init();
//});

djf.marketMenu = new function () {
    this.init = function () {
        $.get("/umbraco/api/MarketApi/GetUnreadConversations", function (response) {
            //console.log("response", response);

            var unreadMessagesCount = parseInt(response);
            //console.log("unreadMessagesCount", unreadMessagesCount);
            if (unreadMessagesCount != -1) {
                var test = $("#market-unread-messages-count")[0];
                //console.log("test is here", test);
                test.innerHTML = unreadMessagesCount + " ulæst";
                //console.log("test is here again", test);
            }

        });
    }
}